// initial state
const state = {
    articles: [],
    families: [],
    familiegroups: [],
}

// getters
const getters = {
}

// actions
const actions = {
}

// mutations
const mutations = {
    set_articles (state, articles) {
        state.articles = articles
    },
    set_families (state, families) {
        state.families = families
    },
    set_familiegroups(state, familiegroups) {
        state.familiegroups = familiegroups
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}