<template>
  <div class="p-grid p-ai-center" v-if="modelValue">
    <label v-if="label" :class="labelClass">{{t(label)}}</label>
    <div :class="valueClass">{{modelValue}}</div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";

export default {
  name: "mkeFormBuilderDisplayValue",
  props: ["label", "modelValue", "labelClass", "valueClass"],
  setup() {
    const { t } = useI18n({ useScope: 'global' })


    return {t}
  }
}
</script>

<style scoped>

</style>