<template>
  <div class="grid-wrapper">
    <swiper
      :slides-per-view="5"
      :slides-per-group="5"
      :space-between="10"
      :observer="true"
      :threshold="3"
      :observeParents="true"
      loopFillGroupWithBlank: false
      class="test"
    >
      <swiper-slide
        class="mke-slide"
        v-for="(category, index) in productCategories"
        :key="index"
        :virtualIndex="index"
      >
        <mke-button
          :key="index"
          :label="category.name"
          :color="category.color"
          :functionCall="'filterProductsByCategory'"
          :functionCallArgs="[category, id]"
          v-bind:class="[category.id == activeProductCategory ? 'active' : '']"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: "PosProductCategoryFilter",
  props: ["color", "id"],
  setup(props) {
    const store = useStore()
    const productCategories = computed(() => store.state.pos.productCategories)
    const activeProductCategory = computed(() => store.state.pos.activeProductCategory[props.id])
    return {activeProductCategory, productCategories}
  }
}
</script>