<template>
  <Dialog id="pos-formbuilder-slot-machine-dialog"
          :modal="true"
          :draggable="false"
          :closable="true"
          :visible="visible"
          :dismissableMask="true"
          style="height: 90vh"
          >
    <template #header>
      <h3 class="p-d-flex p-jc-between p-align-center w-100">{{t(title)}}</h3>
    </template>

    <TabView>
      <TabPanel key="master-data-action-edit-tab-general" header="Allgemein" >
        <mke-form-builder-input-text
            id="master-data-action-edit-name"
            class="mke-m-2"
            :label="t('message.masterdata.fields.name.label')"
            :placeholder="t('message.masterdata.fields.name.placeholder')"
            v-model="modelValue.name" />

        <mke-form-builder-input-text
            id="master-data-action-edit-short_name"
            class="mke-m-2"
            :label="t('message.masterdata.fields.short_name.label')"
            :placeholder="t('message.masterdata.fields.short_name.placeholder')"
            v-model="modelValue.short_name" />

        <mke-form-builder-input-text
            id="master-data-action-edit-button_text"
            class="mke-m-2"
            :label="t('message.masterdata.fields.button_text.label')"
            :placeholder="t('message.masterdata.fields.button_text.placeholder')"
            v-model="modelValue.button_text" />
      </TabPanel>
      <TabPanel key="master-data-action-edit-tab-functional" header="Funktional" >
        <mkeFormBuilderDropdown
            id="master-data-action-edit-click_action_name"
            class="mke-m-2"
            :label="t('message.masterdata.fields.click_action_name.label')"
            :placeholder="t('message.masterdata.fields.click_action_name.placeholder')"
            :options="buttonFunctionOptions"
            optionLabel="label"
            optionValue="value"
            v-model="modelValue.click_action_name" />

        <mke-form-builder-input-text
            id="master-data-action-edit-click_action_param"
            class="mke-m-2"
            :label="t('message.masterdata.fields.click_action_param.label')"
            :placeholder="t('message.masterdata.fields.click_action_param.placeholder')"
            v-model="modelValue.click_action_param" />

        <mkeFormBuilderDropdown
            id="master-data-action-edit-long_pressed_name"
            class="mke-m-2"
            :label="t('message.masterdata.fields.long_pressed_name.label')"
            :placeholder="t('message.masterdata.fields.long_pressed_name.placeholder')"
            :options="buttonFunctionOptions"
            optionLabel="label"
            optionValue="value"
            v-model="modelValue.long_pressed_action_name" />

        <mke-form-builder-input-text
            id="master-data-action-edit-long_pressed_param"
            class="mke-m-2"
            :label="t('message.masterdata.fields.long_pressed_param.label')"
            :placeholder="t('message.masterdata.fields.long_pressed_param.placeholder')"
            v-model="modelValue.long_pressed_action_param" />
      </TabPanel>
      <TabPanel key="master-data-action-edit-tab-design" header="Design" >
        <mke-form-builder-input-text
            id="master-data-action-edit-icon"
            class="mke-m-2"
            :label="t('message.masterdata.fields.icon.label')"
            :placeholder="t('message.masterdata.fields.icon.placeholder')"
            v-model="modelValue.icon" />

        <div class="p-grid p-ai-center mke-px-0 mke-m-2" id="master-data-action-edit-color">
          <div class="p-col-4 mke-p-0">{{ t('message.masterdata.fields.color.label') }}</div>
          <div class="p-col-8 mke-p-0" :data-value="modelValue.color">
            <Dropdown :id="id" class="block" :modelValue="modelValue.color" :options="colorOptions" optionLabel="label" optionValue="value" :filter="true"
                      :placeholder="t('message.masterdata.fields.color.placeholder')" :showClear="true" @change="modelValue.color = $event.value">
              <template #value="slotProps">
                <div v-if="slotProps.value" class="p-d-flex p-ai-center">
                  <div style="width: 10px; height: 10px" :class="'p-button p-button-' + slotProps.value"></div>
                  <div style="align-content: center; padding-left: 10px; padding-top: 3px">{{ colorOptions.filter(x => x.value === slotProps.value)[0]?.label || "" }}</div>
                </div>
                <span v-else>
            {{ slotProps.placeholder }}
        </span>
              </template>
              <template #option="slotProps">
                <div class="p-d-flex p-ai-center">
                  <div style="width: 10px; height: 10px" :class="'p-button p-button-' + slotProps.option.value"></div>
                  <div style="align-content: center; padding-left: 10px; padding-top: 3px">{{ slotProps.option.label }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
        </div>

        <mkeFormBuilderDropdown
            id="master-data-action-edit-fontsize"
            class="mke-m-2"
            :label="t('message.masterdata.fields.fontsize.label')"
            :placeholder="t('message.masterdata.fields.fontsize.placeholder')"
            :options="fontSizeOptions"
            optionLabel="label"
            optionValue="value"
            v-model="modelValue.fontsize" />

        <mke-form-builder-input-number
            id="master-data-action-edit-grid_x"
            class="mke-m-2"
            :label="t('message.masterdata.fields.grid_x.label')"
            :placeholder="t('message.masterdata.fields.grid_x.placeholder')"
            :min-fraction-digits="0"
            :max-fraction-digits="0"
            :use-grouping="false"
            v-model="modelValue.grid_x" />
        <mke-form-builder-input-number
            id="master-data-action-edit-grid_y"
            class="mke-m-2"
            :label="t('message.masterdata.fields.grid_y.label')"
            :placeholder="t('message.masterdata.fields.grid_y.placeholder')"
            :min-fraction-digits="0"
            :max-fraction-digits="0"
            :use-grouping="false"
            v-model="modelValue.grid_y" />
        <mke-form-builder-input-number
            id="master-data-action-edit-grid_width"
            class="mke-m-2"
            :label="t('message.masterdata.fields.grid_width.label')"
            :placeholder="t('message.masterdata.fields.grid_width.placeholder')"
            :min-fraction-digits="0"
            :max-fraction-digits="0"
            :use-grouping="false"
            v-model="modelValue.grid_width" />
        <mke-form-builder-input-number
            id="master-data-action-edit-grid_height"
            class="mke-m-2"
            :label="t('message.masterdata.fields.grid_height.label')"
            :placeholder="t('message.masterdata.fields.grid_height.placeholder')"
            :min-fraction-digits="0"
            :max-fraction-digits="0"
            :use-grouping="false"
            v-model="modelValue.grid_height" />
      </TabPanel>
    </TabView>

    <template #footer>
      <mke-button id="button-action-cancel" :label="t('message.masterdata.button.cancel')" icon="pi pi-times" class="p-button-transparent" @click="closeDialog" />
      <mke-button id="button-action-add-commit" :label="t('message.masterdata.button.accept')" icon="pi pi-check" class="p-button-primary" @click="commitItem" />
    </template>
  </Dialog>
</template>

<script>
import {useStore} from "vuex";
import {ref, toRef} from "vue";
import {useI18n} from "vue-i18n/dist/vue-i18n.esm-bundler";

export default {
  name: "PosFormBuilderSlotMachineDialog",
  props: ["title", "item", "visible", "mode"],
  emits: ["onItemAddCommitted", "onItemAddCanceled", "onItemUpdateCommitted", "onItemUpdateCanceled"],
  setup(props, context){
    const store = useStore();

    const { t } = useI18n({ useScope: 'global' })

    const colorOptions = ref([
      {label: 'Gefahr', value: 'danger'},
      {label: 'Info', value: 'info'},
      {label: 'Primär', value: 'primary'},
      {label: 'Sekundär', value: 'secondary'},
      {label: 'Warnung', value: 'warning'}
    ]);

    const fontSizeOptions = ref([
      {label: 'S', value: 's'},
      {label: 'M', value: 'm'},
      {label: 'L', value: 'l'},
      {label: 'XL', value: 'xl'},
      {label: 'XXL', value: 'xxl'}
    ]);

    const buttonFunctionOptions = ref([
      {label: 'An Eingabefeld anhängen', value: "appendToKeyboardDisplay"},
      {label: 'Bestelle Plu', value: "orderPlu"},
      {label: 'Bon senden', value: "commitOrder"},
      {label: 'Fast Cash', value: "fastCash"},
      {label: 'Gib ... zurück setzen', value: "fastCashReturnOf"},
      {label: 'Mach auf ... setzen', value: "fastCashReturnTo"},
      {label: 'Modus setzen: Links/Rechts Hand', value: "setLayoutLeftRightHandMode"},
      {label: 'Modus wechseln: Links/Rechts Hand', value: "toggleLayoutLeftRightHandMode"},
      {label: 'Modus setzen: Funktionale Bereiche', value: "setLayoutFunctionalAreaMode"},
      {label: 'Modus wechseln: Funktionale Bereiche', value: "toggleLayoutFunctionalAreaMode"},
      {label: 'Nachricht anzeigen', value: "echo"},
      {label: 'Neuen Vorgang erstellen', value: "createNewProcess"},
      {label: 'Rücktaste für Eingabefeld', value: "backspaceKeyboardDisplay"},
      {label: 'Setze Anzahl', value: "setPosQuantity"},
      {label: 'Starte Bezahlung', value: "startPayment"},
      {label: 'Starte Split', value: "startSplit"},
      {label: 'Storniere Vorgang', value: "voidProcess"},
      {label: 'Storniere mit Memo', value: "voidWithMemo"},
      {label: 'Tastatur Eingabefeld leeren', value: "clearKeyboardDisplay"},
      {label: 'Tip setzen', value: "fastTip"},
      {label: 'Tischwechsel', value: "changeTable"},

      {label: 'Vorgang löschen', value: "deleteProcess"},
      {label: 'Vorgangswechsel', value: "switchProcess"},
      {label: 'Zeige Bon', value: "showBon"},
      {label: 'Zeige Rechnung', value: "showBill"},
    ])

    const empty_item = {name: "", short_name: "", button_text: "", click_action_name: "", click_action_param: "", long_pressed_action_name: "", long_pressed_action_param: ""}
    const modelValue = props.mode === "add" ? ref({...empty_item}) : toRef(props, "item")

    const closeDialog = () => {
      store.commit("general/addToast", {
        detail: t('message.masterdata.toast.cancel'),
        life: 3000,
        severity: "info",
        summary: "Info"
      } );

      context.emit( props.mode === "add" ? "onItemAddCanceled" : "onItemUpdateCanceled");
      modelValue.value = {...empty_item};
    }

    const commitItem = () => {
      context.emit(props.mode === "add" ? "onItemAddCommitted" : "onItemUpdateCommitted", [modelValue.value]);
      modelValue.value = {...empty_item};
    }

    return {
      t, modelValue, buttonFunctionOptions, commitItem, closeDialog, colorOptions, fontSizeOptions
    }
  }
}

</script>

<style scoped>

</style>
