import {defineStore} from "pinia";
import {computed, ref} from "vue";

/**
 * Handelt die Berechnung von Trinkgeld und Rück-/Wechselgeld mit den unterschiedlichen Varianten
 * - Stimmt so (d.h. Rückgeld wird zu Trinkgeld)
 * - Mach auf (d.h. es wird der Rechnungsbetrag aufgerundet, die Differenz zum tatsächlichem Preis wird das Trinkgeld und der Rest zum gegeben ist das Wechselgeld)
 * - Gib zurück (d.h. es wird das Rückgeld festgelegt und daraus das Trinkgeld berechnet)
 * - konkrete Tip-Angabe
 *
 */
export const useCashPaymentStore = defineStore('cashPayment', () => {
    // Zu bezahlen
    const priceToPay = ref(0);

    // Gegeben
    const givenAmount = ref(0);
    // Zielbetrag, der für die verschiedenen Berechnungen (Stimmt so, Mach aus und Gib zurück) benötigt wird.
    const targetAmount = ref(0);
    // Trinkgeld
    const tipAmount = ref(0);
    // Wechselgeld
    const returnAmount = ref(0);

    // Flag, ob genug gegeben wurde
    const givenInvalid = ref(true);
    // Flag, ob Ziel-Betrag korrekt vergeben wurde
    const targetInvalid = ref(false);
    // Flag, ob Trinkgeld korrekt vergeben wurde
    const tipInvalid = ref(false);

    // Gibt an, welches Feld/welche Berechnungsstrategie gesetzt wurde
    const inputTarget = ref("");
    // Gibt an, welches Feld/welche Berechnungsstrategie vorher gesetzt wurde, insbesondere interessant,
    // wenn erst der Zielbetrag gesetzt wurde und erst danach der gegeben-Betrag, weil erst dann die
    // abhängigen Werte korrekt berechnet werden können
    const lastTarget = ref("");
    // Der Betrag, der zuletzt gesetzt wurde.
    const inputValue = ref(0);

    // Der zu zahlende Betrag zzgl. Trinkgeld
    const totalPriceToPay = computed(()=>priceToPay.value + tipAmount.value);

    // Die zur Zeit nötigen Informationen zur Bezahlung
    const paymentInfo = computed(() => {
        return {
            payment_type: "cash",
            payment_name: "Geld",
            transaction_type: "receipt",
            business_case: "sales",
            business_case_name: "Verkauf",
            price: priceToPay.value,
            given: givenAmount.value,
            tip: tipAmount.value,
            returned: returnAmount.value,
            valid: !givenInvalid.value
        }
    });

    /**
     * Setzt den zu bezahlenden Preis und alle vorher getätigten Eingaben zurück
     *
     * @param price Der zu zahlende Preis
     */
    function setPriceToPay(price) {
            priceToPay.value = price;
            givenAmount.value = 0;
            targetAmount.value = 0;
            tipAmount.value = 0;
            returnAmount.value = 0;
            givenInvalid.value = true;
            targetInvalid.value = false;
            tipInvalid.value = false;
            inputTarget.value = "";
            lastTarget.value = "";
        }

    /**
     * Gibt einen Betrag und das Ziel für diesen Betrag an
     *
     * @param target    Das Ziel (Gegeben, Trinkgeld, Rückgeld, Rechnungsaufstockung etc)
     * @param value     Der angegebene Betrag
     */
    function setInput(target, value) {
        const isTarget = ['returnToTarget', 'returnOfTarget'].includes(target);
        if( isTarget ) {
            // Merken der Berechnungsstrategie für die nächste Berechnung
            lastTarget.value = target;
        } else if( target != "givenAmount" ) {
            lastTarget.value = "";
        }
        inputTarget.value = target;
        console.log(lastTarget.value, inputTarget.value);

        inputValue.value = value;
        if (inputTarget.value === 'givenAmount') {
            givenAmount.value = value;
        } else if (inputTarget.value === 'returnAsTip' && value > 0) {
            givenAmount.value = value;
        } else if (inputTarget.value === 'tipAmount') {
            tipAmount.value = value;
        } else if (isTarget) {
            targetAmount.value = value;
        }

        // Beträge neu berechnen
        recalculateReturnAndTip();
    }

    // Berechnet - je nach Aktion - das Trinkgeld und das Wechselgeld
    function recalculateReturnAndTip() {
        // Fehler-Flags zurücksetzen
        givenInvalid.value = false;
        targetInvalid.value = false;
        tipInvalid.value = false;

        if (givenAmount.value < priceToPay.value) {
            // Zu wenig gegeben
            givenInvalid.value = true;
        } else if (inputTarget.value === 'returnAsTip') {
            // Aktion: Stimmt so
            tipAmount.value = givenAmount.value - priceToPay.value;
            returnAmount.value = 0;
        } else if (inputTarget.value === 'returnToTarget' || lastTarget.value === 'returnToTarget') {
            // Aktion: Mach auf
            if (targetAmount.value >= priceToPay.value && givenAmount.value >= targetAmount.value) {
                // Gewünschter Zielbetrag liegt zwischen gegeben und dem zu zahlenden Preis -> alles okay
                tipAmount.value = targetAmount.value - priceToPay.value;
                returnAmount.value = givenAmount.value - targetAmount.value;
            } else {
                // Gewünschter Zielbetrag passt nicht
                tipAmount.value = 0;
                returnAmount.value = givenAmount.value - priceToPay.value;
                targetInvalid.value = true;
            }
        } else if (inputTarget.value === 'returnOfTarget' || lastTarget.value === 'returnOfTarget') {
            // Aktion: Gib zurück
            if (givenAmount.value - targetAmount.value >= priceToPay.value) {
                // Gewünschtes Wechselgeld passt
                returnAmount.value = targetAmount.value;
                tipAmount.value = givenAmount.value - priceToPay.value - targetAmount.value;
            } else {
                // Gewünschtes Wechselgeld ist zu hoch
                tipAmount.value = 0;
                returnAmount.value = givenAmount.value - priceToPay.value;
                targetInvalid.value = true;
            }
        } else if (tipAmount.value > givenAmount.value - priceToPay.value) {
            // Eingegebenes Trinkgeld zu hoch, also anpassen
            tipAmount.value = givenAmount.value - priceToPay.value;
            returnAmount.value = 0;
            tipInvalid.value = true;
        } else {
            // Ansonsten berechnen wir das Wechselgeld
            returnAmount.value = givenAmount.value - priceToPay.value - tipAmount.value;
        }

    }

    /**
     * Wurde irgendein Wert angegeben, d.h. ist irgendetwas anzuzeigen?
     */
    const anyValueGiven = computed(() => {
        return tipAmount.value > 0 || givenAmount.value > 0 || returnAmount.value > 0 || targetAmount.value > 0;
    });

    /**
     * Sind die Eingaben korrekt?
     */
    const isValid = computed( () => !givenInvalid.value && !targetInvalid.value && !tipInvalid.value);

    /**
     * Prüft die Eingaben und gibt zurück, wenn jetzt alles okay ist.
     * Es kann sein, dass beim ersten Veruch falsche Eingaben getätigt wurden, die Berechnungen die Werte aber
     * entsprechend korrigiert haben. Wenn der Anwender mit den korrigierten Eingaben, die Bezahlung noch mal
     * anstossen möchte, kann hierüber die Validierung noch mal durchgeführt und anschliessend ausgeführt werden.
     */
    function checkValidity() {
        recalculateReturnAndTip();

        return isValid.value;
    }

    return {
            givenAmount,
            tipAmount,
            targetAmount,
            returnAmount,
            givenInvalid,
            targetInvalid,
            tipInvalid,
            paymentInfo,
            setInput,
            setPriceToPay,
            anyValueGiven,
            isValid,
            checkValidity,
            lastTarget,
            priceToPay,
            totalPriceToPay
        }
    }
);