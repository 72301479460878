<template>
  <div class="mx-querybuilder-group mx-querybuilder-group-container">
    <div class="mx-querybuilder-header p-d-flex p-ai-center gap-3">

      <SelectButton v-model="compQuery.condition" :options="labels.matchTypes" optionLabel="label" optionValue="id" aria-labelledby="basic" />

      <Button @click="toggle" icon="pi pi-plus" aria-haspopup="true" aria-controls="overlay_menu" class="btn btn-secondary dropdown-toggle mke-ml-1"></Button>
      <Menu ref="menu" id="overlay_menu" class="p-menu-overlay" :model="menuItems" :popup="true"/>

      <div>
        <slot name="deleteRule"></slot>
      </div>
    </div>
             
    <div class="mx-querybuilder-body">
      <query-builder-children v-bind="$props" @delete-child="deleteChild"/>
    </div>
  </div>
</template>

<script>
import queryBuilderChildren from "@/components/dashboard/querybuilder/components/queryBuilderChildren";
import { ref } from 'vue'
import {queryBuilderWrapper} from "@/components/dashboard/querybuilder/utils/queryBuilderWrapper";
export default {
  name: "QueryBuilderGroup",

  components: { queryBuilderChildren },
  props: {
    type: {
      type: String,
      default: "query-builder-group",
    },
    cube: String,
    query: Object,
    dataSource: Array,
    index: Number,
    labels: Object,
    groupComponent: Object,
    ruleComponent: Object,
  },
  setup(props,{ emit }, context) {
    const menuItems = ref([
      {
        label: props.labels.addGroup,
        command: () => {
          addGroup();
        }
      },
      {
        label: props.labels.addRule,
        command: () => {
          addRule();
        }
      }
    ]);
    const matchType = ref('and');
    const menu = ref()
    const toggle = (event) => {
      menu.value.toggle(event);
    };
    const compQuery = queryBuilderWrapper(props, emit, 'query')
    const addRule = () =>   {
      let child = {
        type: "query-builder-rule",
        query: {
        },
      };
      compQuery.value.rules.push(child);

    }
    const addGroup = () =>   {
      compQuery.value.rules.push({
          type: "query-builder-group",
          query: {
            condition: props.labels.matchTypes[0].id,
            rules: [],
          },
        });
    }
    const deleteGroup = () =>   {
      context.emit("delete_child", props.index);
    }
    const deleteChild = (index) =>   {
      compQuery.value.rules.splice(index, 1);
    }

    return {menu, toggle , addRule,addGroup  ,deleteGroup, compQuery, deleteChild, menuItems, matchType}
  }

};
</script>
