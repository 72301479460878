import i18n from "@/i18n";
const  operators = {
    string:
        [
            {name:i18n.global.t("message.dashboard.queryBuilder.startsWith"), id:"startswith"},
            {name:i18n.global.t("message.dashboard.queryBuilder.endsWith"), id:"endswith"},
            {name:i18n.global.t("message.dashboard.queryBuilder.contains"), id:  "contains"},
            // {name:i18n.global.t("message.dashboard.queryBuilder.doesNotContain"), id:"doesnotcontain"},
            {name:i18n.global.t("message.dashboard.queryBuilder.equal"), id:"equal"},
            {name:i18n.global.t("message.dashboard.queryBuilder.notEqual"), id:"notequal"},
            {name:i18n.global.t("message.dashboard.queryBuilder.in"), id:"in"},
            {name:i18n.global.t("message.dashboard.queryBuilder.notIn"), id:"notin"},
        ],

    number:
        [
            {name:i18n.global.t("message.dashboard.queryBuilder.equal"), id: "equal"},
            {name:i18n.global.t("message.dashboard.queryBuilder.greaterThanOrEqual"), id: "greaterthanorequal"},
            {name:i18n.global.t("message.dashboard.queryBuilder.greaterThan"), id: "greaterthan"},
            {name:i18n.global.t("message.dashboard.queryBuilder.between"), id: "between"},
            {name:i18n.global.t("message.dashboard.queryBuilder.notBetween"), id: "notbetween"},
            {name:i18n.global.t("message.dashboard.queryBuilder.lessThan"), id: "lessthan"},
            {name:i18n.global.t("message.dashboard.queryBuilder.lessThanOrEqual"), id: "lessthanorequal"},
            {name:i18n.global.t("message.dashboard.queryBuilder.notEqual"), id: "notequal"},
            {name:i18n.global.t("message.dashboard.queryBuilder.in"), id: "in"},
            {name:i18n.global.t("message.dashboard.queryBuilder.notIn"), id: "notin"},
        ],
    boolean:
        [
            {name:i18n.global.t("message.dashboard.queryBuilder.equal"), id:"equal"},
            {name:i18n.global.t("message.dashboard.queryBuilder.notEqual"), id: "notequal"},
        ],
    date:
        [
            {name:i18n.global.t("message.dashboard.queryBuilder.equal"), id: "equal"},
            {name:i18n.global.t("message.dashboard.queryBuilder.greaterThanOrEqual"), id: "greaterthanorequal"},
            {name:i18n.global.t("message.dashboard.queryBuilder.greaterThan"), id: "greaterthan"},
            {name:i18n.global.t("message.dashboard.queryBuilder.between"), id: "between"},
            {name:i18n.global.t("message.dashboard.queryBuilder.notBetween"), id: "notbetween"},
            {name:i18n.global.t("message.dashboard.queryBuilder.lessThan"), id: "lessthan"},
            {name:i18n.global.t("message.dashboard.queryBuilder.lessThanOrEqual"), id: "lessthanorequal"},
            {name:i18n.global.t("message.dashboard.queryBuilder.notEqual"), id: "notequal"},
        ],

}
export default operators
