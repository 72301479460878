<template>
  <Dialog id="process-history-dialog"
          :visible="processHistoryDialogVisible"
          :modal="true"
          :closable="false"
          :breakpoints="{'960px': '75vw', '640px': '90vw'}"
          :style="{width: '60vw'}">
    <template #header>
      <h5>{{t('message.pos.process_history.title', [processHistory.title])}}</h5>
    </template>

    <table>
      <thead>
        <tr>
          <th class="history-transaction">{{t('message.pos.process_history.transaction')}}</th>
          <th class="history-entry">{{t('message.pos.process_history.entry')}}</th>
          <th v-for="field in processHistory.fields" :key="field" :class="'history-'+field">
            {{t('message.pos.process_history.'+field)}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="entry in processHistory.entries" :key="entry.id + '_' + entry.transaction_id">
          <td class="history-transaction" :data-value="entry.transaction_id">{{entry.transaction_id}}</td>
          <td class="history-entry" :data-value="entry.entry_number">{{entry.entry_number}}</td>
          <td v-for="field in processHistory.fields" :key="field"
              :class="[entry[field]?.modified ? 'history-state-changed' : 'history-state-unchanged', 'history-'+field]"
              :data-value="entry[field]?.value">
            <span v-if="field.endsWith('date')">{{d(entry[field]?.value, 'datetime_short')}}</span>
            <span v-else-if="field.endsWith('price')">{{n(entry[field]?.value, 'currency')}}</span>
            <span v-else-if="field === 'entry_type'">{{t('message.pos.entry_type.'+entry[field]?.value)}}</span>
            <span v-else-if="field === 'state'">{{t('message.pos.entry_state.'+entry[field]?.value)}}</span>
            <span v-else-if="field === 'inhouse'">
               <i v-if="entry[field]?.value === true" class="mdi mdi-15x mdi-home-outline mke-ml-2"/>
               <i v-else-if="entry[field]?.value === false" class="mdi mdi-15x mdi-home-export-outline mke-ml-2"/>
            </span>
            <span v-else>{{entry[field]?.value}}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <template #footer>
      <mke-button id="button-process-history-close" label="Schliessen" @click="closeDialog" class="p-button-primary"/>
    </template>
  </Dialog>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: "PosProcessHistoryDialog",
  setup(){
    const store = useStore();
    const { t, n, d } = useI18n({ useScope: 'global' })

    const processHistoryDialogVisible = computed( () =>
        store.state.pos.processHistory !== undefined && store.state.pos.processHistory !== null );

    const processHistory = computed( () => store.state.pos.processHistory);

    const closeDialog = () => {
      store.commit('pos/processHistory', null);
    }

    return {
      t, n, d, processHistory, processHistoryDialogVisible, closeDialog
    }
  }
}

</script>

<style scoped>

</style>