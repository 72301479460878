<template>
  <div class="mx-keyboard" >
    <div class="mx-keyboard-input-wrapper p-input-icon-left">
      <mke-button
          :icon="posProcessDetailsExpanded ? 'mdi mdi-2x mdi-arrow-up-thin-circle-outline' : 'mdi mdi-2x mdi-arrow-down-thin-circle-outline'"
          color="transparent"
          class="mke-expand-display h-100 mke-px-6 p-d-flex p-ai-center"
          @click="togglePosProcessExpanded"/>
      <mke-input-text :id="id + '_keyboard_display'" :store="numpadStore" class="font-size-l" />
    </div>

    <PosActionPanel
        id="mx-keyboard-numpad"
        class="mx-keyboard-numpad"
        :class="{'collapsed':posProcessDetailsExpanded}"
        target="numpad"/>
  </div>
</template>

<script>

import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: "PosNumpad",
  props: ['id', 'numpadStore'],
  setup(){
    const store = useStore();

    const posProcessDetailsExpanded = computed(() => { return store.state.pos.processDetailsExpanded });
    const togglePosProcessExpanded = function() {
      store.commit("pos/setPosProcessDetailsExpanded", !posProcessDetailsExpanded.value);
    }

    return {posProcessDetailsExpanded, togglePosProcessExpanded}
  },
}
</script>