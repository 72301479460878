<template>
  <Dialog id="void-dialog" :modal="true" v-model:visible="voidDialogVisible" :style="{width: '60vw'}" :closable="false">
    <template #header>
      <h5>Storno: Positions-Name</h5>
    </template>
      <div>
        <!-- TODO: Vordefinierte Begründungs-Schnipsel -->
        <!-- <div class="p-d-flex gap-2">
        <Chip
            class=""
            label="Kunde hat zu lange gewartet"
          />
        <Chip
            class=""
            label="Zu Kalt"
          />
          <Chip
            class=""
            label="Zu Warm"
          />
        </div> -->
        <mke-input-text class="block mke-mb-4" placeholder="Storno-Begründung" id="input-void-reason" store="voidReason"></mke-input-text>
      </div>
      <Sidebar class="keyboard" :visible="true" :modal="false" :baseZIndex="700" position="bottom">
        <div id="keyboard-full" class="mke-pt-2 mke-pb-2">
          <mke-keyboard layout="keyboard_full" store="voidReason"/>
        </div>
      </Sidebar>
    <template #footer>
      <mke-button id="button-void-cancel" label="Abbrechen" icon="pi pi-times" @click="closeDialog" class=""/>
      <mke-button id="button-void-commit" label="Storno" icon="pi pi-trash" class="p-button-danger" :posAction="voidWithMemoAction" />
    </template>
  </Dialog>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import MkeInputText from "@/components/general/mkeInputText";

export default {
  name: "PosProcessVoidDialog",
  components: {MkeInputText},
  setup(){
    const store = useStore();

    const voidDialogVisible = computed( () => store.state.pos.voidAction !== undefined );
    const memo = computed( () => encodeURIComponent(store.state.pos.keyboardDisplay['voidReason']) );
    const voidWithMemoAction = computed( function () {
      if( store.state.pos.voidAction ) {
        return {
          name: "voidWithMemo",
          store: "pos-main-keyboard-display",
          param: [ store.state.pos.voidAction.param, memo.value ]
        }
      } else {
        return {};
      }
    } );

    const closeDialog = () => {
      store.commit("general/addToast", {
        detail: "Der Storno wurde abgebrochen",
        life: 3000,
        severity: "info",
        summary: "Info"
      } );
      store.commit('pos/setVoidAction', undefined);
    }

    return {
      voidDialogVisible, voidWithMemoAction, closeDialog, memo
    }
  },
  /**
   * Close the Dialog if the 'Cross' is clicked for closing
   */
  updated() {
    // if (!this.showProcessListDialog){
    //   this.closeDialog()
    // }
  }
}

</script>

<style scoped>

</style>