<template>
  <div class="mx-app-card-wrapper scale"
      :class="{'no-transition':activeApp?.name === app.name}"
      @mousedown="activateApp(app)">

    <Card class="mx-app-card">
      <template #content>
        <div class="mx-app-icon-wrapper p-d-flex p-jc-start p-ai-center p-flex-column gap-3">
          <router-link  class="mx-card-link p-d-flex p-ai-center p-flex-column gap-2" :to="app.link ?? app.path">
            <img style="position: absolute;width: 100%" v-shared-element:[app.icon] :src="require('@/assets/icons/apps/' + app.icon + '_icon.svg')">
            <div v-shared-element:[app.id] key="box" class="v-shared-element-container p-d-flex p-ai-center p-jc-center">
              <div class="mx-app-icon p-d-flex p-ai-center p-jc-center mx-fg-text-high">
                <!-- <Vue3Lottie ref="lottieContainer"
                        v-if="app.animatedIcon"
                        :animationData="app.animatedIcon"
                        :width="500"
                        :autoPlay="false"
                        :loop="1"
                        @pointerenter="startAnimation"
                        @pointerleave="rewindAnimation"
                        @onComplete="animationCompleted"
                         /> -->
                
              </div>
            </div>

            <div v-shared-element:[app.name] class="mx-app-name font-size-m fade"
                 style="hyphens: auto; white-space: normal !important; text-align: center;"
                  :style="app.enabled ? '' : 'text-decoration: line-through'">
              {{ app.name }}
            </div>
          </router-link>
        </div>
      </template>
    </Card>
  </div>

</template>

<script>
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import {computed, ref} from "vue";

export default {
  name: "MxAppIcon",
  props: ["app"],
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    const store = useStore();
    const activeApp = computed( () => store.state.general.activeApp)
    const activateApp = (app) => {
      store.commit("general/setActiveApp", app);
      console.log("APP mouse down", new Date().toISOString());
    }
    const lottieContainer = ref(null);

    let whileAnimating = false;

    const startAnimation = () => {
      if( lottieContainer.value ) {
        // Ensure animation ist started from the beginning and in the correct direction
        lottieContainer.value.stop();
        lottieContainer.value.setDirection('forward');
        lottieContainer.value.play();
      }

      whileAnimating = true;
    }

    const rewindAnimation = () => {
      if( whileAnimating ) {
        if( lottieContainer.value ) {
          lottieContainer.value.setDirection('reverse');
          lottieContainer.value.play();
        }
      }
    }

    const animationCompleted = () => {
      whileAnimating = false;
      if( lottieContainer.value ) {
        lottieContainer.value.stop();
      }
    }

    return {t, activeApp, activateApp, lottieContainer, startAnimation, rewindAnimation, animationCompleted};
  }
}
</script>

<style scoped>

</style>