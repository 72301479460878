import i18n from '../i18n/index.js'
const widgetTemplateDemo = {
    "widget_templates": {
        "overview": [
            {
                "field": "name",
                "label": 'message.dashboard.template_name',
            },
            {
                "field": "description",
                "label": "message.dashboard.template_description"
            }
        ],
        "detail": [
            {
                "id": "modules-detail-name-input-text",
                "component": "mkeFormBuilderInputText",
                "label": 'message.dashboard.template_name',
                "field": "name",
                "class": "p-col-6 mke-pl-0"
            },
            {
                "id": "modules-detail-description-input-text",
                "component": "mkeFormBuilderInputTextarea",
                "label": "message.dashboard.template_description",
                "field": "description",
                "class": "p-col-6 mke-pl-0",
            },
        ]
    },
    "dashboard_names": {
        "overview": [
            {
                "field":"name",
                "label":'message.dashboard.category.dashbaord_name',
                "filter": "startsWith"
            },
            {
                "field":"dashboard_category_name",
                "label":'message.dashboard.category.category',
                "filter": "startsWith"
            },
        ],
        "detail": [
            {
                "id": "dashboard-detail-name-input-text",
                "component": "mkeFormBuilderInputText",
                "label": 'message.dashboard.category.dashbaord_name',
                "field": "name",
                "class": "p-col-6 mke-pl-0"
            },
            {
                "id": "dashbaord-detail-category-id",
                "component": "mkeFormBuilderDropdown",
                "label": "message.dashboard.category.category",
                "field": "category_id",
                "type": "many2one",
                "class": "p-col-6 mke-pl-0",
                "model": "dashboard_categories",

            }
        ],
    },
    "dashboard_categories": {
        "overview": [
            {
                "field":"name",
                "label":'message.dashboard.category.category_name',
                "filter": "startsWith"
            },
            {
                "field": "description",
                "label": "message.dashboard.category.category_description",
                "filter": "startsWith"
            }
        ],
        "detail": [
            {
                "id": "category-detail-name-input-text",
                "component": "mkeFormBuilderInputText",
                "label": 'message.dashboard.category.category_name',
                "field": "name",
                "class": "p-col-6 mke-pl-0"
            },
            {
                "id": "category-detail-description-input-text",
                "component": "mkeFormBuilderInputTextarea",
                "label": "message.dashboard.category.category_description",
                "field": "description",
                "class": "p-col-6 mke-pl-0",
            },
        ]
    },
}
export default widgetTemplateDemo

function translateSchemaHelper(data, field){
    data[field] = i18n.global.t(data[field])
    return data
}

/**
 * Translate the given schema
 */
function translateSchema(res){
    res.filter(x => x.label != null).map(x => translateSchemaHelper(x, "label"))
    res.filter(x => x.title != null).map(x => translateSchemaHelper(x, "title"))
    res.filter(x => x.columns != null).map(x => translateSchema(x.columns))
    return res
}

/**
 * Translate and return a schema
 * @param model The model to get the schema for. For example: users
 * @param viewMode The view mode to get the schema for. For example: detail or overview
 * @returns {*}
 */
function getTranslatedSchema (model, viewMode) {
    let modelCopy = JSON.parse(JSON.stringify(widgetTemplateDemo[model][viewMode]))
    return translateSchema(modelCopy)
}

export { getTranslatedSchema }