<template>
  <MxApp :app="activeApp">
    <div id="path-not-enabled" class="p-d-flex p-flex-column p-ai-center p-jc-around w-100 h-100">
      <h1>Leider sind Sie für den Zugriff auf <strong>{{activeApp.name}}</strong> nicht berechtigt!</h1>
      <mkeButton label="Get Back" @click="$router.go(-1)"/>
    </div>
  </MxApp>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: "PathNotEnabled",
  setup() {
    const store = useStore();
    const activeApp = computed( () => store.state.general.activeApp ?? {name: "UNKNOWN", id: "UNKNOWN"} );

    return {activeApp};
  }
}
</script>

<style scoped>

</style>