<template>
  <div :id="id" class="mx-functions" >
    <div class="mx-module-grid"
         :class="[actionContainer.grid_cols ? 'cols_' + actionContainer.grid_cols : '', actionContainer.grid_rows ? 'rows_' + actionContainer.grid_rows : '', actionContainer.button_layout === 'grid' ? 'layout_grid' : 'layout_floating']">
      <mke-button v-for="action in entries" :key="action.id"
                  :label="getButtonText(action)" :icon="getIcon(action)"
                  :color="action.color" :fontsize="action.fontsize"
                  :style="getButtonStyle(action)"
                  :pos-action="getButtonAction(action)"
                  @click="onClick"/>
    </div>
  </div>
</template>

<script>

import {useStore} from "vuex";
import {computed, inject, onMounted, ref} from "vue";

export default {
  name: "PosActionPanel",
  props: ['id', 'target', 'modelValue', 'mode'],
  emits: ['button:clicked'],
  setup(props, context){
    const store = useStore();

    const modelData = props.mode !== "editor" ? null : inject('modelData');

    const actionContainer = ref(modelData ?? {button_text:"", entries: []} );
    const entries = computed( () => props.modelValue ?? actionContainer.value?.entries );

    console.log( "PosActionPanel mounted", actionContainer.value );

    onMounted(() => {
      if( props.mode !== "editor" ) {
        store.dispatch("pos/getNavigationForTarget", {
          target: props.target,
          mode: "full",
          successHandler: (data) => {
            if( data && data.length > 0 ) {
              actionContainer.value = data[0];
              console.log( "PosActionPanel retrieved", actionContainer.value );

          }
        }});
    }});

    const getIcon = (button) => {
      if( button.icon ) {
        return 'mdi mdi-' + button.icon;
      } else {
        return null;
      }
    }

    const getButtonText = (button) => {
      if( button.icon ) {
        return "";
      } else {
        return button.button_text || button.label || button.short_text || button.name || button.value
      }
    }

    const getButtonStyle = (button) => {
      let style = "";

      if( button.grid_x ) {
        style += "grid-column-start: " + button.grid_x + "; ";
      }

      if( button.grid_width ) {
        style += "grid-column-end: span " + button.grid_width + ";";
      }

      if( button.grid_y ) {
        style += "grid-row-start: " + button.grid_y + "; ";
      }

      if( button.grid_height ) {
        style += "grid-row-end: span " + button.grid_height + ";";
      }

      return style;

    }

    const getButtonAction = (button) => {
      if( props.mode === "edit" ) {
        return null;
      } else {
        return button.pos_action;
      }
    }

    const onClick = (event, button) => {
      if( props.mode === "edit" ) {
        event.preventDefault();
        context.emit("button:clicked", button);
      }
    }

    return {actionContainer, entries, getIcon, getButtonText, getButtonStyle, getButtonAction, onClick}
  },
}
</script>