<template>
  <div class="keyboard-input-text"><span>{{keyboardDisplay}}</span></div>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: "PosKeyboardDisplay",
  props: ['id'],
  setup(props){
    const store = useStore()
    const keyboardDisplay = computed(() => store.state.pos.keyboardDisplay[props.id])
    return {keyboardDisplay}
  },
}
</script>