// initial state
const state = {
    waiters: [],
    active_waiter: null,
    active_terminal: null,
}

// getters
const getters = {
}

// actions
const actions = {
}

// mutations
const mutations = {
    set_waiters (state, waiters) {
        state.waiters = waiters
    },
    set_active_terminal (state, terminal) {
        state.active_terminal = terminal
    },
    set_active_waiter (state, waiter) {
        state.active_waiter = waiter
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}