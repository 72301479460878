<template>
  <div class="mx-system-app-card-wrapper fade-in-from-bottom"
      :class="{'no-transition':activeApp?.name === app.name}"
      @mousedown="activateApp(app)">

    <Card class="mx-system-app-card"  v-shared-element:[app.id] >
      <template #content>
        <router-link class="mx-system-card-link" :to="app.link ?? app.path">
          <div class="p-d-flex p-flex-row p-jc-between p-ai-center mke-md-p-2 mke-xl-p-3">
            <div class="p-d-flex p-ai-center gap-3">
              <div v-ripple class="mx-system-app-icon p-d-flex p-ai-center p-jc-center mx-fg-text-high">
                <i class="mx-icon" :class="'mx-icon-' + app.icon"/>
              </div>
              <div class="mx-system-app-name font-size-m" :style="app.enabled ? '' : 'text-decoration: line-through'">
                {{ app.name }}
              </div>
            </div>
            <i class="mdi mdi-chevron-right"/>
          </div>
        </router-link>
      </template>
    </Card>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: "MxAppIcon",
  props: ["app"],
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    const store = useStore();
    const activeApp = computed( () => store.state.general.activeApp)
    const activateApp = (app) => {
      store.commit("general/setActiveApp", app);
    }

    return {t, activeApp, activateApp};
  }
}
</script>

<style scoped>

</style>