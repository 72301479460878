<template>
  <div class="p-grid p-ai-center mke-px-0">
    <div v-if="label" class="p-col-4 mke-p-0">{{ label }}</div>
    <div class="p-col-8 mke-p-0">
      <Textarea
        :id="id"
        :placeholder="placeholder"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :disabled="readonly || false"
        class="block"
        rows="8" cols="500"
      >
      </Textarea>
      <mkeFormBuilderTranslationDialog v-if="translate == true" :fieldName="field" :translate="translate"
                                       :translationModel="translationModel" v-on:updateTranslation="$emit('update:modelValue', $event)"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "mkeInput",
  props: ["field", "id", "label", "modelValue", "placeholder", "readonly", "translate", "translationModel"],
  emits: ["update:modelValue"],
};
</script>

<style scoped></style>
