<template>
  <div class="mke-form-builder-image" :class="type">
    <!-- action-bar -->
    <div class="mke-form-builder-actions" v-if="image != undefined">
        <mke-button icon="mdi mdi-delete" v-tooltip="t('message.formbuilder.delete')" @click="image = null; $emit('update:modelValue', null)"/>
        <FileUpload class="edit" v-if="noUpload !== true" mode="basic" accept="image/*" :maxFileSize="5000000" @select="onFileSelection($event)" chooseLabel="t('message.formbuilder.edit')" v-tooltip="t('message.formbuilder.edit')" />
    </div>
    <!-- no upload - fallback image -->
    <div class="mke-form-builder-image-fallback" v-if="image == undefined">
      <div class="mke-form-builder-image-fallback-upload">
        <i class="fallback-icon mdi mdi-image-filter-hdr"/>
        <FileUpload class="hide-label" v-if="noUpload !== true" mode="basic" accept="image/*" :maxFileSize="5000000" @select="onFileSelection($event)" chooseLabel="t('message.formbuilder.choosePicture')" v-tooltip="t('message.formbuilder.choosePicture')"/>
      </div>
    </div>
    <!-- yes upload - show image -->
    <Image v-else :src="image" alt="Image" width="250"/>
  </div>
</template>

<script>
import {ref} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: "mkeFormBuilderImage",
  props: {
    "modelValue": String,
    "noUpload": Boolean,
    "type": {
      type: String,
      default: 'default'
    }
  },
  emits: ["update:modelValue"],
  setup(props, context){
    const image = ref(props.modelValue)
    const { t } = useI18n({ useScope: 'global' })

    function onFileSelection(event) {
      /**
       * Read the selected file and update the model value with it
       */
      event.files.forEach((file) => {
        let reader = new FileReader()
        reader.onload = (event) => {
          image.value = String(event.target.result)
          context.emit("update:modelValue", event.target.result)
        }
        reader.readAsDataURL(file)
      });
    }
    return {image, onFileSelection, t}
  }
}
</script>