<template>
    <mkeCrudOverview v-if="viewMode == 'overview'" :title="title" store="dashboard" :columns="schema" :create=create :actions="[]"/>
    <mkeCrudDetail v-if="viewMode == 'detail'" :schema="schema" store="dashboard" :model="model" :activeID="activeID" @onGoBack="onGoBack($event)"/>
</template>
<script>
import {computed} from "vue";
import {useStore} from "vuex";
import { useI18n } from 'vue-i18n'
import {getTranslatedSchema} from "@/json/widget_template_demo";

export default {
  props: {
    store: String,
    title: String,
    create: Boolean
  },
  emits: ["onRowClick"],
  setup(props){
    const store = useStore()
    const model = computed(() => store.state[props.store].model)
    const data = computed(() => store.state[props.store][model.value].items)
    const viewMode = computed(() => store.state[props.store].viewMode)
    const { t } = useI18n({ useScope: 'global' })
    const schema = computed(() => getTranslatedSchema(model.value, viewMode.value))
    const activeID = computed(() => store.state.dashboard.activeID)
    function onGoBack() {
      store.commit(props.store+"/setViewMode", "overview")
    }

    return {data, model, viewMode,schema, activeID,onGoBack, t}
  },
}
</script>
