<template>
<div 
  v-for="(component, index) in addStoreToLayout(keyboardLayout)"
  :key="index"
  class="p-flex-grow-1 surface-medium-opaque"
  style="height: 100%; width: 100%;"
  >
    <component
      :is="component.component"
      v-bind="component"
      @enterButtonPressed="enterButtonPressed"
      @resetFocus="onResetFocus"
    />
</div>
</template>

<script>
import {useStore} from "vuex";
import {computed, ref, watch} from "vue";

export default {
  name: "mkeKeyboard",
  props: ["layout", "store"],
  emits: ["enterButtonPressed", "resetFocus"],
  setup(props, context){
    const store = useStore()
    let keyboardLayoutMode = computed(() => store.getters["layout/getKeyboardLayoutMode"](props.store))
    const keyboardLayout = ref(store.getters["layout/getKeyboardLayout"](props.layout, 'default'))
    /**
     * Change keyboard based on layout mode
     */
    watch(keyboardLayoutMode, () => {
      keyboardLayout.value = store.getters["layout/getKeyboardLayout"](props.layout, keyboardLayoutMode.value)
    })
    /**
     * TODO: Think about a better solution to add the store to the components
     */
    const addStoreToLayout = (layout) => {
      if (layout){
        let newLayout = null
        newLayout = JSON.parse(JSON.stringify(layout[0]))
        if (newLayout) {
          const addStoreToComponent = (component) => {
            if (component.gridElements){
              component.gridElements.forEach(addStoreToComponent);
            } else {
              component.functionCallStore = props.store
            }
          }
          newLayout.gridElements.forEach(addStoreToComponent);
          return [newLayout]
        }
      } else {
        return [];
      }
    };
    /**
     * Receive and forward the enterButtonPressed emit
     */
    const enterButtonPressed = async () => {
      context.emit("enterButtonPressed")
    }

    /**
     * Receive and forward the resetFocus emit to set the focus to the input field
     */
    function onResetFocus() {
      context.emit("resetFocus")
    }

    return {addStoreToLayout, enterButtonPressed, keyboardLayout, keyboardLayoutMode, onResetFocus}
  },
  /**
   * Reset keyboard store on mount
   */
  mounted() {
    this.$store.commit("pos/setKeyboardDisplay", {
      keyboardDisplayId: this.store,
      value: ""
    })
  },
  /**
   * Reset keyboard store on unmount
   */
  unmounted(){
    this.$store.commit("pos/setKeyboardDisplay", {
      keyboardDisplayId: this.store,
      value: ""
    })
  }
}
</script>

<style scoped>

</style>