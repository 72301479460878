<template>
  <div
    id="application"
    class="login set-terminal"
    v-bind:class="[Interaction ? 'active' : '']"
  >

<div id="app-background">
        <div class="mke-background-gradient-element"></div>
      </div>
    <Toast />
    <transition name="fade-in-from-left">
      <div
        class="login-content"
        v-show="Interaction"
        align="top"
      >
        

        <div class="p-d-flex p-ai-center p-jc-center" style="pointer-events: all; height: 100%;">
          <perfect-scrollbar ref="scroll" class="waiter-list">
          <div
            class="waiter p-button-transparent p-ripple"
            v-ripple
            v-for="(terminal, index) in terminals"
            v-bind:key="index"
            @click="selectTerminal(terminal)"
          >
            <span>{{ terminal.terminal_name }}</span>
          </div>
        </perfect-scrollbar>
        <div
          id="keyboard"
          class=""
        >
          <div class="keyboard-input-text">
            <span v-if="selectedTerminal"
              >{{ selectedTerminal.terminal_name }}</span
            >
          </div>

          <Button
            class="col-start-1 col-end-4 row-start-6 p-button-primary"
            type=""
            label="Set Terminal"
            @click="buttonSetTerminal(selectedTerminal)"
          />
        </div>
      </div>
      </div>
    </transition>
   <div
      id="login-background"
      :style="Background"
      @click="Interaction = !Interaction"
    ></div>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";

export default {
  data() {
    return {
      Interaction: false,
      visibleLeft: false,
      Background: {
        // backgroundImage: `url(${require("@/assets/images/background-light.jpeg")})`,
      },
    };
  },
  setup() {
    const selectedTerminal = ref(false);
    const store = useStore()
    const terminals = computed(() => store.state.pos.terminals);
    store.dispatch("pos/getTerminals")

    /**
     * Set the selected terminal
     */
    const buttonSetTerminal = async (terminal) => {
      if (terminal == false) {
        return false;
      }
      store.dispatch("pos/setActiveTerminal", terminal)
    };

    const selectTerminal = async (terminal) => {
      selectedTerminal.value = terminal;
    };

    return {
      buttonSetTerminal,
      selectTerminal,
      selectedTerminal,
      terminals,
    };
  },
  beforeUnmount() {
    // Set Cookie
    // this.$cookies.set("pos-terminal", this.selectedTerminal);
  },
};
</script>

<style scoped>
</style>