<template>
  <div class="tree-panel p-d-flex p-flex-column mke-pt-2 mke-p-2 mke-m-2 p-jc-center"  :data-level="level" :data-default="combo.is_default">
    <div class="header p-d-flex p-justify-between p-align-center" :class="level <= 2 ? 'p-flex-row' : 'p-flex-column'">
      <h4 class="title " style="flex:1">{{combo.title ? combo.title : combo.name}}</h4>
      <div class="info p-d-flex p-flex-row p-justify-center" style="flex:2; ">
        {{info}}
      </div>
      <div v-if="level <= 2" class="actions p-d-flex p-flex-row p-justify-end" style="flex:1">
        <mke-button icon="mdi mdi-arrow-up-bold" color="transparent" fontsize="xs" v-if="level===2 && moveUpPossible" @click="$emit('moveComboUp', combo)"/>
        <mke-button icon="mdi mdi-arrow-down-bold" color="transparent" fontsize="xs" v-if="level===2 && moveDownPossible" @click="$emit('moveComboDown', combo)"/>
        <mke-button icon="mdi mdi-lead-pencil" color="transparent" fontsize="xs" @click="editCombo(combo)"/>
        <mke-button icon="mdi mdi-plus-thick" color="transparent" fontsize="xs" v-if="level===1" @click="addProduct(combo)"/>
        <mke-button icon="mdi mdi-minus-thick" color="transparent" fontsize="xs" v-if="level===2" @click="removeCombo(combo)"/>
      </div>
    </div>

    <template v-if="level < 2 && sortedChildren">
      <TransitionGroup name="fade-in-from-left" tag="div">
          <PosFormBuilderComboPanel
              v-for="(childCombo, index) in sortedChildren"
              :key="childCombo.key"
              :id="childCombo.id"
              :combo="childCombo"
              :level="level+1"
              :move-up-possible="index > 0"
              :move-down-possible="index < combo.entries.length - 1"
              @editCombo="editCombo"
              @removeCombo="removeCombo"
              @moveComboUp="moveComboUp"
              @moveComboDown="moveComboDown"
              v-on:modelValueUpdate="onModelValueUpdate"/>
      </TransitionGroup>
    </template>
    <div v-else class="p-d-flex p-flex-wrap ">
      <PosFormBuilderComboPanel
          v-for="(childCombo, index) in sortedChildren"
          :key="childCombo.key"
          class="combo-tile"
          :id="childCombo.id"
          :combo="childCombo"
          :level="level+1"
          :move-up-possible="index > 0"
          :move-down-possible="index < combo.entries.length - 1"
          @editCombo="editCombo"
          @removeCombo="removeCombo"
          @moveComboUp="moveComboUp"
          @moveComboDown="moveComboDown"
          v-on:modelValueUpdate="onModelValueUpdate"/>
    </div>
  </div>
</template>

<script>

import {computed} from "vue";
import {useI18n} from "vue-i18n/dist/vue-i18n.esm-bundler";

export default {
  name: "PosFormBuilderComboPanel",
  props: ["id", "combo", "level", "moveUpPossible", "moveDownPossible"],
  emits: ["modelValueUpdate", "editCombo", "addProduct", "moveComboUp", "moveComboDown"],

  setup(props,context) {
    const { t,n } = useI18n({ useScope: 'global' })
    
    const info = computed(() => {
      let infos = [];
  
      if( props.combo ) {
        const combo_price = props.combo.combo_price !== null && props.combo.combo_price >= 0 ? props.combo.combo_price : props.combo.product_price;
        
        if( props.level <= 2 ) {
          if (props.combo.is_default) {
            infos.push(t('message.masterdata.combo.default.label'));
          }
        }

        if( props.level <= 2 ) {
          if( props.combo.combo_min > 0 || props.combo.combo_max > 0 ) {
            if( props.combo.combo_min === props.combo.combo_max ) {
              if( props.combo.combo_min === 1 ) {
                infos.push(t('message.masterdata.combo.min_max.exact-one'));
              } else {
                infos.push(t('message.masterdata.combo.min_max.exact', [props.combo.combo_min]));
              }
            } else if( props.combo.combo_min > 0 && props.combo.combo_max > 0 ) {
              infos.push(t('message.masterdata.combo.min_max.range', [props.combo.combo_min, props.combo.combo_max]));
            } else if( props.combo.combo_min > 0 ) {
              if( props.combo.combo_min === 1 ) {
                infos.push(t('message.masterdata.combo.min_max.min-one'));
              } else {
                infos.push(t('message.masterdata.combo.min_max.min', [props.combo.combo_min]));
              }
            } else if( props.combo.combo_max > 0 ) {
              if( props.combo.combo_max === 1 ) {
                infos.push(t('message.masterdata.combo.min_max.max-one'));
              } else {
                infos.push(t('message.masterdata.combo.min_max.max', [props.combo.combo_max]));
              }
            }
          }
        }

        if( props.level > 2 ) {
          if( combo_price !== null && combo_price > 0) {
            infos.push("+" + n(combo_price, 'currency'));
          }
        } else {
          switch (props.combo.combo_selection_method) {
            case "combinable":
            case null:
              break;
            default:
              infos.push(t('message.masterdata.combo.selection-method.' + props.combo.combo_selection_method + '.label'));
          }
        }

        if( props.level <= 2 ) {
          const price_method = props.combo.combo_price_method ;
          const i18n_key = 'message.masterdata.combo.price-method.' + price_method + '.label-with-price';

          switch (price_method) {
            case null:
              if (combo_price !== null && combo_price > 0) {
                infos.push(n(combo_price, 'currency'));
              }
              break;
            case "add":
              if (combo_price !== null && combo_price > 0) {
                infos.push(t(i18n_key, [n(combo_price, 'currency')]));
              } else {
                infos.push(t('message.masterdata.combo.price-method.add.label'));
              }
              break;
            default:
              if (combo_price !== null && combo_price > 0) {
                infos.push(t(i18n_key, [n(combo_price, 'currency')]));
              }
          }
        }
      }

      return infos.join(" / ");
    });

    const sortedChildren = computed( () => {
      if( props.combo.entries ) {
        return props.combo.entries.filter(()=>true).sort((a,b) => a.index - b.index);
      } else {
        return [];
      }
    })

    /**
     * Triggered on model value update.
     */
    function onModelValueUpdate() {
      context.emit("modelValueUpdate")
    }

    function editCombo(combo) {
      context.emit("editCombo", combo)
    }

    function addProduct(combo) {
      context.emit("addProduct", combo)
    }

    function removeCombo(combo) {
      context.emit("removeCombo", combo)
    }

    function moveComboUp(combo) {
      context.emit("moveComboUp", combo)
    }

    function moveComboDown(combo) {
      context.emit("moveComboDown", combo)
    }

    return {sortedChildren, info, addProduct, removeCombo, moveComboUp, moveComboDown, editCombo, onModelValueUpdate};
  }
}
</script>

<style scoped></style>
