<template>
    <div class="p-d-flex p-flex-column mke-pt-2 mke-p-2 mke-m-2 mx-one-to-many-panel" >
      <mke-button v-if="add" icon="mdi mdi-plus-thick" color="transparent" fontsize="xs" @click="addItem()"/>
      <div>
        <draggable
            tag="transition-group"
            v-model="items"
            item-key="key"
            v-bind="dragOptions"
            @start="onDragStart"
            @end="onDragEnd"
        >
          <template #item="{element}">
            <div class="header p-d-flex p-justify-between p-align-center p-flex-row mx-draggable mke-mb-2" >
              <i class="mdi mdi-drag-vertical mke-mr-2"/>
              <span>{{element.index}}:</span>
              <mkeFormBuilder :inline="true" :store="modelStore" :schema="elements" :activeID="modelID" :data="element" v-on:modelValueUpdate="onModelValueUpdate"/>
              <div class="actions p-d-flex p-flex-row p-justify-end" style="flex:1">
                <mke-button v-if="edit" icon="mdi mdi-pencil" color="transparent" fontsize="xs" @click="editItem(element)"/>
                <mke-button icon="mdi mdi-minus-thick" color="transparent" fontsize="xs" @click="removeItem(element)"/>
              </div>
            </div>
          </template>
        </draggable>
      </div>

      <!-- Component/Dialog to add new items-->
      <component v-if="add" :is="add.component" :visible="addItemVisible"
                 v-bind="add"
                 @onItemAddCommitted="itemAddCommitted"
                 @onItemAddCanceled="itemAddCanceled"/>

      <!-- Component/Dialog to edit items-->
      <component v-if="edit" :is="edit.component" :visible="editItemVisible"
                 :item="itemToEdit"
                 v-bind="edit"
                 @onItemUpdateCommitted="itemEditCommitted"
                 @onItemUpdateCanceled="itemEditCanceled"/>
  </div>
</template>

<script>

import {inject, ref, computed} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: "mkeFormBuilderOneToManyPanel",
  props: ["id", "modelValue", "field", "selectionTitle", "selectionType", "elements", "add", "edit"],
  emits: ["update:modelValue", "modelValueUpdate", "setModelValue"],

  setup(props, context) {
    const { t } = useI18n({ useScope: 'global' });
    const addItemVisible = ref( false );

    const setModelValue = inject('setModelValue');

    const modelStore = inject('modelStore');
    const modelID = inject('modelID');

    const dragOptions = ref( {
      animation: 200,
      disabled: false,
      ghostClass: "ghost"
    } );

    const items = computed({
      get() {
        if( props.modelValue ) {
          return props.modelValue.map((item, index) => {
            const keyedItem = {...item};
            keyedItem.key = keyedItem.key ?? index+1;
            return keyedItem;
          });
        } else {
          return [];
        }
      },
      set(sortedItems) {
        setModelValue({field:props.field, value:sortedItems});
      }
    });

    let nextKey = 1;
    if( props.modelValue ) {
      // Calculate the max index for all items and increase it for the next key
      nextKey = Math.max(...props.modelValue.map((item)=>item.index)) + 1;
    }

    function addItem() {
      addItemVisible.value = true;
    }

    function itemAddCommitted(itemsAdded) {
      addItemVisible.value = false;

      const newItems = [];
      let currentIndex = (props.modelValue?.length ?? 0) + 1;

      itemsAdded.forEach( (item) => {
        let newItem = { index: currentIndex + newItems.length}
        for( let [targetField, sourceField] of Object.entries(props.add.mapping) ) {
          newItem[targetField] = item[sourceField];
        }
        newItem.key = nextKey++;
        newItems.push( newItem );
      });

      if( props.modelValue ) {
        setItems(props.modelValue.concat(newItems));
      } else {
        setItems(newItems);

      }
    }

    function itemAddCanceled() {
      addItemVisible.value = false;
    }

    const itemToEdit = ref(null );

    function editItem(item) {
      itemToEdit.value = item;
    }
    const editItemVisible = computed( () => !!itemToEdit.value );

    function itemEditCommitted(itemsUpdated) {
      const updatedItems = props.modelValue.map( (item) => {
        const updatedItem = {...item};
        const updatedValues = itemsUpdated.find((i) => i.index === item.index);

        if( updatedValues ) {
          for( let [targetField, sourceField] of Object.entries(props.edit.mapping) ) {
            updatedItem[targetField] = updatedValues[sourceField];
          }
        }
        return updatedItem;
      });
      setItems(updatedItems);
      itemToEdit.value = null;
    }

    function itemEditCanceled() {
      itemToEdit.value = null;
    }

    function removeItem(item) {
      const newItems = props.modelValue.filter( (i) => i.index !== item.index ).map((i)=>{ return {...i} });

      newItems.forEach((item, index) => { item.index = index+1;});
      setItems(newItems);
    }



    function onDragStart() {
    }

    function onDragEnd(event) {

      const sourceItemIndex = event.oldIndex + 1;
      const targetItemIndex = event.newIndex + 1;

      if( targetItemIndex !== sourceItemIndex ) {
        const movedItems = props.modelValue.map( (item) => {
          const newItem = {...item};

          if( item.index === sourceItemIndex ) {
            newItem.index = targetItemIndex;
          } else if( item.index >= targetItemIndex && targetItemIndex < sourceItemIndex ) {
            newItem.index = newItem.index+1;
          } else if( item.index <= targetItemIndex && targetItemIndex > sourceItemIndex ) {
            newItem.index = newItem.index-1;
          }

          return newItem;
        });

        movedItems.sort((a,b) => a.index - b.index );
        movedItems.forEach((item, i) => item.index = i+1);

        setItems(movedItems);
      }
    }

    function setItems(items) {
      const sortedItems = [].concat(items);
      sortedItems.sort( (a,b) => a.index - b.index);

      setModelValue({field:props.field, value:sortedItems});
    }

    function onModelValueUpdate() {
      context.emit("modelValueUpdate")
    }

    return {t,
      modelStore, modelID, onModelValueUpdate,
      addItem, addItemVisible, removeItem,
      itemAddCommitted, itemAddCanceled,
      editItem, editItemVisible, itemToEdit,
      itemEditCommitted, itemEditCanceled,
      onDragStart, onDragEnd, items, dragOptions};
  }
}
</script>

<style scoped></style>
