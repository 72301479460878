export default function createWebSocketPlugin(socket) {
    return store => {
        var jwtemit = function(ev, ...args) {
            let jwttoken = {token: store.state.general.jwtToken}
            if (jwttoken) {
                args.unshift(jwttoken)
            }
            socket.emit(ev, ...args)
        }
        socket.on('connect', () => {
            jwtemit('authenticate')
        });
        socket.on('set_cubes', (dashboards) => {
            store.commit('dashboard/set_cubes', dashboards)
        })
        socket.on('set_synch_state', (sync_state) => {
            store.commit('dashboard/set_synch_info', sync_state['sync_state']['sync_info'])
            store.commit('dashboard/set_synch_state', sync_state['sync_state']['sync_state'])
            let active_dashboard_is_live = store.getters['dashboard/get_active_dashboard_live_state']()
            if( active_dashboard_is_live !== false){
                store.dispatch('dashboard/calculate_dashboard', {spinner: false} )
            }
        })
        socket.on('set_dashboards', (dashboards) => {
            store.commit('dashboard/set_dashboards', dashboards)
        })
        socket.on('set_active_dashboard', (dashboard_id) => {
            store.commit('dashboard/set_active_dashboard', dashboard_id)
            store.dispatch('dashboard/setMainView', 'dashboards')
            store.dispatch('dashboard/calculate_dashboard')
        })
        socket.on('add_dataservice', (dataservice) => {
            store.commit('dashboard/add_dataservice', dataservice)
        })
        socket.on('update_dataservice', (dataservice) => {
            store.commit('dashboard/update_dataservice', dataservice)
        })
        socket.on('add_toast', (toast) => {
            store.commit("general/addToast", toast)
        })
        socket.on('delete_dataservice', (dataservice_id) => {
            store.commit('dashboard/delete_dataservice', dataservice_id)
        })
        socket.on('set_dataservices', (dataservices) => {
            store.commit('dashboard/set_dataservices', dataservices)
        })
        socket.on('set_outlets', (outlets) => {
            store.commit('dashboard/set_outlets', outlets)
        })
        socket.on('set_widget_values', (widget_values) => {
            store.commit('dashboard/set_widget_values', widget_values)
            store.commit('dashboard/set_is_loading', false)
        })
        store.subscribeAction((action, state) => {
            if (action.type === "dashboard/calculate_dashboard") {
                if (action.payload != undefined && action.payload.widget_id != undefined){
                    jwtemit('calculate_dashboard', state.dashboard.dashboards[state.dashboard.active_dashboard], {token: store.state.dashboard.jwt}, action.payload.widget_id)
                } else {
                    jwtemit('calculate_dashboard', state.dashboard.dashboards[state.dashboard.active_dashboard], {token: store.state.dashboard.jwt})
                }
            } else if (action.type === "dashboard/createDashboard") {
                jwtemit('create_dashboard', action.payload)
            } else if (action.type === "dashboard/deleteDashboard") {
                jwtemit('delete_dashboard', {dashboard_id: action.payload, token: store.state.dashboard.jwt})
            } else if (action.type === "dashboard/createWidget") {
                jwtemit('create_widget', state.dashboard.dashboards[state.dashboard.active_dashboard], {widget_config: action.payload, token: store.state.dashboard.jwt})
            }
        })
        store.subscribe((mutation, state) => {
            if (mutation.type === "dashboard/switch_datasource_sync") {
                jwtemit('set_datasource_sync', {
                    'datasource_id': mutation.payload.datasource_id,
                    'sync': mutation.payload.sync
                })
            } else if (mutation.type === "dashboard/editWidget") {
                store.dispatch('dashboard/calculate_dashboard', {widget_id:mutation.payload.widget_id})
            } else if (mutation.type === "dashboard/update_dashboard_name") {
                jwtemit('update_dashboard_name', mutation.payload)
            } else if (mutation.type === "dashboard/reset_dashboard") {
                jwtemit('reset_dashboard')
            } else if (mutation.type === "dashboard/delete_widget") {
                jwtemit('delete_widget', mutation.payload)
            } else if (mutation.type === "dashboard/set_widget_coordinates") {
                jwtemit('set_dashboard_coordinates', state.dashboard.dashboards[state.dashboard.active_dashboard].widget_coordinates)
            }
        });
    };
}
