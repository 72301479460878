<template>
  <div :id="id" class="w-100">
    <div class="">
      <InputText v-model="inputText" class="font-size-l w-100"></InputText>
    </div>
    <div class="mx-keyboard">
    <div class="mx-functions mx-keyboard-numpad">
      <div class="mx-module-grid cols_4 rows_4 layout_floating" >
        <mke-button
            v-for="button in mode === 'numpad' ? numButtons : moneyButtons"
            :key="button.id || button.value"
            :id="id + '-button-' + (button.id || button.value)"
            :icon="button.icon"
            iconPos="center"
            :label="button.label || button.value "
            :color="button.color || 'surface-medium'"
            class="w-100 font-size-xxl"
            @click="button.action ? button.action() : addText(button.value)"
        />
      </div>
    </div>
    </div>
  </div>

</template>

<script>

import {ref, computed} from "vue";

export default {
  name: "PosNumpadSplit",
  props: ['id', 'value'],
  emits: ['numpad:change', 'numpad:create', 'numpad:close'],
  setup(props, ctx){
    const inputText = ref("");
    const amount = ref(0);
    const mode=ref("numpad");
    const selectAllColor=ref("surface-medium");

    if( props.value ) {
      const value = mode.value === "numpad" ? props.value.inputText : props.value.value;

      addText(value ?? "");
    }

    function addText(value) {
      if( mode.value === "numpad" ) {
        inputText.value += value;
      } else {
        amount.value += value;
      }
      changed();
    }

    function deleteText() {
      inputText.value = inputText.value.slice(0, -1);
      changed();
    }

    function clearText() {
      if( mode.value === "numpad" ) {
        inputText.value = "";
      } else {
        amount.value = 0;
      }
      changed();
    }

    function changed() {
      if( selectAllColor.value === "warning" ) {
        selectAllColor.value = "surface-medium"
      }
      if( mode.value === "numpad" ) {
        if( inputText.value === "" ) {
          amount.value = 0;
        } else {
          amount.value = parseFloat(inputText.value);
        }
      } else {
        inputText.value = String(amount.value);
      }
      emitState();
    }

    function selectAll() {
      clearText();
      selectAllColor.value = "warning"
      emitState()
    }

    function createProcess() {
      const newProcessName = inputText.value;

      clearText();

      ctx.emit("numpad:create", {name:newProcessName})
    }

    function emitState() {
      const state = {
        value: amount.value,
        text: inputText.value,
        all: selectAllColor.value == "warning"
      }

      ctx.emit('numpad:change', state);
    }

    function consumed() {
      if( mode.value === "numpad" ) {
        inputText.value = "";
      } else {
        amount.value = 0;
      }
      selectAllColor.value = "surface-medium";
      changed();
    }

    const numButtons = computed( () => [
      {"value": "7", },
      {"value": "8"},
      {"value": "9"},
      {"id": "backspace", "icon" : "mdi mdi-backspace-outline", "color": "danger", "action": () => deleteText() },
      {"value": "4"},
      {"value": "5"},
      {"value": "6"},
      {"id": "clear", "label":"C", "color": "danger", "action": () => clearText() },
      {"value": "1"},
      {"value": "2"},
      {"value": "3"},
      {"id": "select-all", "label": "Alle", "color": selectAllColor.value, "action": () => selectAll()},
      {"value": "0"},
      {"id":"point", "value": "."},
      {"id": "create", "color": "primary", "icon" : "mdi mdi-plus", "action": () => createProcess()},
    ] );

    return {numButtons, addText, createProcess, inputText, selectAll, consumed, mode}
  },
}
</script>