<template>
  <div >
    <TabView>
      <TabPanel v-for="tab in tabs" :key="tab.id" :header="tab.title" >
        <mkeFormBuilder :store="store" :schema="tab.elements" :activeID="activeID" :data="data" :options="options" v-on:modelValueUpdate="onModelValueUpdate"/>
      </TabPanel>
    </TabView>

  </div>
</template>

<script>
export default {
  name: "mkeTab",
  props: ["id", "tabs", "data", "options", "store", "activeID"],
  emits: ["modelValueUpdate"],

  setup(props,context) {
    /**
     * Triggered on model value update.
     */
    function onModelValueUpdate(field, value) {
      context.emit("modelValueUpdate", field, value);
    }

    return {onModelValueUpdate};
  }
}
</script>

<style scoped></style>
