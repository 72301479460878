<template>
  <div v-if="!displayDashboard">
  <div class="p-grid mke-pt-5">
    <div class="p-col-fixed p-d-flex p-ai-center">
      <h1 class="mke-page-title display-small p-d-inline-block">
        {{ t('message.home.all') }} {{ t('message.crud.model.' + model) }}
      </h1>
    </div>
    <div v-if="create" class="p-col p-d-flex p-ai-center">
      <mke-button
            id="button-model-overview-new"
            :label="t('message.crud.new')"
            icon="pi pi-plus"
            class="p-button-primary no-shrink crud-neu"
            @click="onEdit()"
          />
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <mkeDataTable :modelData="data" :columns="columns" :actions="actions"
                      :filters="filters" :lazy="true"  @onFilter="onFilter"
                      :deleteButton="true" :editButton="true" :loading="loading"
                      @onDelete="onDelete" @onEdit="onEdit" @onNew="onEdit" @onPage="onPage"
                      @onRowClick="onRowClick" @onSort="onSort" @onAction="onAction"
                      :rows="tableRows" selectionMode="multiple" :totalRecords="totalRecords"
                      :rowsPerPageOptions="[10, 20, 50, 100]"/>

      </div>
    </div>
  </div>
  </div>
  <div v-else>
    <DshDashboard/>
  </div>
</template>

<script>
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import { useI18n } from 'vue-i18n'
import DshDashboard from "@/components/dashboard/dsh_dashboard";

export default {
  name: "mkeCrudOverview",
  components: { DshDashboard,},
  props: {
    actions: Array,
    title: String,
    columns: Object,
    rowCount: Number,
    store: String,
    create: {type:Boolean, default: true}
  },
  emits: ["onRowClick"],
  setup(props, context){
    const store = useStore()
    const router = useRouter()
    const model = computed(() => store.state[props.store].model)
    const data = computed(() => store.state[props.store][model.value].items)
    const lazyParams = ref({});
    const loading = ref(false)
    const tableRows = ref(router.currentRoute.value.query.limit || props.rowCount || 10)
    const totalRecords = computed(() => store.state[props.store][model.value].total)
    const { t } = useI18n({ useScope: 'global' })
    const displayDashboard = computed({
      get: () => {return store.state.dashboard. dashboard_visible},
      set: (value) => (store.commit(props.store+"/set_dashboard_visible", value))
    })
    /**
     * Load new data (paginated)
     */
    const loadLazyData = () => {
      loading.value = true;
      store.dispatch(props.store + "/getData", {
        model: model.value,
        offset: lazyParams.value.first || 0,
        limit: lazyParams.value.rows || tableRows.value || 0,
        order: lazyParams.value.sortOrder,
        sort: lazyParams.value.sortField,
        filter: lazyParams.value.filters
      })
      loading.value = false;

      // Write data for url into the query
      // let queryData = JSON.parse(JSON.stringify(router.currentRoute.value.query))
      // queryData.offset = lazyParams.value.first
      // queryData.limit = tableRows.value
      // queryData.order = lazyParams.value.sortOrder
      // queryData.sort = lazyParams.value.sortField
      // router.push({query: queryData})
    };
    const filters = computed( () => {
      const filterEntries = props.columns
          .filter(column => column.filter)
          .map(column => [column.field, {
            value: lazyParams.value.filters && lazyParams.value.filters[column.field] ? lazyParams.value.filters[column.field].value : "",
            matchMode: column.filter
          }]);

      return Object.fromEntries(new Map(filterEntries));
    } );
    /**
     * Load new data if the model changes
     */
    watch(model, () => {
      loadLazyData()
    })

    /**
     * Load the initial data
     */
    onMounted(() => {
      loading.value = true;
      lazyParams.value = {
        first: router.currentRoute.value.query.offset || 0,
        rows: tableRows.value,
        sortField: null,
        sortOrder: null,
      };
      loadLazyData();
    })

    /**
     * Called on action selection. Used to emit the selected action with the selected data.
     * @param {String} action The action to emit
     * @param {Array} selectedData The selected data
     */
    const onAction = ({action, selectedData}) => {
      context.emit(action, selectedData)
    }

    /**
     * Delete the given data
     * @param Object data The data to delete
     */
    const onDelete = (data) => {
      store.dispatch(props.store + "/deleteData", {model: model.value, id: data.id})
    }

    /**
     * Load new data on table page change
     */
    const onPage = (event) => {
      lazyParams.value = event;
      loadLazyData();
    };
    /**
     * handel click and dbclick on datatable row
     *
     */
    const onEdit= (data) => {
      if (data){
        store.commit(props.store + "/setActiveID", data.id)
      } else {
        store.commit(props.store + "/setActiveID", null)
      }
      store.commit(props.store + "/setViewMode", "detail")
      context.emit("onRowClick", {...data})
    }
    const onRowClick = (data) => {
      if (model.value == "dashboard_names"){
        store.commit('dashboard/set_active_dashboard', data['id'])
        store.commit("dashboard/set_dashboard_visible", true)
        // store.dispatch('dashboard/setMainView', 'dashboards')
        store.dispatch('dashboard/calculate_dashboard')
        displayDashboard.value = true
      }

    };

    /**
     * Load new data on column sorting
     */
    const onSort = (event) => {
      lazyParams.value = event;
      loadLazyData();
    };
    /**
     * Load new data on column sorting
     */
    const onFilter = (event) => {
      lazyParams.value = event;
      loadLazyData();
    };
    return {data, loading, model,filters, onAction, onDelete, onPage, onEdit, onSort, tableRows, totalRecords, onRowClick, displayDashboard, onFilter, t}
  },
}
</script>
