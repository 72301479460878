<template>
  <div>
    <mkeButton v-if="activeID" :label="lang_code" @click="showTranslationDialog()"/>
    <Dialog v-model:visible="showDialog" :style="{width: '450px'}" :modal="true" :dismissableMask="true">
      <template #header>
        <h5>{{ t('message.masterdata.translationDialog') }}</h5>
      </template>
      <form @submit.prevent="">
        <div
            v-for="(language) in languages"
            :key="language.id"
            class="p-d-flex p-flex-column gap-4 mke-p-0"
        >
          <mkeFormBuilderInputText :label="language.name" v-model:model-value="translations[language.code]"/>
        </div>
      </form>
      <template #footer>
        <Divider layout="horizontal"/>
        <mkeButton
            label="Cancel"
            icon="mdi mdi-close"
            class="p-mt-2 p-button-transparent"
            @click="showDialog = false">
        </mkeButton>
        <mkeButton
            label="Save"
            icon="mdi mdi-check"
            class="p-mt-2"
            @click="save()">
        </mkeButton>
      </template>
    </Dialog>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: "mkeFormBuilderTranslationDialog",
  props: ['fieldName', 'translationModel'],
  emits: ["updateTranslation"],
  setup(props, context) {
    const store = useStore();
    const activeID = computed(() => store.state.admin.activeID)
    const lang_code = computed(() => store.state.general.activeUser.user_lang)
    const showDialog = ref(false)
    const languages = ref({})
    const translations = ref({})
    const {t} = useI18n({useScope: 'global'})

    function showTranslationDialog() {
      // Get Languages
      store.dispatch("admin/retrieveData", {
        model: "languages",
        sort: "name",
        offset: 0,
        limit: 100,
        successHandler: (data) => {
          languages.value = data.items;
        }
      });
      // Get Translations
      store.dispatch("admin/getAllTranslationsForObject", {
        field: props.fieldName,
        object_id: activeID.value,
        translation_model: props.translationModel,
        onSuccessCallback: (newData) => {
          translations.value = [...newData].reduce((obj, item) => Object.assign(obj, {[item.language_code]: item.name}), {})
        }
      });
      showDialog.value = true
    }

    function save() {
      store.dispatch("admin/saveAllTranslationsForObject", {
        field: props.fieldName,
        object_id: activeID.value,
        translations: translations.value,
        translation_model: props.translationModel,
        onSuccessCallback: () => {
          showDialog.value = false
          context.emit("updateTranslation", translations.value[lang_code.value])
        }
      });
    }

    return {activeID, lang_code, languages, save, showDialog, showTranslationDialog, translations, t}
  }
}
</script>

<style scoped>

</style>