<template>
  <div>
    <mq-responsive target="lg+">
      <div id="sidebar">
        <div class="application-logo">
          <router-link to="/home">
              <img :src="require('@/assets/images/'+`${logo}`)"/>
          </router-link>
        </div>
        <TieredMenu :model="items">
          <template #item="{item}">
            <a :id="item.id" class="p-menuitem-link" :class="{active: activeItemId === item.id}"
               aria-haspopup="false" aria-expanded="false" role="menuitem" tabindex="0" @click="itemSelected(item)">
              <span class="p-menuitem-icon mdi " :class="item.icon ? 'mdi-' + item.icon : 'mdi-view-dashboard'"></span>
              <span class="p-menuitem-text">{{item.label}}</span>
            </a>
          </template>
        </TieredMenu>
      </div>
    </mq-responsive>

    <mq-responsive target="lg-">
      <Sidebar v-model:visible="mobileMenuVisible" :baseZIndex="1000">
        <div id="sidebar" class="mobile">
          <router-link to="/home">
              <img :src="require('@/assets/images/'+`${logo}`)"/>
          </router-link>
          <PanelMenu :model="items" />
        </div>
      </Sidebar>
    </mq-responsive>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";

export default {
  props: ["items", "selectedItemId"],
  setup(props) {
    const store = useStore();
    const mobileMenuVisible = computed({
      get: () => {return store.state.admin.mobile_menu_visible},
      set: (value) => (store.commit("admin/set_mobile_menu_visible", value))
    })
    const logo = config.logo
    const activeItemId = ref(props.selectedItemId ? props.selectedItemId : "");
    const itemSelected = (item) => {
      activeItemId.value = item.id;
      item.command();
    }


    return {activeItemId, itemSelected, mobileMenuVisible, logo};
  },
};
</script>