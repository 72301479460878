<template>
  <div :id="id" class="mx-product-explorer">
    <TabView class="fade" lazy @tab-change="selectTopNavigation" :activeIndex="0">
      
      <TabPanel v-for="topNavigation in topNavigations" :key="topNavigation.id" >
        <template #header>
          <i v-if="topNavigation.icon" class="mdi mke-mr-1 p-d-flex p-ai-center" :class="'mdi-' + topNavigation.icon"></i>
          <span>{{topNavigation.button_text}}</span>
        </template>
        <div class="mx-tab-panel" :class="currentTopNavigation.navigations?.length > 0 && currentTopNavigation.id === topNavigation.id ? 'with-sidebar' : 'without-sidebar'">

          <Transition name="fade-fast" appear>
            <div v-if="currentTopNavigation.navigations?.length > 0 && currentTopNavigation.id === topNavigation.id"
                 class="mx-product-explorer-sidebar" >
              <Accordion class="accordion-custom" >
                  <AccordionTab v-for="subNavigation in currentTopNavigation.navigations" :key="subNavigation.name" >
                    <template #header>
                      <mke-button :icon="subNavigation.icon ? 'mdi mdi-' + subNavigation.icon : ''"
                                  :label="subNavigation.button_text"
                                  color="surface-low"
                                  fontsize="s"
                                  @click="selectSubNavigation(subNavigation)"/>
                    </template>
                    <Accordion class="accordion-custom" v-if="subNavigation.navigations">
                      <AccordionTab v-for="c2 in subNavigation.navigations" :key="c2.name">
                        <template #header>
                          <mke-button :icon="c2.icon ? 'mdi mdi-' + c2.icon : ''"
                                      :label="c2.button_text"
                                      color="surface-low" fontsize="s"
                                      @click="selectSubNavigation(c2)"/>
                        </template>
                        <Accordion class="accordion-custom" v-if="c2.navigations">
                          <AccordionTab v-for="c3 in c2.navigations" :key="c3.name">
                            <template #header>
                              <mke-button :icon="c3.icon ? 'mdi mdi-' + c3.icon : ''"
                                          :label="c3.button_text"
                                          color="surface-low"
                                          fontsize="s"
                                          @click="selectSubNavigation(c3)"/>
                            </template>
                          </AccordionTab>
                        </Accordion>
                      </AccordionTab>
                    </Accordion>
                  </AccordionTab>
              </Accordion>
            </div>
          </Transition>

          <div class="mx-product-explorer-content">
            <div class="mx-module-grid"
                 :class="[currentNavigation.grid_cols ? 'cols_' + currentNavigation.grid_cols : '', currentNavigation.grid_rows ? 'rows_' + currentNavigation.grid_rows : '', currentNavigation.button_layout === 'grid' ? 'layout_grid' : 'layout_floating']"
                 v-if="currentTopNavigation.id === topNavigation.id">
                <mke-button v-for="entry in entries" :key="entry.key"
                            :label="entry.button_text" :icon="entry.icon"
                            :class="{snap:entry.snap}" class="mx-product-button"
                            :data-button-id="entry.id"
                            :data-button-name="entry.name"
                            :data-button-plu="entry.plu"
                            :color="entry.color" :fontsize="entry.fontSize" :pos-action="entry.pos_action" />
            </div>

          </div>
        </div>

      </TabPanel>
      
    </TabView>

    <div class="mx-product-explorer-actions">
      <mke-button icon="mdi mdi-filter-outline" @click="toggleRecDialog" />
      <mke-button icon="mdi mdi-microphone"  />

      <Dialog class="scrollbar-gutter" header="Weinempfehlung" v-model:visible="displayRecDialog" :modal="true" :draggable="false">

        <div class="mx-recommendation mke-mb-4" v-for="recommendation in recommendations" :key="recommendation.product_name">
          <!-- component "Matrix Divider" -->
          <div class="mx-divider-with-headline p-d-flex p-flex-column">
            <div class="mx-divider-with-headline-text p-d-flex p-ai-baseline">
              <div class="mx-divider-headline">{{ recommendation.product_name }}</div>
              <div class="mx-divider-desc mx-fg-text-medium mke-ml-1">{{ recommendation.product_addon.join(', ') }}</div>
            </div>
            <Divider />
          </div>
          <!-- Empfehlung -->
          <div class="mx-recommendation-entry mke-py-1" v-for="wine in recommendation.wine_names" :key="wine">
            <div class="p-d-flex p-ai-center p-jc-between">
              <div class="mke-pl-2">{{ wine }}</div>
              <div class="p-d-flex gap-1">
                <div class="mx-button-with-badge">
                  <mke-button label="0,1" icon="mdi mdi-glass-wine font-size-l"/>
                  <!-- <div class="p-badge p-badge-danger bottom-right">1</div> -->
                </div>

                <div class="mx-button-with-badge">
                  <mke-button label="0,2" icon="mdi mdi-glass-wine font-size-l"/>
                  <!-- <div class="p-badge p-badge-danger bottom-right"></div> -->
                </div>

                <div class="mx-button-with-badge">
                  <mke-button :color="displayRecAddon? 'primary' : ''" label="0,7" icon="mdi mdi-glass-wine font-size-l" @click="toggleRecAddon"/>
                  <div class="p-badge p-badge-danger bottom-right" v-show="displayRecAddon">1</div>
                </div>
              </div>
            </div>
            <!-- Abfrage nach der Anzahl der Gläser, wenn "Flasche" gewählt -->
            <mx-transition-expand>
              <div class="mx-recommendation-entry-addon" v-if="displayRecAddon">
                <div class="p-d-flex p-jc-end p-ai-center mke-pt-2">
                  <div class="mx-fg-text-medium mke-pr-2">Wie viele Gläser dazu?</div>
                  <div class="p-d-flex p-ai-center gap-2">
                    <mke-button icon="mdi mdi-minus" color="surface-low" />
                    <span class="">1</span>
                    <mke-button icon="mdi mdi-plus" color="surface-low" />
                  </div>
                </div>
              </div>
            </mx-transition-expand>
          </div>
        </div>

        <template #footer>
          <div class="p-d-flex p-ai-center p-jc-between">
            <div class="mx-fg-text-medium">Auswahl für 2 Gerichte getroffen</div>
            <div class="p-d-flex">
              <mke-button color="transparent" label="Abbrechen" @click="toggleRecDialog" />
              <mke-button color="primary" label="Übernehmen" @click="toggleRecDialog" />
            </div>
          </div>
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>

import {useStore} from "vuex";
import {onMounted, ref} from "vue";

export default {
  name: "PosProductExplorer",
  props: ['id', 'target'],
  setup(){
    const store = useStore();

    const topNavigations = ref([]);
    const currentTopNavigation = ref({});
    const currentNavigation = ref({});
    const entries = ref([]);
    const activeNavigationIndex = ref(0);

    onMounted(() => {
      window.setTimeout(() => {
        store.dispatch("pos/getNavigationForTarget", {
          target: "product_explorer",
          mode: "top",
          successHandler: (data) => {
            if( data.length > 0 ) {
              topNavigations.value = data;
              selectTopNavigation({index:0});
            } else {
              store.commit("pos/setPosInstallationMode", "not_installed");
            }
          }});
      }, 10);
    });

    const getIcon = (button) => {
      if( button.icon ) {
        return 'mdi mdi-' + button.icon;
      } else {
        return null;
      }
    }

    const getButtonText = (button) => {
      if( button.icon ) {
        return "";
      } else {
        return button.button_text || button.label || button.value
      }
    }

    function selectTopNavigation({index}) {
      const selectedTopNavigation = topNavigations.value[index];
      const selectedID = selectedTopNavigation.id;

      if( selectedTopNavigation.entries ) {
        applyTopNavigation(selectedTopNavigation);
      }

      store.dispatch("pos/getNavigation", {
        id: selectedID,
        successHandler: applyTopNavigation
      });
    }

    function applyTopNavigation(data) {
      currentTopNavigation.value = data;
      currentNavigation.value = data;
      activeNavigationIndex.value = 0;

      const topNavigationIndex = topNavigations.value.findIndex( (n) => n.id === data.id );
      if( topNavigationIndex >= 0 ) {
        topNavigations.value[topNavigationIndex] = data;
      }

      selectSubNavigation(data);
    }

    function selectSubNavigation(navigation) {
      currentNavigation.value = navigation;

      let navigation_entries = [];
      if( currentNavigation.value.entries?.length > 0) {
        navigation_entries = currentNavigation.value.entries;
      }

      if( navigation_entries.length === 0 ) {
        navigation_entries = aggregateEntries(navigation)
        // Build unique list of entries by id
        navigation_entries = [...new Map(navigation_entries.map((entry) => [entry.id, entry])).values()];
      }

      entries.value = navigation_entries.sort((a,b)=> a.button_text.localeCompare(b.button_text));

      calculateSnapPoints();
    }

    function aggregateEntries(navigation) {
      let entries = navigation.entries ? [].concat(navigation.entries) : [];
      if( navigation.navigations ) {
        navigation.navigations.forEach((subNavigation) => {
          entries = entries.concat(aggregateEntries(subNavigation));
        })
      }
      return entries;
    }

    /**
     * Calculates the buttons, which should be the snap points for the product grid,
     * when the user is scrolling within it.
     *
     * The first button is a snap point, for that the user can scroll to the top of the grid.
     * The last button is a snap point, for that the user can scroll to the end of the grid.
     * If the bottom of a button is outside of the visible view, if the user is scrolled to the previous snap point,
     * this button will be the next snap point.
     *
     * For this function to work, it's important that the scrolling container isn't positioned static - it must be
     * at least relative.
     */
    // eslint-disable-next-line no-unused-vars
    function calculateSnapPointsInternal() {
      // The scrolling container
      const container = document.querySelector("#pos__product-explorer .mx-product-explorer-content");

      if( container ) {
        // The height of the scrolling container, which is needed for calculating the next snap point
        const containerHeight = container.clientHeight;
        // The html buttons to calculate their dimensions
        const entryButtons = container.querySelectorAll(".mx-product-button");

        // The first button exceeding the container height, meaning it isn't fully visible, is a snap point
        let nextSnapBreakpoint = containerHeight;

        console.log("\n\nCALCULATE SNAP POINTS\n");

        entries.value?.forEach((p, index) => {
          // p.color = "";
          // Get the corresponding html button
          const productButton = entryButtons[index];

          if( productButton ) {
            // Get the placement of the button relative to the scrolling container
            const buttonTop = productButton.offsetTop;
            const buttonBottom = buttonTop + productButton.clientHeight;

            // Is it a snap point?
            p.snap = index === 0 || index+1 === entryButtons.length || buttonBottom > nextSnapBreakpoint;

            // console.log( p.name + "[" + productButton.innerText + "]-> top: " + buttonTop + ", bottom: " + buttonBottom + ", breakpoint: " + nextSnapBreakpoint + ", snap: " + p.snap);

            // the button exceeds the visible area, therefore it is a snap point and
            // we have to calculate the next limiting point:
            // which is the top of the current buttom plus the height of the scroll container
            if( buttonBottom > nextSnapBreakpoint ) {
              // for debug resaons: color the snap button and the previous buttons, so that we can see, if it works well
              // p.color = "primary";
              // entries.value[index-1].color = "secondary";
              nextSnapBreakpoint = buttonTop + containerHeight;
            }
          }
        } );
      }
    }

    function calculateSnapPoints() {
      window.setTimeout(calculateSnapPointsInternal, 200);
    }

    onMounted(calculateSnapPoints);
    window.addEventListener("resize", calculateSnapPoints);

    const recommendations = [
      {
        product_name: "Menü 42",
        product_addon: ["Rumpsteak | medium", "Neue Kartoffeln"],
        wine_names: ["Burgunder", "Bordeaux", "Shiraz"]
      },
      {
        product_name: "Meeresfrüchte Grill",
        product_addon: [""],
        wine_names: ["Valpolicella", "Sauvignon Blanc ", "Riesling", "Chardonnay"]
      },
      {
        product_name: "Grünkohl mit Bregenwurst und Salzkartoffeln",
        product_addon: ["mit Tofu statt Wurst"],
        wine_names: ["Spätburgunder"]
      },
    ];

    const displayRecDialog = ref(false);
    const toggleRecDialog = () => {
      displayRecDialog.value = !displayRecDialog.value;
    };
    const displayRecAddon = ref(false);
    const toggleRecAddon = () => {
      displayRecAddon.value = !displayRecAddon.value;
    };

    return {topNavigations, currentTopNavigation, currentNavigation, entries, selectTopNavigation, selectSubNavigation,
      recommendations, displayRecDialog, toggleRecDialog, toggleRecAddon, displayRecAddon, activeNavigationIndex,
        getIcon, getButtonText}
  },
}
</script>