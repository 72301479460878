// initial state
const state = {}

// getters
const getters = {
    getSpecificLayout: (state) => (schemaName) => {
        let res = []
        try {
            res = state[schemaName]
        } catch(err) {
            res = []
        }
        return res
    },
    /**
     * Get the keyboard layout for the given mode (default, shift, etc.)
     */
    getKeyboardLayout: (state) => (keyboardLayout, keyboardMode) => {
        if (state[keyboardLayout]){
            try {
                return state[keyboardLayout][keyboardMode]
            } catch(err) {
                return state[keyboardLayout]["default"]
            }
        }
        return []
    },
    /**
     * Get the mode of the given keyboard
     */
    getKeyboardLayoutMode: (state) => (keyboardId) => {
        try {
            return state[keyboardId].layoutMode
        } catch(err) {
            return "default"
        }
    }
}

// actions
const actions = {
}

// mutations
const mutations = {
    set_keyboard (state, {schemaName, keyboard}) {
        state[schemaName].keyboard = keyboard
    },
    set_keyboardvalue (state, {schemaName, keyboard_display}) {
        state[schemaName].keyboard_display = keyboard_display
    },
    /**
     * Set a new layout
     * @param state
     * @param {JSON} layout The layout to set. Every key will be accessible separately
     */
    setLayout(state, layout){
        for (let schemaName in layout){
            state[schemaName] = layout[schemaName]
        }
    },
    /**
     * Set the mode for the given keyboard
     */
    setKeyboardLayoutMode(state, {keyboardId, layoutMode}){
        if (!state[keyboardId]){
            state[keyboardId] = {layoutMode: layoutMode}
        } else {
            state[keyboardId].layoutMode = layoutMode
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}