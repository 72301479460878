<template>
  <div class="mke-dsh-widget simple" v-bind:class="[widget_value.loading ? 'loading' : '']">
    <div class="grid-stack-item">
      <mkePreloader v-show="widget_value.loading"/>
    </div>

    <Card class="grid-stack-item-content">

      <template #header>
        <div class="p-d-flex p-jc-between relative p-ai-center gap-4">
<!--          <i class="mdi mdi-alert-circle-outline font-size-xl mx-fg-danger-high" v-tooltip.bottom="'Dies ist ein Fehler!<ul><li>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua,8,1</li><li>bla</li></ul>'"/>-->
<!--          <i class="mdi mdi-information-outline font-size-xl mx-fg-warning-high" v-tooltip.bottom="'Dies ist eine Information!'"/>-->
          <mke-context-menu v-bind:widget_id="id"/>
        </div>
      </template>

      <template #content>

        <div v-if="widget_value.error == false" class="p-d-flex">
          <div class="kpi-icon mke-mr-5">
            <i class="mdi display-tiny mx-fg-primary" :class="icon" />
          </div>
          <div v-if="widget_value.value.compare_value === undefined" class="kpi-text p-d-flex p-flex-column">
            <span class="kpi-title">{{ title }}</span>
            <div class="kpi-count"  :data-kpi-count="widget_value.value.value">
              <span v-if="format === 'number'" class="display-tiny font-weight-bold">
                {{ $filters.toNumber(widget_value.value.value) }} {{ unit }}
              </span>
              <span v-else class="display-tiny font-weight-bold">
                {{ $filters.toNumberInt(widget_value.value.value) }} {{ unit }}
              </span>
              <span class="body-18-regular mx-fg-text-medium"></span>
            </div>
          </div>
          <div v-else class="kpi-text p-d-flex p-flex-column">
            <span class="kpi-title">{{ title }}</span>
            <div class="kpi-count" :data-kpi-count="widget_value.value.value">
              <span v-if="format === 'number'" :class="widget_value.value.css_mainvalue">
                {{ $filters.toNumber(widget_value.value.value) }} {{ unit }}
              </span>
              <span v-else :class="widget_value.value.css_mainvalue">
                {{ $filters.toNumberInt(widget_value.value.value) }} {{ unit }}
              </span>
              <span class="body-18-regular mx-fg-text-medium"></span>
            </div>
            <div class="kpi-compare" :data-kpi-compare="widget_value.value.compare_value">
              <div>
                <!-- <span class="mx-fg-text-high mke-mr-2">Gestern</span> -->
                <span v-if="format === 'number'" class="mke-mr-2">
                  {{ $filters.toNumber(widget_value.value.compare_value) }} {{ unit }}
                </span>
                <span v-else class="mke-mr-2">
                  {{ $filters.toNumberInt(widget_value.value.compare_value) }} {{ unit }}
                </span>
                <span :class="widget_value.value.css_percent">
                    <i :class="widget_value.value.css_arrow"></i>
                    {{widget_value.value.diff}} %
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
//import {computed, ref, watch} from "vue";
import { computed } from "vue";
import { useStore } from "vuex";
import mkeContextMenu from "./components/mkeContextMenu";

export default {
  name: "WidgetSimple",
  props: ["id", "title", "unit", "icon", "format"],
  setup(props) {
    const store = useStore();

    const widgetIsLoading = computed(() =>
        store.getters["dashboard/get_widget_loading_state"]()
    );
    const widget_value = computed(() =>
      store.getters["dashboard/get_widget_info"](props.id)
    );

    return { widget_value, widgetIsLoading, mkeContextMenu };
  },
};
</script>
<style>
.mke-dsh-widget.simple .p-card-header {
    position: absolute;
    right: 0;
    z-index: 1;
}

.p-tooltip {
  max-width: 50rem !important;
}

</style>
