<template>
  <div class="p-grid p-ai-center mke-px-0">
    <div v-if="label" class="p-col-4 mke-p-0">{{ label }}</div>
    <div class="p-col-8 mke-p-0" :data-value="modelValue">

      <Dropdown :id="id" class="block" :modelValue="modelValue"
                :options="options" :optionLabel="option_label" :optionValue="option_key" :filter="true"
                :placeholder="placeholder" :showClear="true" @change="itemSelected($event)"/>
    </div>
  </div>
</template>
<script>

import {inject, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";

export default {
  name: "mkeFormBuilderSelectItem",
  props: ["id", "label", "option_key", "option_label", "modelValue", "endpoint", "placeholder", "readonly", "selectionTitle", "selectionType", "mapping"],

  emits: ["update:modelValue", "modelValueUpdate", "setModelValue"],

  // eslint-disable-next-line no-unused-vars
  setup(props, context) {
    const {t} = useI18n({useScope: 'global'});

    const store = useStore();
    const modelStore = inject('modelStore');
    const modelID = inject('modelID');
    const options = ref([]);
    const setModelValue = inject('setModelValue');


    store.dispatch("admin/retrieveData", {
      endpoint: props.endpoint,
      sort: "name",
      offset: 0,
      limit: 100,
      filter: {q: ''},
      successHandler: (data) => {
        options.value = data;
      }
    });

    function itemSelected(event) {
      const selectedKey = event.value;
      const item = options.value.find((i) => i[props.option_key] === selectedKey)

      for (let [targetField, sourceField] of Object.entries(props.mapping)) {
        setModelValue({field: targetField, value: item[sourceField]});
      }
    }

    return {
      t,
      modelStore, modelID, itemSelected, options
    }
  }
}
</script>

<style scoped></style>
