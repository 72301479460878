<template>
  <div :id="id" class="w-100">
    <div class="mke-mb-1">
      <mke-input-text :store="numpadInputStore" class="w-100 font-size-l"></mke-input-text>
    </div>
    <div class="pos-numpad mx-module-grid rows_4 cols_4" >
      <mke-button
          v-for="button in mode === 'numpad' ? numButtons : moneyButtons"
          :key="button.id || button.value"
          :id="id + '-button-' + (button.id || button.value)"
          :icon="button.icon"
          iconPos="center"
          :label="button.label || button.value "
          :color="button.color || 'surface-medium'"
          class="w-100 font-size-xxl"
          :functionCall="button.functionCall ?? ''"
          :functionCallStore="numpadInputStore"
          @click="button.action ? button.action() : addText(button.value)"
      />
    </div>
  </div>

</template>

<script>

import {ref, computed} from "vue";
import {useStore} from "vuex";

export default {
  name: "PosNumpadCash",
  props: ['id', 'value', 'numpadInputStore'],
  emits: ['numpad:change', 'numpad:close'],
  setup(props, ctx){
    const amount = ref(0);
    const mode=ref("numpad");
    const selectAllColor=ref("surface-medium");
    const store = useStore();
    const numpadInputStore = computed(() => props.numpadInputStore ?? "numpadCashInput")

    store.commit("pos/setKeyboardDisplay", {
      keyboardDisplayId: numpadInputStore.value,
      value: ""
    })

    if( props.value ) {
      const value = mode.value === "numpad" ? store.state.pos.keyboardDisplay[numpadInputStore.value] : props.value.value;

      addText(value ?? "");
    }

    function addText(value) {
      if( value ) {
        if( mode.value === "numpad" ) {
          store.commit("pos/setKeyboardDisplay", {
            keyboardDisplayId: numpadInputStore.value,
            value: store.state.pos.keyboardDisplay[numpadInputStore.value] + value
          })
        } else {
          amount.value += value;
        }
        changed();
      }
    }

    function deleteText() {
      store.commit("pos/setKeyboardDisplay", {
        keyboardDisplayId: numpadInputStore.value,
        value: store.state.pos.keyboardDisplay[numpadInputStore.value].slice(0, -1)
      })
      changed();
    }

    function clearText() {
      if( mode.value === "numpad" ) {
        store.commit("pos/setKeyboardDisplay", {
          keyboardDisplayId: numpadInputStore.value,
          value: ""
        })
      } else {
        amount.value = 0;
      }
      changed();
    }

    function changed() {
      if( selectAllColor.value === "warning" ) {
        selectAllColor.value = "surface-medium"
      }
      if( mode.value === "numpad" ) {
        if( store.state.pos.keyboardDisplay[numpadInputStore.value] === "" ) {
          amount.value = 0;
        } else {
          amount.value = parseFloat(store.state.pos.keyboardDisplay[numpadInputStore.value]);
        }
      } else {
        store.commit("pos/setKeyboardDisplay", {
          keyboardDisplayId: numpadInputStore.value,
          value: String(amount.value)
        })
      }
      emitState();
    }

    function selectAll() {
      clearText();
      selectAllColor.value = "warning"
      emitState()
    }

    function emitState() {
      const state = {
        value: amount.value,
        text: store.state.pos.keyboardDisplay[numpadInputStore.value],
        all: selectAllColor.value === "warning"
      }

      ctx.emit('numpad:change', state);
    }

    function consumed() {
      if( mode.value === "numpad" ) {
        store.commit("pos/setKeyboardDisplay", {
          keyboardDisplayId: numpadInputStore.value,
          value: ""
        })
      } else {
        amount.value = 0;
      }
      selectAllColor.value = "surface-medium";
      changed();
    }

    const numButtons = computed( () => [
      {"value": "7", },
      {"value": "8"},
      {"value": "9"},
      {"id": "backspace", "icon" : "mdi mdi-backspace-outline", "color": "danger", "action": () => deleteText() },
      {"value": "4"},
      {"value": "5"},
      {"value": "6"},
      {"id": "given", "icon": "mdi mdi-hand-coin", "functionCall": "fastCashGiven"},
      {"value": "1"},
      {"value": "2"},
      {"value": "3"},
      //{"id": "select-all", "label": "Alle", "color": selectAllColor.value, "action": () => selectAll()},
      {"id": "tip", "label": "TIP", "functionCall": "fastTip"},
      {"id":"point", "value": "."},
      {"value": "0"},
      {"id": "toggle-money", "color": "default", "icon" : "mdi mdi-cash-multiple", "action": () => toggleView()},
      {"id": "target", "icon": "mdi mdi-arrow-right", "functionCall": "fastCashTarget"}
    ] );

    const moneyButtons = [
      {"label": "1 Cent", "value": 0.01},
      {"label": "2 Cent", "value": 0.02},
      {"label": "5 Cent", "value": 0.05},
      {"label": "10 Cent", "value": 0.1},
      {"label": "20 Cent", "value": 0.2},
      {"label": "50 Cent", "value": 0.5},
      {"label": "1 €", "value": 1},
      {"id": "clear", "label":"C", "color": "danger", "action": () => clearText() },
      {"label": "2 €", "value": 2},
      {"label": "5 €", "value": 5},
      {"label": "10 €", "value": 10},
      {"label": "", "action": () => undefined},
      {"label": "20 €", "value": 20},
      {"label": "50 €", "value": 50},
      {"label": "100 €", "value": 100},
      {"id": "toggle-money", "color": "default", "icon" : "mdi mdi-numeric", "action": () => toggleView()},
    ];

    function toggleView() {
      if( mode.value === "numpad" ) {
        mode.value = "money";
        store.commit("pos/setKeyboardDisplay", {
          keyboardDisplayId: numpadInputStore.value,
          value: "0"
        })
      } else {
        mode.value = "numpad";
        store.commit("pos/setKeyboardDisplay", {
          keyboardDisplayId: numpadInputStore.value,
          value: ""
        })
      }

      amount.value = 0;
    }

    return {numButtons, moneyButtons, addText, toggleView, selectAll, consumed, mode}
  },
}
</script>