import i18n from "../../i18n";
import axios from "axios";

export default function createCrud(module, resturl, model) {
    const state = module.state
    state['viewMode'] = 'overview'
    state['model'] = model
    state['activeID'] = null
    state['options'] = {}
    const getters = {
    }
    const actions = {
        // eslint-disable-next-line no-unused-vars
        getSpecificData({commit}, {id, model, onSuccessCallback}) {
            const index = state[model].items.findIndex((el) => el.id == id)
            if (onSuccessCallback) {
                onSuccessCallback(state[model].items[index])
            }
        },
        getData({commit, dispatch}, {model, offset, limit, sort=null, order="asc", filter=null} ) {
            const retrieveParam = {
                model: model,
                offset: offset,
                limit: limit,
                sort: sort,
                order: order,
                filter: filter,
                successHandler: (data) => {
                    data.sort = data.sort || sort;
                    data.order = data.order || order;
                    data.filter = data.filter || filter;
                    commit("setData", {model: model, data: data})
                }
            }
            dispatch("retrieveData", retrieveParam);
        },
        /**
         * Get new data from the service
         */
        retrieveData({commit}, {model, offset, limit, sort=null, order="asc", filter=null, successHandler = null}) {
            let sortOrder = "asc"
            if (order == -1){
                sortOrder = "desc"
            }

            let callUrl = resturl + "/" + model + "/page?offset=" + offset + "&limit=" + limit  + "&order=" + sortOrder;
            if( filter ) {
                let filterEntries = [];
                for(let f in filter ) {
                    if( filter[f].value) {
                        filterEntries.push("filter_" + f + "=" + encodeURI(filter[f].value));
                    } else if( typeof filter[f] === 'string' || filter[f] instanceof String ) {
                        filterEntries.push("filter_" + f + "=" + encodeURI(filter[f]));
                    }
                }
                callUrl += "&" + filterEntries.join("&");
            }

            if (sort != null){
                callUrl += "&sort=" + sort
            }
            axios.get(callUrl)
                .then(function (result) {
                    if( successHandler ) {
                        successHandler(result.data);
                    }

                })
                .catch(function (error) {
                    commit("general/addToast", {
                        detail: error.message,
                        life: 3000,
                        severity: "error",
                        summary: i18n.global.t('message.home.error')
                    }, {root: true})
                });
        },
        getAllData({commit}, {field, model, onSuccessCallback=null}) {
            let callUrl = resturl + "/" + model
            axios.get(callUrl)
                .then(function (result) {
                    commit("setData", {model: model, data: result.data})
                    commit("setOptions", {field: field, data: result.data})
                    if (onSuccessCallback != null) {
                        onSuccessCallback(result.data)
                    }
                })
                .catch(function (error) {
                    commit("general/addToast", {
                        detail: error.message,
                        life: 3000,
                        severity: "error",
                        summary: i18n.global.t('message.home.error')
                    }, {root: true})
                });
        },
        deleteData({commit}, {model, id}) {
            axios.delete(resturl + "/" + model + "/" + id)
                .then(function () {
                    commit("deleteData", {model: model, id: id})
                    commit("general/addToast", {
                        detail: i18n.global.t('message.home.DeleteSuccess'),
                        life: 3000,
                        severity: "success",
                        summary: i18n.global.t('message.home.dataDeleted'),
                    }, {root: true})
                })
                .catch(function (error) {
                    let detail = error.message
                    if (error.response.data.detail !== undefined){
                        if (Array.isArray(error.response.data.detail)){
                            detail = i18n.global.t('message.crud.'+  error.response.data.detail[0].loc[1])

                        }else{
                            detail = error.response.data.detail
                        }

                    }
                    commit("general/addToast", {
                        detail: detail,
                        life: 3000,
                        severity: "error",
                        summary: i18n.global.t('message.home.error')
                    }, {root: true})
                });
        },
        saveData({commit}, {data, model, successcallback, errorcallback}) {
            if (data.id === undefined){
                axios.post(resturl + "/" + model + "/" , data)
                    .then(function (result) {
                        commit("addData", {model: model, data: result.data})
                        commit("general/addToast", {
                            detail: i18n.global.t('message.home.createSuccess'),
                            life: 3000,
                            severity: "success",
                            summary: i18n.global.t('message.home.dataCreated')
                        }, {root: true})
                        if (successcallback) {
                            successcallback(result.data)
                        }
                    })
                    .catch(function (error) {
                        let detail = error.message
                        if (error.response.data.detail !== undefined){
                            if (Array.isArray(error.response.data.detail)){
                                detail = i18n.global.t('message.crud.'+  error.response.data.detail[0].loc[1])

                            }else{
                                detail = error.response.data.detail
                            }

                        }
                        commit("general/addToast", {
                            detail: detail,
                            life: 3000,
                            severity: "error",
                            summary: i18n.global.t('message.home.error')
                        }, {root: true})
                        if (errorcallback) {
                            errorcallback()
                        }
                    });
            } else {
                axios.put(resturl + "/" + model + "/" + data.id, data)
                    .then(function (result) {
                        commit("addData", {model: model, data: result.data})
                        commit("general/addToast", {
                            detail: i18n.global.t('message.home.createSuccess'),
                            life: 3000,
                            severity: "success",
                            summary: i18n.global.t('message.home.dataCreated')
                        }, {root: true})
                        if (successcallback) {
                            successcallback()
                        }
                    })
                    .catch(function (error) {
                        let detail = error.message
                        if (error.response.data.detail !== undefined){
                            if (Array.isArray(error.response.data.detail)){
                                detail = i18n.global.t('message.crud.'+  error.response.data.detail[0].loc[1])

                            }else{
                                detail = error.response.data.detail
                            }

                        }
                        commit("general/addToast", {
                            detail: detail,
                            life: 3000,
                            severity: "error",
                            summary: i18n.global.t('message.home.error')
                        }, {root: true})
                    });
            }
        }
    }
    const mutations = {
        setViewMode(state, viewMode) {
            state.viewMode = viewMode
        },
        setOptions(state, {field, data}) {
            state.options[field] = data
        },
        setModel(state, model) {
            state.model = model
        },
        setActiveID(state, activeID) {
            state.activeID = activeID
        },
        addData(state, {model, data}) {
            const index = state[model].items.findIndex((el) => el.id == data.id)
            if (index >= 0){
                state[model].items[index] = data
            } else {
                state[model].items.push(data)
            }

        },
        deleteData(state, {model, id}) {
            state[model].items = state[model].items.filter(x => x.id != id)
        },
        setData(state, {model, data}) {
            if ('items' in data){
                state[model]= data
            }else{
                state[model].items= data
            }

        }
    }
    for (const [getters_key, getters_function] of Object.entries(getters)) {
        if (module.getters[getters_key] === undefined) {
            module.getters[getters_key] = getters_function
        }
    }
    for (const [actions_key, actions_function] of Object.entries(actions)) {
        if (module.actions[actions_key] === undefined) {
            module.actions[actions_key] = actions_function
        }
    }
    for (const [mutation_key, mutation_function] of Object.entries(mutations)) {
        if (module.mutations[mutation_key] === undefined) {
            module.mutations[mutation_key] = mutation_function
        }
    }
}