<template>
  <div class="mx-sidebar-process-switcher">
    <PosProcessSelectDialog :id="'process-select-for-process-switcher'" title="Vorgang öffnen" @process:selected="changeActiveProcess" />
    <mkeButton
        icon="mdi mdi-format-list-numbered"
        class="font-size-xl"
        @click="openProcessList()"
    />
    <Divider />
    <div class="mx-sidebar-process-list">
      <transition-group name="fade-in-from-left">
      <mkeButton v-for="(process, index) in processList" :key="index"
                 :color="['in_pay', 'in_split'].includes(process.state) ? 'danger' : ''"
                 size="lg"
                 :label="process.name"
                 :functionCall="'switchProcess'"
                 :functionCallArgs="process.id"
                 :tooltip="process.id"
                 v-bind:class="[activeProcess && process.id === activeProcess.id ? 'active' : '', 'mke-button--select-process']"
                 :data-process-id="process.id"/>
      </transition-group>
    </div>
    <Divider />
    <mkeButton class="mx-button--create-new-process" color="" :functionCall="'createNewProcess'" function-call-store="pos-main-keyboard-display" icon="mdi mdi-plus"/>
  </div>
</template>
<script>
import { useStore } from 'vuex'
import {computed} from 'vue'


export default {
  name: "PosProcessSwitcher",
  props: ["color"],
  setup(){
    const store = useStore()
    const processList = computed(() => store.state.pos.processList)
    const activeProcess = computed(() => store.state.pos.activeProcess)

    /**
     * Change the active process to the selected. If a table without an active process is selected: Create a new process
     * @param selectedProcess: The selected process
     */
    function changeActiveProcess(selectedProcess) {
      if (selectedProcess.id) {
        store.dispatch("pos/openProcess", selectedProcess.id)
      } else {
          store.dispatch("pos/createProcess", {name: selectedProcess.name})
      }
      store.commit("pos/setSelectProcessVisibleId", undefined);
    }

    /**
     * Open the process list
     */
    function openProcessList() {
        store.commit('pos/setSelectProcessVisibleId', 'process-select-for-process-switcher');
    }

    return {
      activeProcess,
      changeActiveProcess,
      openProcessList,
      processList
    }
  },
}
</script>
<style lang="scss">

</style>