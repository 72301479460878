<template>
  <div id="app_switcher" class="mx-app-switcher-wrapper">
    <div class="mx-app-switcher-inner">
      <svg
          width="8px"
          height="8px"
          viewBox="0 0 8 8"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-418.000000, 0.000000)" fill="#102341">
            <g transform="translate(418.000000, 0.000000)">
              <path
                  d="M0,0 C4.41832452,0 8,3.58172001 8,8 L8,0 L0,0 Z"
              ></path>
            </g>
          </g>
        </g>
      </svg>
      <router-link  to="/home">
        <div class="mx-app-switcher">
            <img style="position: relative; z-index: 9999;" v-shared-element:[app.icon] v-if="app?.app_type === 'app'" :src="require('@/assets/icons/apps/' + app?.icon + '_icon.svg')" width="35" height="35">
            <i v-shared-element:[app?.icon] v-else class="mx-icon" :class="'mx-icon-' + app?.icon"/>
          <span v-shared-element:[app?.name] class="mx-app-name uppercase">{{app?.name}}</span>
        </div>
      </router-link>
      <svg
          width="8px"
          height="8px"
          viewBox="0 0 8 8"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-527.000000, 0.000000)" fill="#102341">
            <g transform="translate(418.000000, 0.000000)">
              <path
                  d="M109,0 C113.418325,0 117,3.58172001 117,8 L117,0 L109,0 Z"
                  transform="translate(113.000000, 4.000000) scale(-1, 1) translate(-113.000000, -4.000000) "
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div class="mx-app-switcher-background"/>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";

export default {
  name: "MxAppIcon",
  props: ["app"],
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    return {t};
  }
}
</script>

<style scoped>

</style>