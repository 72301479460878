import { createStore, createLogger } from 'vuex'
import layout from './modules/layout'
import articles from './modules/articles'
import general from './modules/general'
import pos from "./modules/pos";
import pos_process from "./modules/process";
import waiters from "./modules/waiters";
import dashboard from "./modules/dashboard";
import admin from "./modules/admin";
import query_builder from "./modules/querybuilder";

// Import mixins
import createCrud from "./plugins/crud"
import createResetState from "./mixins/resetStateMixin";

createCrud(dashboard, config.ANALYTICSURL, 'dashboard_names')
createResetState(admin)
createResetState(general)
createResetState(pos)

import createDashboardWebsocketPlugin from "./plugins/dashboardio";
import createAuthenticationWebSocketPlugin from "./plugins/authenticationio"
import createPosWebSocketPlugin from "./plugins/posio";
import createPersistedState from "vuex-persistedstate"
import createDashboardSecurityPlugin from "./plugins/security/dashboardSecurity"

const debug = process.env.NODE_ENV !== 'production'
import { io } from 'socket.io-client'
import posIcon from "@/assets/lottie/app_icon_pos.json";
const dashboard_websocket_url = config.ANALYTICSSOCKETURL
const dashboard_websocket_path = config.ANALYTICSSOCKETPATH
const dashboardsocket = io(dashboard_websocket_url, {path: dashboard_websocket_path, autoConnect: false})
const websocketpluginDashboard = createDashboardWebsocketPlugin(dashboardsocket)

// Authentication Service
const auth_websocket_url = config.AuthenticationSOCKETURL
const auth_websocket_path = config.AuthenticationSOCKETPATH
const authenticationSocket = io(auth_websocket_url, {path: auth_websocket_path})
const websocketPluginAuthentication = createAuthenticationWebSocketPlugin(authenticationSocket)

// PosService Service
const posWebsocketUrl = config.PosServiceSOCKETURL
const posWebsocketPath = config.PosServiceSOCKETPATH
const posSocket = io(posWebsocketUrl, {path: posWebsocketPath})
const websocketPluginPos = createPosWebSocketPlugin(posSocket)

//Security Plugins
const dashboardSecurity = createDashboardSecurityPlugin()

const apps = [
    {name: "Home", component: "Home", path: "/home", app_type: "home", icon: ""},
    {name: "POS", component: "Pos", path: "/pos", app_type: "app", icon: "pos", animatedIcon:posIcon, privileges: ["pos_user"]},
    {name: "Dashboard", component: "Dashboard", path: "/dashboard", app_type: "app", icon: "dashboard", privileges: ["dashboard_admin", "dashboard_manager", "dashboard_user"]},

    {name: "Stammdaten", component: "MasterData", path: "/masterdata", app_type: "settings", icon: "files", privileges: ["master_data_manager"]},
    {name: "Benutzer", link:"/admin/users", app_type: "settings", icon: "files", privileges: ["admin_manager"]},
    {name: "Berechtigungen", link:"/admin/privileges", app_type: "settings", icon: "files", privileges: ["admin_manager"]},
    {name: "Geräte", link:"/admin/devices", app_type: "settings", icon: "files", privileges: ["admin_manager"]},
    {name: "Authentifizierungsmedien", link:"/admin/authentication_media", app_type: "settings", icon: "files", privileges: ["admin_manager"]},
    {name: "Sprachen", link:"/admin/languages", app_type: "settings", icon: "files", privileges: ["admin_manager"]},
    {name: "Terminal Einstellungen", link:"/admin/terminal_settings/detail", app_type: "settings", icon: "files", privileges: ["admin_manager"]},
    {name: "Microservices", link:"/admin/microservices", app_type: "settings", icon: "files", privileges: ["admin_manager"]},

    {name: "Bon", component: "Bon", path: "/bon", app_type: "disabled", icon: "orderdisplay", privileges: ["pos_user"]},
    {name: "Monitor", component: "Monitor", path: "/monitor", app_type: "disabled", icon: "orderdisplay", privileges: ["pos_user"]}
]

apps.forEach((app) => {
    app.id = 'mx' + app.name;
    app.icon_ref = app.id + "_icon";
    app.name_ref = app.id + "_name";
    app.enabled = true;
    app.props = true;
});

let plugins = [];

if( debug ) {
    plugins.push(createLogger());
}

plugins.push( createPersistedState({
    paths: ["general.activeUser", "general.jwtToken", "general.terminalUUID", "pos.activeTerminal", "pos.layoutLeftRightHandMode", "pos.layoutFunctionalAreaMode"]} ) );
plugins.push(websocketpluginDashboard);
plugins.push(websocketPluginAuthentication);
plugins.push(websocketPluginPos);
plugins.push(dashboardSecurity);

const store = createStore({
    modules: {
        articles, general, layout, pos, pos_process, waiters, dashboard, admin, query_builder
    },
    strict: debug,
    plugins: plugins
});

store.commit("general/setApps", apps);

store.subscribe((mutation, state) => {
    if (mutation.type === "general/setActiveUser") {
        const appsWithRenewedPermission = [];

        const appsForRenewPermission = state.general.apps.length > 0 ? state.general.apps : apps;

        appsForRenewPermission.forEach( (appForRenewPermission) => {
            let appWithRenewedPermission = {...appForRenewPermission};
            appWithRenewedPermission.enabled = store.getters["general/isAppEnabled"](appForRenewPermission);

            appsWithRenewedPermission.push(appWithRenewedPermission);
        });

        /**
         * Temporary implementation to add the modules menu item to the home screen of the administrator.
         * Otherwise, our pilot customers could activate the pos or master data.
         * TODO: Remove after the pilot customer phase
         */
        if (state.general.activeUser?.user_name == 'Administrator'){
            let moduleMenu = {id: "mxModule", name: "Module", link:"/admin/modules", app_type: "settings", icon: "files", privileges: ["admin_manager"], enabled: true}
            if (appsWithRenewedPermission.filter(x => x.id == moduleMenu.id).length == 0){
                appsWithRenewedPermission.push(moduleMenu)
            }
        }
        store.commit("general/setApps", appsWithRenewedPermission);
    }
});

console.log("STORE ready");

export default store;
export function useStore () {}
export {dashboardsocket, posSocket}