<template>
  <div class="grid-wrapper product-filter-content">
    <PosProductConstraintsDialog v-if="displayConstraintsDialog" v-model:visible="displayConstraintsDialog" v-bind="productConstraints" @closeProductConstraintsDialog="onCloseProductConstraintsDialog"/>
    <swiper
          :threshold="3"
          loopFillGroupWithBlank: false
          :direction="'vertical'"
          :pagination='{
            "clickable": true
          }'



          
          >
      <swiper-slide class="mke-slide" v-for="(slide, index) in slides"
                    :key="index"
                    :virtualIndex="index">
        <div class="grid-wrapper cols_7 rows_7 product-filter-content">
          <mke-button v-for="(button, index) in slide.products"
                  :key="index"
                  :label="button.name"
                  :color="color"
                  :functionCall="'orderPlu'"
                  :functionCallArgs="[button.plu, keyboardDisplayValue || quantity]"/>
        </div>
      </swiper-slide>

    </swiper>
  </div>
</template>

<script>
import {computed, inject, ref, watch} from "vue";
import {useStore} from "vuex";

export default {
  name: "PosProductSelection",
  props: ["color", 'keyboardDisplayId', 'productCategoryFilterId'],
  setup(props){
    const articles = computed(() => store.state.articles)
    const activeProductCategory = computed(() => store.state.pos.activeProductCategory[props.productCategoryFilterId])
    const displayConstraintsDialog = ref(false);
    const keyboardDisplayValue = computed(() => store.state.pos.keyboardDisplay[props.keyboardDisplayId])
    const quantity = computed(() => store.state.pos.quantity)
    const productConstraints = ref(null)
    const signalr = inject("signalr")
    const slides = ref([])
    const store = useStore()

    /**
     *
     * @param activeProductCategory
     */
    function filter_articles(activeProductCategory){
      slides.value = []
      let products = {}
      if (activeProductCategory == null){
        products.value = [...store.state.pos.products]
      } else {
        products.value = store.state.pos.products.filter(function (el) {
          return el.category == activeProductCategory;
        });
      }
      while (products.value.length > 0) {
        slides.value.push({"products": products.value.splice(0,49)})
      }
    }

    watch(activeProductCategory, () => {
      filter_articles(activeProductCategory.value);
    });
    watch(articles, () => {
      filter_articles(store.state.pos.activeProductCategory[props.productCategoryFilterId]);
    }, {deep: true});

    /**
     * If the order has a product constraint. This function will be called.
     */
    signalr.on("order_missing", (IncomingEvent) => {
      openConstraintsDialog(JSON.parse(IncomingEvent))
    });

    /**
     * TODO: INSERT COMMENT HERE
     * @param orderMissingData
     * @returns {Promise<void>}
     */
    const openConstraintsDialog = async (orderMissingData) => {
      productConstraints.value = orderMissingData
      displayConstraintsDialog.value = true;
    }

    /**
     * Temporary function to set the constraint result to a specific position!
     * Type 0: Free Product Price
     * Type 1: Free Product Text
     * Type 2: Free Weight
     * Type 3: Free Length
     * Type 4: Free Width
     * Type 5: Choice to make
     * TODO: Remove it after we can set the value at the exact same position every time
     * @param result
     */
    function putConstraintResultInAnswer(result) {
      let constraintType = productConstraints.value.AskItems[productConstraints.value.CurrentCallback].Type
      let forLine = productConstraints.value.AskItems[productConstraints.value.CurrentCallback].ForLine
      if (constraintType == 0){
        productConstraints.value.OrderLines[forLine].Price = result
      } else if (constraintType == 1){
        productConstraints.value.OrderLines[forLine].Article = result
      } else if (constraintType == 2){
        productConstraints.value.OrderLines[forLine].Weight = result
      } else if (constraintType == 3){
        productConstraints.value.OrderLines[forLine].Length = result
      } else if (constraintType == 4){
        productConstraints.value.OrderLines[forLine].Width = result
      } else if (constraintType == 5){
        productConstraints.value.AskItems[productConstraints.value.CurrentCallback].Choice = result
      }

    }
    /**
     * Function get called after the ProductConstraintsDialog got closed
     * @param result
     * @returns {Promise<void>}
     */
    const onCloseProductConstraintsDialog = async (result) => {
      displayConstraintsDialog.value = false;
      putConstraintResultInAnswer(result)
      signalr.send("order_progress", JSON.stringify(productConstraints.value))
    }

    return {
      displayConstraintsDialog,
      keyboardDisplayValue,
      onCloseProductConstraintsDialog,
      productConstraints,
      quantity,
      slides
    }
  }
}
</script>

<style scoped>

</style>