<template>
  <div :data-child-mode="combo.child_mode" >
    <template v-if="combo.child_mode === 'no_child_childs' && level === 1">
      <ul class="p-d-flex p-flex-row p-flex-wrap" style="list-style: none; gap: 2em;" >
        <pos-process-combo-single v-for="entry in combo.entries" :key="entry.key" :combo="entry" :parent="combo" :level="level" @entry-selected="selectEntry"/>
      </ul>
    </template>
    <template v-else-if="combo.child_mode === 'no_child' || combo.child_mode === 'no_child_childs'">
      <pos-process-combo-single :parent="parent" :combo="combo" :level="level" @entry-selected="selectEntry"/>
    </template>
    <template v-else-if="combo.child_mode === 'all_child_childs'">
      <div class="p-d-flex p-flex-column">
        <pos-process-combo-multi v-for="entry in combo.entries" :key="entry.key" :parent="combo" :combo="entry" :level="level" @entry-selected="selectEntry"/>
      </div>
    </template>
    <template v-else>
      <div class="p-d-flex p-flex-column">
        <ul class="p-d-flex p-flex-row p-flex-wrap" style="list-style: none; gap: 2em;" >
          <pos-process-combo-single v-for="entry in combo.entries" :key="entry.key" :combo="entry" :parent="combo" :level="level" @entry-selected="selectEntry"/>
        </ul>
        <pos-process-combo-multi v-for="entry in selectedEntries()" :key="entry.key" :combo="entry" :parent="combo" :level="level+1" @entry-selected="selectEntry"/>
      </div>
    </template>

  </div>
</template>

<script>

export default {
  name: "PosProcessComboComponent",
  props: ["combo", "parent", "level"],
  emits: ["entry-selected"],
  setup(props, {emit}) {
    const isEntrySelected = (entry) => {
      return entry.selected_quantity > 0 && entry.entries.length > 0;
    }
    const selectedEntries = () => {
      return props.combo.entries.filter(isEntrySelected);
    }

    const selectEntry = ({combo, selectedEntry, added} ) => {
      emit('entry-selected', {combo:combo, selectedEntry:selectedEntry, added: added});
    }

    return {isEntrySelected, selectedEntries, selectEntry}
  }
}

</script>

<style scoped>

</style>