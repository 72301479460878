<template>
  <div id="topbar" style="z-index: 2">
    <div class="p-grid" style="z-index: 2">
      <div class="p-col-12 mke-py-0 ">
        <Toolbar class="mke-py-2">
          <template #start>
            <Button icon="mdi mdi-menu mdi-2x" @click="mobileMenuVisible = true" class="p-button-transparent p-button-rounded mke-mr-4 p-d-xl-none" />
            <div class="time body-14-regular mx-fg-text-high">{{clock}}</div>
          </template>


          <template #end>
            <mke-switch-theme-toggle ignoreTheme />
<!--            <mke-button @click="setStatus('error')" label="Set Error"/>-->
<!--            <mke-button @click="setStatus('loading')" label="Set loading"/>-->
<!--            <mke-button @click="setStatus('ok')" label="Set OK"/>-->
<!--            <mke-button @click="setStatus('warning')" label="Set Warning"/>-->
            <dsh_status :label="status.label" :status="status.status" :icon="status.icon" :color="status.color" :description="status.description"/>

            <!-- TODO: Add Notification / User Profile funtionality -->
            <!-- <Button
              class="p-button-transparent p-button-rounded"
              type=""
              icon="mdi mdi-bell-outline mdi-2x"
            /> -->
            <Divider layout="vertical" />
            <div class="user_profile p-d-flex p-ai-center p-ripple hover-primary" v-ripple @click="toggle">
              <Avatar
                  v-if="activeUser && activeUser.user_image"
                  size="large"
                  :image="require('@/assets/images/avatar.jpg')"
                  shape="circle"
              /> <!-- TODO user_image -->
              <Avatar
                  v-else
                  size="large"
                  icon="mdi mdi-account-outline"
                  shape="circle"
              />
              <div class="user_name mke-ml-4">
                <span v-if="activeUser" class="body-14-bold p-d-block">{{activeUser.user_name}}</span>
                <!-- <span v-if="activeUser" class="">ID: {{activeUser.user_id}}</span> -->
              </div>
            </div>
            <Menu ref="menu" :model="items" :popup="true" />
          </template>
        </Toolbar>
        <Dialog v-model:visible="showSettingsDialog" :style="{width: '50vw'}" :dismissableMask="true"
                :draggable="false" :closable="true" :modal=true @after-hide="closeUserSettings($event)">
          <template #header>
            <div>
              <h5 class="p-d-inline-block mke-my-0 mke-mr-2">{{ t("message.home.settings") }}</h5>
            </div>
          </template>
          <mkeModelDetail
              store="admin"
              model="users"
              :activeID="activeUser.user_id"
              :schema="schema"
              @onSaved="closeUserSettings($event)"
              @onGoBack="closeUserSettings($event)"/>
        </Dialog>
      </div>
    </div>
  </div>
</template>


<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n'
import { getTranslatedSchema } from "@/json/admin_demo";

export default {
  name: "WidgetSimple",
  props: ["id", "value", "label", "currency", "icon"],
  setup() {
    const menu = ref();
    const store = useStore();
    const activeUser = computed(() => store.state.general.activeUser)
    const { t } = useI18n({ useScope: 'global' })
    const showSettingsDialog = ref(false)


    const mobileMenuVisible = computed({
      get: () => {return store.state.dashboard.mobile_menu_visible},
      set: (value) => (store.commit("dashboard/set_mobile_menu_visible", value))
    })
    const schema = computed(() => getTranslatedSchema("dsh_user_settings", "detail"))

    const items = ref([
      {
        label: t("message.home.settings"),
        items: [
          {
            label: t("message.home.userSettings"),
            icon: "mdi mdi-dashboard",
            command: () => {
              showSettingsDialog.value = true
            }
          },
        ],
      },
      {
        label: t("message.home.navigate"),
        items: [
          {
            label: t("message.home.logout"),
            icon: "mdi mdi-logout",
            command: () => {
              store.dispatch('general/logout')
            }
          },
        ],
      },
    ]);

    // const items = ref([
    //   {
    //     label: "Options",
    //     items: [
    //       {
    //         label: "Update",
    //         icon: "pi pi-refresh",
    //       },
    //       {
    //         label: "Delete",
    //         icon: "pi pi-times",
    //       },
    //     ],
    //   },
    //   {
    //     label: "Navigate",
    //     items: [
    //       {
    //         label: "Vue Website",
    //         icon: "pi pi-external-link",
    //         url: "https://vuejs.org/",
    //       },
    //       {
    //         label: "Router",
    //         icon: "pi pi-upload",
    //         command: () => {
    //           window.location.hash = "/fileupload";
    //         },
    //       },
    //     ],
    //   },
    // ]);



    const status = computed(() => store.getters["dashboard/get_syncstate"]());

    const clock = ref()
    /**
     * Get the current date and time
     */
    const get_now = async () =>  {
      const today = new Date();
      let lang_code = "en-US"
      if (activeUser.value) {
        lang_code = activeUser.value.user_lang.replace("_", "-")
      }
      clock.value = today.toLocaleDateString(lang_code) + " " + today.toLocaleTimeString(lang_code);
    }

    const toggle = (event) => {
      menu.value.toggle(event);
    };

    function closeUserSettings() {
      showSettingsDialog.value = false
    }

    return { menu, mobileMenuVisible, toggle, get_now, clock, status, items, activeUser, t, closeUserSettings, schema, showSettingsDialog };
  },
    created() {
    setInterval(() => {
      this.get_now();
    }, 1000)
  },
};
</script>
