<template>
  <div :id="id"
       class="pos-process-payment pos-process-payment-cash p-d-flex gap-2 mx-grouped-section"
       :data-total-price="priceToPay"
       :data-given-amount="givenAmount"
       :data-tip-amount="tipAmount"
       :data-target-amount="targetAmount"
       :data-return-amount="returnAmount"
       :data-given-invalid="givenInvalid"
       :data-target-invalid="targetInvalid"
      >
    <div class="p-d-flex p-flex-column w-100 form-display" >
      <div class="p-d-flex p-flex-column gap-2">
        <div class="p-d-flex p-jc-between p-ai-baseline mke-px-0 font-size-s" >
          <div class="">{{ t('message.pos.payment.subtotal') }}</div>
          <div class="text-align-right mke-p-0">
            <transition name="value-update" mode="out-in">
              <div class="bold" :key="priceToPay">
                {{n(priceToPay, 'currency')}}
              </div>
            </transition>
          </div>
        </div>

        <div class="p-d-flex p-jc-between mke-px-0 font-size-s cursor-pointer"
             :class="{invalid: targetInvalid || tipInvalid}"
             @click="synchWithNumpad('tipAmount')"
        >
          <div class="">{{ t('message.pos.payment.tipAmount') }}</div>
          <div class="text-align-right mke-p-0 ">
            <transition name="value-update" mode="out-in">
              <div class="bold" :key="tipAmount">
                {{n(tipAmount, 'currency')}}
              </div>
            </transition>
          </div>
        </div>

        <Divider type="dashed" class="mke-my-1"/>

        <div class="p-d-flex p-jc-between p-ai-baseline mke-px-0 mke-mb-3 font-size-m" >
          <div class="">{{ t('message.pos.payment.price') }}</div>
          <div class="text-align-right mke-p-0 ">
            <transition name="value-update" mode="out-in">
              <div class="bold" :key="totalPriceToPay">
                {{n(totalPriceToPay, 'currency')}}
              </div>
            </transition>
          </div>
        </div>

        <Divider type="dashed" class="mke-my-1"/>

        <div class="p-d-flex p-jc-between mke-px-0 font-size-m cursor-pointer"
             :class="{invalid: givenInvalid}"
             @click="synchWithNumpad('givenAmount')">
          <div class="">{{ t('message.pos.payment.givenAmount') }}</div>
          <div class="text-align-right mke-p-0">
            <transition name="value-update" mode="out-in">
              <div class="bold" :key="givenAmount">
                {{n(givenAmount, 'currency')}}
              </div>
            </transition>
          </div>
        </div>

        <div class="p-d-flex p-jc-between mke-px-0" :class="{invalid: targetInvalid}" v-if="lastTarget && targetAmount > 0">
          <div class="">{{ t('message.pos.payment.' + lastTarget) }}</div>
          <div class="text-align-right mke-p-0">
            <transition name="value-update" mode="out-in">
              <div :key="targetAmount">
                {{n(targetAmount, 'currency')}}
              </div>
            </transition>
          </div>
        </div>

        <div class="p-d-flex p-jc-between p-ai-baseline mke-px-0 font-size-m cursor-pointer"
             :class="{invalid: targetInvalid, valid : !targetInvalid}"
             @click="synchWithNumpad('returnOfTarget')">
          <div class="mke-p-0">{{ t('message.pos.payment.returnAmount') }}</div>
          <div class="text-align-right mke-p-0">
            <transition name="value-update" mode="out-in">
              <div class="bold" :key="returnAmount">
                {{n(returnAmount, 'currency')}}
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import {useCashPaymentStore} from "@/store/pinia/pos/CashPaymentStore";
import {storeToRefs} from "pinia";

export default {
  name: "PosProcessPaymentCash",
  props: ['id', 'numpadState'],
  emits: ['payment:changed', 'numpad:consumed'],
  setup(props, ctx) {
    const {t,n} = useI18n();
    const cashPaymentStore = useCashPaymentStore();

    const {
      priceToPay,
      givenAmount,
      tipAmount,
      targetAmount,
      returnAmount,
      givenInvalid,
      targetInvalid,
      tipInvalid,
      lastTarget,
      totalPriceToPay
    } = storeToRefs(cashPaymentStore);

    // Gleicht die Eingabe im Numpad mit der geünschten Aktion ab
    function synchWithNumpad(amountName) {
      cashPaymentStore.setInput(amountName, props.numpadState.value);

      // numpad zurücksetzen
      ctx.emit('numpad:consumed');
      ctx.emit('payment:changed', cashPaymentStore.paymentInfo);
    }

    return {t, n, totalPriceToPay, priceToPay, givenAmount, tipAmount, targetAmount, returnAmount, givenInvalid, targetInvalid, tipInvalid, lastTarget, synchWithNumpad}
  }
}
</script>