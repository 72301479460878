<template>
  <div :id="id" class="pos-process-functions" >
    <div class="mke-widget grid-inset">
      <div class="grid-wrapper" :class="'rows_' + (widgetRows ? widgetRows : 4) + ' cols_' + (widgetCols ? widgetCols : 1)">
        <mkeButton class="pos-process-functions-show-bon " color="warning" functionCall="showBon" label="Zeige Bon"></mkeButton>
        <mkeButton class="pos-process-functions-show-bill " color="warning" functionCall="showBill" label="Zeige Rechnung"></mkeButton>
        <mkeButton class="pos-process-functions-delete-process " color="danger" functionCall="deleteProcess" label="Löschen"></mkeButton>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "PosProcessFunctions",
  props: ['id', 'widgetRows', 'widgetCols']

}
</script>