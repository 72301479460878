// initial state
import axios from "axios";
import i18n from "@/i18n";
import deepClone from "@/utilities";

const restUrlService = config.ANALYTICSURL

const state = {
    query_builder_value_options: {},
    isLoading: false
}

// getters
const getters = {}

// actions
const actions = {
    set_field({commit, rootState}, {cubeName}) {
        const cubes = rootState.dashboard.cubes
        const thisCube = cubes[cubeName]
        commit('set_default_options', {thisCube, cubeName})

    },
    /**
     * Get new data from the service
     */
    retrieveData({commit}, {model, cube, name, operator, offset, limit, filter = null, successHandler = null}) {
        let callUrl = restUrlService + "/" + model + "/?cube=" + cube + "&name=" + name + "&operator=" + operator + "&offset=" + offset + "&limit=" + limit;
        if (filter) {
            callUrl += "&filter_name=" + filter;
        }
        axios.get(callUrl)
            .then(function (result) {
                if (successHandler) {
                    successHandler(result.data);
                }

            })
            .catch(function (error) {
                commit("general/addToast", {
                    detail: error.message,
                    life: 3000,
                    severity: "error",
                    summary: i18n.global.t('message.home.error')
                }, {root: true})
            });
    },

    get_value_options({commit, dispatch}, {model, cube, name, operator, offset, limit, filter = null}) {
        const retrieveParam = {
            model: model,
            cube: cube,
            name: name,
            operator: operator,
            offset: offset,
            limit: limit,
            filter: filter,
            successHandler: (data) => {
                data.filter = data.filter || filter;
                data.total = data.total || offset
                commit("set_value_options", {cube: cube, name: name, data: data})
            }
        }
        dispatch("retrieveData", retrieveParam);
    },
}

// mutations
const mutations = {
    set_value_options(state, {cube, name, data}) {
        state.query_builder_value_options[cube][name].data = data.items.map((item) => item.name)
        state.query_builder_value_options[cube][name].total = data.total

        const clonedItems = deepClone(state.query_builder_value_options[cube][name].data)
        for (let i = 0; i < data.total; i++) {
            clonedItems[i] = {
                label: state.query_builder_value_options[cube][name].data[i],
                value: state.query_builder_value_options[cube][name].data[i]
            };
        }

        state.query_builder_value_options[cube][name]['options'] = deepClone(clonedItems);
    },

    reset_options(state, {cube, field}) {
        state.query_builder_value_options[cube][field]['options'] = []
    },
    /**
     * define a default empty list for each model in each cube
     * */
    set_default_options(state, {thisCube, cubeName}) {
        state.query_builder_value_options[cubeName] = {}
        const model = thisCube['model']
        for (const [model_key, model_value] of Object.entries(model)) {
            if (model_value['kpi'] === false) {
                state.query_builder_value_options[cubeName][model_key] = {'options': []}
            }
        }
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}