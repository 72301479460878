<template>
  <div>
    <Button
      class="p-button-transparent"
      type="button"
      icon="mdi mdi-dots-vertical"
      @click="toggle"
    />
    <Menu ref="menu" :model="items" :popup="true" />
    <Dialog
      v-model:visible="widgetSettingsDialog"
      :modal="true"
      :breakpoints="{ '960px': '75vw' }"
      :style="{ width: '50vw' }"
    >
      <template #header>
        <h5>{{ t('message.dashboard.settings') }}</h5>
      </template>
      <div class="p-d-flex p-flex-column gap-4">
        <div class="p-grid p-ai-center">
          <div class="p-col-4 mke-p-0">
            <span class="p-d-block">{{ t('message.dashboard.title') }}</span>
            <!-- <span class="p-d-block mx-fg-text-high mke-mt-1 font-size-s">Subline</span>   -->
          </div>
          <div class="p-col-8 mke-p-0">
            <InputText class="block" type="text" v-model="dshName" />
          </div>
        </div>
      </div>

      <template #footer>
        <Divider layout="horizontal" />
        <Button
          :label="t('message.dashboard.exit')"
          icon="mdi mdi-close"
          class="p-mt-2 p-button-transparent"
          @click="closeWidgetSettingsDialog">
        </Button>
        <Button
          :label="t('message.dashboard.saveSettings')"
          icon="mdi mdi-check"
          class="p-mt-2"
          @click="saveDialog">
        </Button>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

export default {
  name: "mkeContextMenu",
  setup() {
    const store = useStore()
    const widget_dialog = ref({});
    const { t } = useI18n({ useScope: 'global' })
    const widgetSettingsDialog = ref(false);
    const menu = ref();
    const openWidgetSettingsDialog = () => {
      widgetSettingsDialog.value = true;
    };

    const closeWidgetSettingsDialog = () => {
      widgetSettingsDialog.value = false;
    };
    const dshName = ref()
    dshName.value = store.state.dashboard.dashboards[store.state.dashboard.active_dashboard].name
    const saveDialog = () => {
      store.commit('dashboard/update_dashboard_name', dshName.value)
      widgetSettingsDialog.value = false;
    };
    const items = ref([
      {
        label: t("message.dashboard.dashboardActions"),
        items: [
          {
            label: t("message.dashboard.settings"),
            icon: "mdi mdi-cog",
            command: () => {
              widgetSettingsDialog.value = true;
            },
          },
          {
            label: t("message.dashboard.delete"),
            disabled: false,
            icon: "mdi mdi-delete",
            command: () => {
              store.dispatch('dashboard/setMainView', null)
              store.dispatch("dashboard/deleteDashboard")
            },
          },
        ],
      },
    ]);
    const toggle = (event) => {
      menu.value.toggle(event);
    };


    return {
      items,
      openWidgetSettingsDialog,
      closeWidgetSettingsDialog,
      menu,
      toggle,
      widgetSettingsDialog,
      widget_dialog,
      dshName,
      saveDialog,
      t
      };
  },
};
</script>
