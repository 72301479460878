// initial state
// import i18n from '@/i18n'
import i18n from '../../i18n/index.js'
import moment from "moment";
const getDefaultState = () => {
    return {
        dashboards: {},
        dashboard_categories: [],
        dashboard_names: [],
        widget_values: {},
        widget_templates: {items: []},
        outlets: [],
        active_dashboard: null,
        calculate_dashboard: true,
        dashboard_visible:false,
        main_view: "dashboards",
        model: "dashboard_names",
        cubes: {},
        dataservices: [],
        mobile_menu_visible: false,
        isLoading: false,
        visibility: true,
        export_data_dialog_visible: false,
        cubes_transformed: [],
        cubes_rules: {},
        active_rights: {},
        sync_state: {},
        sync_info: [],
        create_dashboard: false,
        viewMode: 'overview',
    }
}
const state = getDefaultState()

// import { graphic } from "echarts/core";
// getters
const getters = {

    get_widget_value: (state) => (id) => {
        let res = []
        try {
            res = state.widget_values[id]['value']
        } catch (err) {
            res = []
        }
        return res
    },
    get_widget_info: (state) => (id) => {
        let res = []
        try {
            res = state.widget_values[id]
            if (res === undefined) {
                res = {
                    'id': id,
                    'value': 0,
                    'error': true,
                    'info': "Widget Data not initialized",
                    'loading': true
                }
            }
        } catch (err) {
            res = []
        }
        return res
    },
    get_cuberules: (state) => () =>{
        return state.cubes_rules
    },
    get_syncstate: (state) => () =>{
        return state.sync_state
    },
    get_syncinfo: (state) => () => {
        return state.sync_info
    },
    get_widget_templates: (state) => () =>{
        return state.widget_templates.items
    },
    get_cubenames: (state) => () => {
        return state.cubes_transformed
    },
    get_widget_loading_state: (state) => () => {
        return state.isLoading;
    },
    get_active_dashboard_live_state: (state) => () => {
        if (state.active_dashboard !== undefined && state.active_dashboard !== null) {
            return state.dashboards[state.active_dashboard]['dashboard_settings']['live'];
        } else {
            return false
        }
    },
    get_graph_value: (state) => (id) => {
        let res = []
        try {
            res = state.widget_values[id]['value']
        } catch (err) {
            res = []
        }
        return res
    },
    get_widget: (state) => (id) => {
        let res = []
        try {
            res = state.dashboards[state.active_dashboard].widgets.filter(widget_value => widget_value.id === id)[0]['widget_json'];
        } catch (err) {
            res = []
        }
        return res
    },
    get_date_range: (state) => () => {
        let res = undefined
        try {
            res = state.dashboards[state.active_dashboard].dashboard_settings.date_range;
            if (res.length == 0){
                res = undefined
            }
        } catch (err) {
            res = undefined
        }
        return res
    },
    get_date_range_compare: (state) => () => {
        let res = undefined
        try {
            if (state.dashboards[state.active_dashboard].dashboard_settings.date_range_compare != undefined &&
                state.dashboards[state.active_dashboard].dashboard_settings.date_range_compare.length > 0) {
                res = [new Date(state.dashboards[state.active_dashboard].dashboard_settings.date_range_compare[0]),
                    new Date(state.dashboards[state.active_dashboard].dashboard_settings.date_range_compare[1])]
            }
        } catch (err) {
            res = undefined
        }
        return res
    },
    get_widgets: (state) => () => {
        let res = []
        try {
            res = state.dashboards[state.active_dashboard].widgets;
        } catch (err) {
            res = []
        }
        return res
    },
    get_dashboard_name: (state) => () => {
        return state.dashboards[state.active_dashboard].name
    },
    manual_update: (state) => () => {
        return state.dashboards[state.active_dashboard].manual_update
    },
    edit_dashboard: (state) => () => {
        return state.edit_dashboard
    },
    edit_widget: (state) => () => {
        return state.edit_widget
    },
    get_main_view: (state) => () => {
        return state.main_view
    },
    get_dataservices: (state) => () => {
        return state.dataservices
    },
    get_outlets: (state) => () => {
        return state.outlets
    },
    get_weekdays: (state) => (from_compare) => {
        let res = []
        try {
            if (from_compare) {
                res = state.dashboards[state.active_dashboard].dashboard_settings.weekdays_compare
            } else
                res = state.dashboards[state.active_dashboard].dashboard_settings.weekdays
            if (!res)
                res = []
        } catch (err) {
            return []
        }
        return res
    },
    /**
     * Get calendar weekdays Shortcut
     * @param state
     * @param getters
     */
    get_weekdaysShortcut: (state, getters) => (from_compare) => {
        const shortcut = {
            'Monday': i18n.global.t("message.dashboard.calendar.mon"),
            'Tuesday': i18n.global.t("message.dashboard.calendar.tue"),
            'Wednesday': i18n.global.t("message.dashboard.calendar.wed"),
            'Thursday': i18n.global.t("message.dashboard.calendar.thu"),
            'Friday': i18n.global.t("message.dashboard.calendar.fri"),
            'Saturday': i18n.global.t("message.dashboard.calendar.sat"),
            'Sunday': i18n.global.t("message.dashboard.calendar.sun")
        }
        if (state.dashboards[state.active_dashboard].dashboard_settings.predefined_date.id === "today" ||
            state.dashboards[state.active_dashboard].dashboard_settings.predefined_date.id === "yesterday") {
            return ""
        }
        let weekdays = getters.get_weekdays(from_compare)
        if (weekdays.length)
            return weekdays.map(day => day.key).map(value => shortcut[value]) + ""
        else return ""

    },
}

// actions
const actions = {
    calculate_dashboard({commit}, payload) {
        let spinner = true
        if (payload!= undefined && payload.spinner !=undefined){
            spinner = payload.spinner
        }
        if (spinner){
            if (payload!= undefined && payload.widget_id !=undefined){
                commit('set_widget_loading', payload.widget_id)
            } else {
                for (const widget of state.dashboards[state.active_dashboard].widgets) {
                    commit('set_widget_loading', widget.id)
                }
            }
        }
    },
    // saveWidgetTemplate({commit}, payload) {
    //     commit("addData", {model: 'widget_templates', data: {id: payload['name'], name: payload['name'], code: payload['code'], description: payload['description']}})
    // },
    setMainView({commit}, mainView) {
        commit('set_main_view', mainView)
        commit('set_mobile_menu_visible', false)
    },
    // eslint-disable-next-line no-unused-vars
    createWidget({commit}, widget_config) {
    },
    createDashboard() {
    },
    deleteDashboard() {
    },
    createCategory() {
    },
}

// mutations
const mutations = {
    reset_state(state) {
        Object.assign(state, getDefaultState())
    },
    set_active_dashboard(state, dashboard_id) {
        state.active_dashboard = dashboard_id
    },
    set_widget_loading(state, widget_id) {
        if (state.widget_values[widget_id] != undefined) {
            state.widget_values[widget_id].loading = true
        }
    },
    set_dashboard_rights(state, active_user) {
        state.create_dashboard = false
        state.datasource_config = false
        state.reset_dashboard = false
        state.edit_dashboard = false
        state.edit_widget = false
        state.move_widgets = false
        if(active_user && "privileges" in active_user) {
            for(let privilege of active_user.privileges){
                if(privilege === 'dashboard_admin') {
                    state.create_dashboard = true
                    state.datasource_config = true
                    state.reset_dashboard = true
                    state.edit_dashboard = true
                    state.edit_widget = true
                    state.move_widgets = true
                }
                if(privilege === 'dashboard_manager') {
                    state.create_dashboard = true
                    state.edit_dashboard = true
                    state.edit_widget = true
                    state.move_widgets = true
                }
            }
        }
    },
    set_main_view(state, mainview) {
        state.main_view = mainview
    },
    set_cubes(state, cubes) {
        state.cubes = cubes
        const data = {
            "str": ["string", "", {"isRequired": true}],
            "float": ["number", 1, {"isRequired": true}],
            "int": ["number", 1, {"isRequired": true}, {
                "hour": {"isRequired": true, "min": 0, "max": 23},
                "day": {"isRequired": true, "min": 1, "max": 7},
                "week": {"isRequired": true, "min": 1, "max": 53},
                "month": {"isRequired": true, "min": 1, "max": 12},
                "year": {"isRequired": true, "max": new Date().getFullYear()}
            }],
            "bool": ["boolean", true, {"isRequired": true}],
            "datetime": ["date", '', {"isRequired": true}],
            "date": ["date", moment(new Date(new Date()), "DD/MM/YYYY").format(i18n.global.t("message.dashboard.queryBuilder.dateFormat")), {"isRequired": true}],
        }
        const rules = {};
        const res = [];
        for (const [cube_key, cube_value] of Object.entries(state.cubes)) {
            rules[cube_key] = []
            var cube = {};
            var kpi = [], noKpi = [], calc = [];
            cube['name'] = cube_value['title']
            cube['code'] = cube_key
            const datatypes = cube_value['datatypes']
            for (const[, value] of Object.entries(datatypes)){
                value['calc'].forEach((key) => {
                    calc.push({name: key[0], code: key[1], items:[]})
                })

            }
            const calcUniq = [...new Map(calc.map(v => [JSON.stringify(v), v])).values()]
            const model = cube_value['model']
            for (const [model_key, model_value] of Object.entries(model)) {
                if (model_value['kpi'] === true) {
                    kpi.push(getCalc(datatypes[model_value['data_type']], cube, model_value, model_key))
                    getCalcKpi(datatypes[model_value['data_type']], cube, model_value, model_key, calcUniq)
                } if (model_value['kpi'] === false || cube_key== 'table') {
                    let date_name = model_key.split('_').slice(-1)[0]
                    let rule = {'label': model_value['name'],
                        'field': model_key,
                        'type': data[model_value['data_type']][0],
                        'value': data[model_value['data_type']][1]}
                    if (model_value['data_type'] === "int" && Object.keys(data[model_value['data_type']][3]).includes(date_name)) {
                        rule["validation"]= data[model_value['data_type']][3][date_name]
                        rules[cube_key].push(rule)
                    } else {
                        rule["validation"]= data[model_value['data_type']][2]
                        rules[cube_key].push(rule)
                    }

                    if (model_value['data_type'] !== 'datetime') {
                        noKpi.push(getKpi(model_key, model_value))
                    }
                }
            }
            let calcRes = calcUniq.filter(calc => calc.items.length >0)
            cube['kpi'] = kpi
            cube['nokpi'] = noKpi
            cube['calc'] = calcRes
            cube['default_widget_filters'] = cube_value['default_widget_filters']
            res.push(cube)
            rules[cube_key].sort(compare)
        }
        state.cubes_transformed = res
        state.cubes_rules = rules
    },
    reset_manual_update(state) {
        state.dashboards[state.active_dashboard]['manual_update'] = false
    },
    set_widget_coordinates(state, widget_coordinates) {
        for (const [key, value] of Object.entries(widget_coordinates)) {
            state.dashboards[state.active_dashboard].widgets.filter(widget_value => widget_value.id === key)[0].widget_json.x = value.x
            state.dashboards[state.active_dashboard].widgets.filter(widget_value => widget_value.id === key)[0].widget_json.y = value.y
            state.dashboards[state.active_dashboard].widgets.filter(widget_value => widget_value.id === key)[0].widget_json.w = value.w
            state.dashboards[state.active_dashboard].widgets.filter(widget_value => widget_value.id === key)[0].widget_json.h = value.h
        }
        state.dashboards[state.active_dashboard]['manual_update'] = true
        state.dashboards[state.active_dashboard].widget_coordinates = widget_coordinates
    },
    set_dashboards(state, dashboards) {
        for (const [key, value] of Object.entries(dashboards)) {
            if (value.dashboard_settings.date_range != undefined) {
                dashboards[key].dashboard_settings.date_range = value.dashboard_settings.date_range.map(datestr => new Date(datestr))
            } else {
                dashboards[key].dashboard_settings.date_range = value.dashboard_settings.date_range
            }
        }
        state.dashboards = dashboards
    },

    set_synch_state(state, synch_state) {
        state.sync_state = synch_state
    },
    set_synch_info(state, sync_info){
        state.sync_info = sync_info
    },
    set_dashboard_date(state, {dashboardId, dateRange}) {
        state.dashboards[dashboardId].dashboard_settings.date_range = dateRange
    },
    set_dashboard_date_compare(state, {dashboardId, dateRangeCompare}) {
        state.dashboards[dashboardId].dashboard_settings.date_range_compare = dateRangeCompare
    },
    set_dashboard_compare(state, {dashboardId, compare}) {
        state.dashboards[dashboardId].dashboard_settings.compare = compare
    },
    set_predefined_date(state, {dashboardId, predefined_date}) {
        state.dashboards[dashboardId].dashboard_settings.predefined_date = predefined_date
    },
    set_predefinedWeekdays(state, {dashboardId, weekdays}) {
        state.dashboards[dashboardId].dashboard_settings.weekdays = weekdays
    },
    set_weekdaysCompare(state, {dashboardId, weekdays}) {
        state.dashboards[dashboardId].dashboard_settings.weekdays_compare = weekdays
    },
    set_dashboard_outlets(state, {dashboardId, outlets}) {
        state.dashboards[dashboardId].dashboard_settings.outlets = outlets
    },
    set_dashboard_live(state, {dashboardId, live}) {
        state.dashboards[dashboardId].dashboard_settings.live = live
    },
    set_outlets(state, outlets) {
        state.outlets = outlets
    },
    set_dashboard_visible(state, visible) {
       state.dashboard_visible = visible
    },
    reset_dashboard(state) {
        state.dashboards.reset = true
    },
    setViewMode(state, viewMode) {
        state.viewMode = viewMode
    },
    setModel(state, model) {
        state.model = model
    },
    update_dashboard_name(state, dashboard) {
        state.dashboards[dashboard.dashboard_id].name = dashboard.name
        state.dashboards[dashboard.dashboard_id].category = dashboard.category
        // return state.dashboards[dashboard.dashboard_id].name = dashboard.name
    },
    set_mobile_menu_visible(state, visible) {
        state.mobile_menu_visible = visible
    },
    set_export_data_dialog_visible(state, visible) {
        state.export_data_dialog_visible = visible
    },
    add_dataservice(state, dataservice) {
        state.dataservices.push(dataservice)
    },
    update_dataservice(state, dataservice) {
        let dataservice_obj = state.dataservices.find(ds => ds.id == dataservice.id)
        if (dataservice_obj == undefined){
            state.dataservices.push(dataservice)
        } else {
            Object.assign(dataservice_obj, dataservice)
        }
    },
    delete_dataservice(state, dataservice_id) {
        state.dataservices = state.dataservices.filter(ds => ds.id != dataservice_id)
    },
    // eslint-disable-next-line no-unused-vars
    delete_widget(state, widget_id) {
    },
    set_dataservices(state, dataservices) {
        state.dataservices = dataservices
    },
    set_is_loading(state, loading_state) {
        state.isLoading = loading_state
    },
    set_widget_values(state, widget_values) {
        Object.assign(state.widget_values , widget_values)
    },

    editWidget(state, payload) {
        let real_widget_value = state.dashboards[state.active_dashboard].widgets.filter(widget_value => widget_value.id === payload['widget_id'])[0]['widget_json']
        Object.assign(real_widget_value, payload['widget_dialog'].value)
    },
    switch_datasource_sync(state, {dataservice_id, datasource_id, sync}) {
        if (sync != undefined && datasource_id != undefined && dataservice_id != undefined) {
            state.dataservices.filter(dataservice => dataservice.id === dataservice_id)[0].datasources.filter(datasource => datasource.id === datasource_id)[0].sync = sync
        }
    }
}

function getCalc(datatypes, cube, value, key) {
    let calc = []
    datatypes['calc'].forEach((v) => {
        if (Object.keys(value['unit']).includes(v[1])) {
            calc.push({
                cname: v[0],
                dcode: `${cube['name']}/${value['name']}/${v[0]}`,
                code: `${cube['code']}/${key}/${v[1]}`
            })
        }
    })
    return {
        name: value['name'],
        code: key,
        descr: value['description'],
        calc: calc,
        type: value['data_type'],
        unit: value['unit']
    }
}
function getCalcKpi(datatypes, cube, value,key,calc){
    calc.forEach((thisCalc) => {
        datatypes['calc'].forEach((myCalc) => {
            if (thisCalc['code'] === myCalc[1]&& Object.keys(value['unit']).includes(thisCalc['code'])){
                thisCalc['items'].push({name: `${thisCalc['name']}(${value['name']})`, value:`${cube['code']}/${thisCalc['code']}(${key})`})
            }
        })
    })
}

function getKpi(key, value) {
    return {kname: value['name'], descr: value['description'], code: `${key}`,type:value['data_type']}
}
/*Sort fields QueryBuilder */
function compare( obj1, obj2 ) {
    if ( obj1.label <obj2.label ){
        return -1;
    }
    if ( obj1.label >obj2.label ){
        return 1;
    }
    return 0;
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
