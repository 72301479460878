<template>
  <Dialog class="mke__ChangePasswordDialog" v-model:visible="showDialogRef" :style="{width: '50vw'}" dismissableMask="true"
          :draggable="false" closable=false modal=true @hide="$emit('update:showDialog', false)">
    <template #header>
      <h5 class="mke-my-0">{{ t('message.home.enterNewPassword') }}</h5>
    </template>
    <div class="mke__input-group">
      <label for="newPassword" class="mke__input-label">{{ t('message.home.newPassword') }}</label>
      <Password id="newPassword" class="mke-login-password p-inputtext-lg" :feedback="false" toggleMask v-model="newPassword"/>
    </div>
    <div class="mke__input-group">
      <label for="newPasswordRepeat" class="mke__input-label">{{ t('message.home.repeatPassword') }}</label>
      <Password id="newPasswordRepeat" class="mke-login-password p-inputtext-lg" :feedback="false" toggleMask v-model="newPasswordRepeat"/>
    </div>
    <template #footer>
      <Button
        :label="t('message.masterdata.button.cancel')"
        @click="$emit('update:showDialog', false)"
      />
      <Button
          id="button-save-new-password"
          :label="t('message.masterdata.button.save')"
          :disabled="newPassword != '' && newPasswordRepeat != '' && newPassword == newPasswordRepeat ? false : true"
          @click="onSave();$emit('update:showDialog', false)"
      />
    </template>
  </Dialog>
</template>

<script>

import {ref} from "vue";
import {useStore} from "vuex";
import { useI18n } from 'vue-i18n'

export default {
  name: "mkeChangePasswordDialog",
  props: ["noOldPassword", "showDialog", "userID"],
  setup(props) {
    const store = useStore()
    const newPassword = ref("")
    const newPasswordRepeat = ref("")
    const showDialogRef = ref(props.showDialog)
    const { t } = useI18n({ useScope: 'global' })
    function onSave() {
      store.dispatch("admin/changePassword", {
        newPassword: newPassword.value,
        userID: props.userID
      })
    }

    return { newPassword, newPasswordRepeat, onSave, showDialogRef, t}
  }
}
</script>

<style scoped>

</style>
