<template>
  <Badge
      :label="label"
      :class="classname + ' ' + color + ' ' + 'font-size-'+fontsize"
      :value="value"
      :size="size"
  />

</template>

<script>

export default {
  name: 'mkeBadge',
  props: ['label', 'classname', 'color', 'fontsize']
}
</script>