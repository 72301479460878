<template>
  <div :id="id" class="pos-product-navigation" >
    <div class="mke-widget grid-inset">
      <div class="grid-wrapper rows_8 cols_1">
        <template :key="nav_index" v-for="(navigation, nav_index) in navigations" >
          <template v-if="navigation.navigation_type=='slider'">

            <swiper v-if="rendered"
                  :slides-per-view="7"
                  :slides-per-group="5"
                  :space-between="10"
                  :observer="true"
                  :threshold="3"
                  :observeParents="true"
                  :rewind="true"
                  loopFillGroupWithBlank: false
                  :grabCursor="true"
                  :key="nav_index"
                  :class="'row-start-' + (nav_index+1) + ' row-end-' + (nav_index+1)"
              >
                <swiper-slide
                    class="mke-slide"
                    v-for="(entry, entry_index) in navigation.entries"
                    :key="entry_index"
                    :virtualIndex="entry_index"
                >
                  <mke-button
                      class="p-button-lg"
                      :label="entry.button_text"
                      :color="entry.color"
                      :class="[entry.id == navigation.activeNavigationID ? 'active' : '']"
                      :posAction="entry.posAction"
                      functionCallStore="pos-main-keyboard-display"
                  />
                </swiper-slide>
              </swiper>
        </template>
          <template v-if="navigation.navigation_type=='grid'">
<!--            <div :class="'row-end-8 row-start-' + (nav_index+1)" style="background-color: orange">-->
              <swiper
                    :threshold="3"
                    loopFillGroupWithBlank: false
                    :direction="'vertical'"
                    :pagination='{
                      "clickable": true
                    }'
                    :class="'row-end-9 row-start-' + (nav_index+1)">
                <swiper-slide class="mke-slide">
                    <div class="grid-wrapper cols_7 product-filter-content" :class="'rows_' + (8-nav_index)">
                      <mke-button v-for="(button, index) in navigation.entries"
                                  class="p-button-lg"
                                  :key="index"
                                  :label="button.button_text"
                                  :color="button.color"
                                  :posAction="button.posAction"
                                  functionCallStore="pos-main-keyboard-display"
                      />
                    </div>
                </swiper-slide>
              </swiper>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";

export default {
  name: "PosProductNavigation",
  props: ["id"],
  setup(){
    const store = useStore()
    const navigations = computed( () => store.state.pos.navigations)
    const rendered = ref(false);

    window.setTimeout(function() { rendered.value = true;}, 300);
    return {
      navigations, rendered
    }
  }
}
</script>

<style scoped>

/*.mke-slide {*/
/*  height: 100%;*/
/*}*/

/*.mke-slide button {*/
/*  padding: 1em;*/
/*}*/

/*.mke-swiper-wrapper * {*/
/*  gap: 1em !important;*/
/*}*/

</style>