import {inject} from "vue";
import {useStore} from "vuex";
const restUrlPosService = config.PosServiceUrl


export const pos_api = () => {
    const signalr = inject("signalr")
    const store = useStore()

    /**
     * Close the table
     * TODO: Replace Table functionality with process closing
     * @param table_id
     */
    const closeTable = (table_id) => {
        commitOrder()
        signalr.send("close_table", JSON.stringify({
            "terminal": store.state.waiters.active_terminal,
            "waiter": store.state.waiters.active_waiter,
            "table": table_id
        }))
    }

    /**
     * Create a new process
     * @returns {Promise<number>}
     */
    const createNewProcess = () => {
        let items = store.state.pos_process.items
        // TODO: Remove the "next table" functionality after it isn't an requirement anymore
        let next_table_id = Math.max(...Object.keys(items).map(x=>items[x].Table)) + 1;
        if (next_table_id == -Infinity){
            next_table_id = 1
        }

        // Close active process
        if (store.state.pos_process.active_process != null){
            closeTable(store.state.pos_process.active_process.Table)
        }

        // Open new process
        openTable(next_table_id)

        // We close it directly to show the new process in the ui
        // TODO: Remove this after we implemented the 'last opened processes' functionality
        closeTable(next_table_id)

        // Open new process
        openTable(next_table_id)
        return next_table_id
    }

    /**
     * Open the table
     * TODO: Replace Table functionality with process opening
     * @param table_id
     */
    const openTable = (table_id) => {
        signalr.send("open_table", JSON.stringify({
            "terminal": store.state.waiters.active_terminal,
            "waiter": store.state.waiters.active_waiter,
            "waitername": "superwaiter",
            "table": table_id
        }))
    }

    /**
     * Commit the order of the current process
     */
    const commitOrder = () => {
        if (store.state.pos.activeProcess){
            // signalr.send("commit", JSON.stringify({
            //     "processid": store.state.pos.active_process.ProcessId,
            //     "terminal": store.state.waiters.active_terminal,
            // }));

            window.open(restUrlPosService + "/pos/" + store.state.pos.activeProcess.id + "/bon?compressed=True", "_blank")

        }

    }

    return {
        closeTable,
        createNewProcess,
        openTable,
        commitOrder,
    };

}
