<template>
<div>
  <div class="p-grid mke-pt-5">
    <div class="p-col">
      <div id="mke-title-wrapper">
        <h1 class="display-small">{{ t('message.dashboard.configuration') }}</h1>

      </div>
    </div>
  </div>
  <div class="p-grid mke-pt-5">
    <div class="p-col-12">
      <div class="card">
        <Toolbar class="mke-mb-4">
          <template #left>
<!--            <Button label="New" icon="pi pi-plus" class="p-button-success mke-mr-2" @click="openNew"/>-->
<!--            <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"-->
<!--                    :disabled="!selectedDataservices || !selectedDataservices.length"/>-->
          </template>

          <template #right>
<!--            <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import"-->
<!--                        class="mke-mr-2 p-d-inline-block"/>-->
<!--            <Button label="Export" icon="pi pi-upload" class="" @click="exportCSV($event)"/>-->
          </template>
        </Toolbar>


<Card>
  <template #content>
        <DataTable ref="dt" :value="dataservices" v-model:expandedRows="dataservices.datasources" v-model:selection="selectedDataservices" dataKey="id"
                   :paginator="dataservices.length>1" :rows="10" :filters="filtersDataServices" filterDisplay="menu"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   :rowsPerPageOptions="[5,10,25]"
                   :currentPageReportTemplate="t('message.dashboard.showing')+' '+`{first}`+' '+
                                                 t('message.dashboard.to')+' '+ `{last}`+' '+ t('message.dashboard.of')+' '+ `{totalRecords}`"
                   :globalFilterFields="['name','url','type','progress','state']"
                   responsiveLayout="scroll">
          <template #header v-if="dataservices.length>1">
            <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
              <h5 class="mke-mb-2 p-m-md-0 p-as-md-center">{{ t('message.dashboard.manageDataServices') }}</h5>
              <span class="p-input-icon-left">
                            <i class="pi pi-search"/>
                            <InputText v-model="filtersDataServices['global'].value" :placeholder="placeholder"/>
                        </span>
            </div>
          </template>

<!--          <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>-->
          <Column :expander="true" headerStyle="width: 3rem" />
          <Column field="name" header="Name" :sortable="true" style="min-width:16rem"></Column>
          <Column field="url" header="Url" :sortable="true" style="min-width:12rem"></Column>
          <Column field="type" header="Type" :sortable="true" style="min-width:10rem">></Column>
          <Column field="progress" header="Progress" :sortable="true" style="min-width:12rem">
            <template #body="{data}">
              <ProgressBar :value="data.progress" :showValue="true" class="status-qualified"/>
            </template>
          </Column>
          <Column field="state" header="Status" :sortable="true" style="min-width:12rem">>
            <template #body="slotProps">
              <Button v-if="slotProps.data.state  == 'online'" v-tooltip.focus="slotProps.data.info" icon="mdi mdi-download-network"
                      class="p-button-rounded p-button-success mke-mr-2"/>
              <Button v-if="slotProps.data.state  == 'offline'" v-tooltip.focus="slotProps.data.info" icon="mdi mdi-close-network"
                      class="p-button-rounded p-button-danger mke-mr-2"/>
            </template>
          </Column>
          <Column :exportable="false">
            <template #body="slotProps">
              <div v-if="slotProps.data.type != 'walkservice_mqtt'">
              <Button icon="pi pi-pencil" class="p-button-rounded mke-mr-2"
                      @click="editDataservice(slotProps.data)"/>
              <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                      @click="confirmDeleteDataservice(slotProps.data)"/>
              </div>
            </template>
          </Column>
          <template #expansion="slotProps">
            <div class="orders-subtable">
              <h5> {{ t('message.dashboard.dataSources') }} {{ t('message.dashboard.for') }} {{ t('message.dashboard.dataService') }} {{slotProps.data.name}}</h5>

              <DataTable :value="slotProps.data.datasources"  responsiveLayout="scroll" editMode="cell" class="editable-cells-table" sortField="id" :sortOrder="1"
                         :paginator="true" :rows="10" :filters="filtersDataSources"
                         paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                         :rowsPerPageOptions="[5,10,25]"
                         :globalFilterFields="['id','name','info','progress','sync']"
                         :currentPageReportTemplate="t('message.dashboard.showing')+' '+`{first}`+' '+
                                                 t('message.dashboard.to')+' '+ `{last}`+' '+ t('message.dashboard.of')+' '+ `{totalRecords}`"
                        >
                <template #header>
                  <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                    <h5 class="mke-mb-2 p-m-md-0 p-as-md-center">{{ t('message.dashboard.manage') }} {{ t('message.dashboard.dataSources') }}</h5>
                    <span class="p-input-icon-left">
                            <i class="pi pi-search"/>
                            <InputText v-model="filtersDataSources['global'].value" :placeholder="placeholder"/>
                        </span>
                  </div>
                </template>
                <Column field="id" header="Id"></Column>
                <Column field="name" header="Name"></Column>
                <Column field="info" header="Info"></Column>
                <Column field="progress" header="DataSync" style="min-width:12rem">
                  <template #body="{data}">
                    <ProgressBar :value="data.progress" :showValue="false" class="status-qualified"/>
                  </template>
                </Column>
                <Column field="sync" header="Sync" sortable>
                  <template #body="slotProps">
                    <Checkbox :modelValue="slotProps.data.sync" @click="switchDatasourceSync($event, slotProps)" :binary="true"/>
                  </template>
<!--                  <template #editor="slotProps">-->
<!--                    <Checkbox :modelValue="slotProps.data.sync" @click="switchDatasourceSync($event, slotProps)" :binary="true" />-->
<!--                  </template>-->
                </Column>
              </DataTable>
            </div>
          </template>
        </DataTable>
  </template>
</Card>


      </div>

      <Dialog v-model:visible="dataserviceDialog" :style="{width: '450px'}"  :modal="true"
              class="p-fluid">
        <template #header>
          <h5>{{ t('message.dashboard.DataServiceDetails') }}</h5>
        </template>
        <Toast />
        <div class="p-field">
          <label for="name">Name</label>
          <InputText id="name" v-model.trim="dataservice.name" required="true" autofocus/>
          <small class="p-error" v-if="submitted">{{ apierrors ? apierrors.filter(err => err.key == 'name').map(err => err.value)[0] : '' }}</small>
        </div>
        <div class="p-field">
          <label for="description">Description</label>
          <InputText id="description" v-model.trim="dataservice.description" required="true" autofocus/>
          <small class="p-error" v-if="submitted">{{ apierrors ? apierrors.filter(err => err.key == 'description').map(err => err.value)[0] : '' }}</small>
        </div>
        <div class="p-field">
          <label for="url">URL</label>
          <InputText id="url" v-model.trim="dataservice.url" required="true" autofocus/>
          <small class="p-error" v-if="submitted" >{{ apierrors ? apierrors.filter(err => err.key == 'url').map(err => err.value)[0] : '' }}</small>

        </div>

        <div class="p-field">
          <label for="type" class="mke-mb-3">Type</label>
          <Dropdown v-model="dataservice.type" :options="types" required="true" optionLabel="key" optionValue="code"
                    placeholder="message.dashboard.selectType">
            <template #value="value">
              <span v-if="!value.value" >{{ value.placeholder }}</span>
            </template>
          </Dropdown>
          <small class="p-error" v-if="submitted">{{ apierrors ? apierrors.filter(err => err.key == 'type').map(err => err.value)[0] : '' }}</small>
        </div>

        <template #footer>
          <Button :label="t('message.dashboard.exit')"  icon="pi pi-times" class="p-button-text" @click="hideDialog">
          </Button>
          <Button :label="t('message.dashboard.save')"  icon="pi pi-check" class="mke-mr-0" @click="saveDataservice">
          </Button>
        </template>
      </Dialog>

      <Dialog v-model:visible="deleteDataserviceDialog" :style="{width: '450px'}" :modal="true">
        <template #header>
          <h5>{{ t('message.dashboard.confirm') }}</h5>
        </template>
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mke-mr-3" style="font-size: 2rem"/>
          <span v-if="dataservice">Are you sure you want to delete <b>{{ dataservice.name }}</b>?</span>
        </div>
        <template #footer>
          <Button icon="pi pi-times" class="p-button-text" @click="deleteDataserviceDialog = false">
            <span >{{ t('message.dashboard.reject') }}</span>
          </Button>
          <Button  icon="pi pi-check" class="p-button-text" @click="deleteDataservice">
            <span >{{ t('message.dashboard.accept') }}</span>
          </Button>
        </template>
      </Dialog>

      <Dialog v-model:visible="deleteDataservicesDialog" :style="{width: '450px'}"  :modal="true">
        <template #header>
          <h5>{{ t('message.dashboard.confirm') }}</h5>
        </template>
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mke-mr-3" style="font-size: 2rem"/>
          <span v-if="dataservice">Are you sure you want to delete the selected dataservices?</span>
        </div>
        <template #footer>
          <Button  icon="pi pi-times" class="p-button-text" @click="deleteDataservicesDialog = false">
            <span >{{ t('message.dashboard.reject') }}</span>
          </Button>
          <Button icon="pi pi-check" class="p-button-text" @click="deleteSelectedDataservices">
            <span >{{ t('message.dashboard.accept') }}</span>
          </Button>
        </template>
      </Dialog>
    </div>
  </div>
</div>
</template>
<style>
.p-datatable .p-datatable-thead .p-column-title {
  display: block !important;
}
</style>
<script>
import {computed, ref} from 'vue';
import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'primevue/usetoast';
import {useStore} from "vuex";
import axios from 'axios';
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const resturl = config.ANALYTICSURL
    const store = useStore();
    const toast = useToast();
    const dt = ref();
    const dataserviceDialog = ref(false);
    const deleteDataserviceDialog = ref(false);
    const deleteDataservicesDialog = ref(false);
    const dataservice = ref({});
    const dataservices = computed(() => store.getters["dashboard/get_dataservices"]())
    const { t } = useI18n({ useScope: 'global' })
    const selectedDataservices = ref();
    const filtersDataSources = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
    });
    const filtersDataServices = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
    });
    const placeholder = ref(t("message.dashboard.search"))
    const submitted = ref(false);
    const apierrors = ref(false);
    const types = ref([
      {key: 'Walkservice', code: 'walkservice'},
      {key: 'Kakom', code: 'kakom'},
    ]);

    const formatCurrency = (value) => {
      if (value)
        return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
      return;
    };
    const openNew = () => {
      dataservice.value = {};
      submitted.value = false;
      dataserviceDialog.value = true;
    };
    const hideDialog = () => {
      dataserviceDialog.value = false;
      submitted.value = false;
    };
    const saveDataservice = () => {
      submitted.value = true;
      const submitvalue = (({ url, name, type, description }) => ({ url, name, type, description }))(dataservice.value);
      if (dataservice.value.id) {
        axios.put(resturl +'/dataservices/'+ dataservice.value.id, submitvalue)
            .then(function () {
              dataserviceDialog.value = false;
              dataservice.value = {};
              toast.add({severity: 'success', summary: t('message.home.success'), detail: t('message.dashboard.dataserviceUpdated'), life: 3000});
              apierrors.value = false;
            })
            .catch(function (error) {
              apierrors.value = error.response.data.detail.map(x => {var rObj = {}; rObj['key'] = x.loc[1]; rObj['value'] = x.msg; return rObj})
              toast.add({severity: 'error', summary: t('message.dashboard.serverError'), detail: t('message.dashboard.dataserviceNotCreated'), life: 3000});
            });
      } else {
        axios.post(resturl +'/dataservices', submitvalue)
            .then(function () {
              toast.add({severity: 'success', summary: t('message.dashboard.dataserviceCreated'), detail: t('message.dashboard.dataserviceCreated'), life: 3000});
              dataserviceDialog.value = false;
              dataservice.value = {};
              apierrors.value = false;
            })
            .catch(function (error) {
              apierrors.value = error.response.data.detail.map(x => {var rObj = {}; rObj['key'] = x.loc[1]; rObj['value'] = x.msg; return rObj})
              toast.add({severity: 'error', summary: t('message.dashboard.serverError'), detail: t('message.dashboard.dataserviceNotCreated'), life: 10000});
            });
      }
    };
    const editDataservice = (prod) => {
      dataservice.value = {...prod};
      dataserviceDialog.value = true;
    };
    const confirmDeleteDataservice = (prod) => {
      dataservice.value = prod;
      deleteDataserviceDialog.value = true;
    };
    const deleteDataservice = () => {
      if (dataservice.value.id) {
        axios.delete(resturl +'/dataservices/'+ dataservice.value.id)
            .then(function () {
              deleteDataserviceDialog.value = false;
              dataservice.value = {};
              toast.add({severity: 'success', summary: t('message.home.success'), detail: t('message.dashboard.dataserviceDelete'), life: 3000});
            })
            .catch(function (error) {
              console.log(error)
              toast.add({severity: 'error', summary: t('message.dashboard.serverError'), detail: t('message.dashboard.dataserviceNotDeleted'), life: 10000});
            });
      }
    };
    const switchDatasourceSync = (newValue, props) => {
      store.commit('dashboard/switch_datasource_sync', {dataservice_id: props.data.dataservice_id, datasource_id: props.data.id, sync: !props.data.sync})
    };
    const findIndexById = (id) => {
      let index = -1;
      for (let i = 0; i < dataservices.value.length; i++) {
        if (dataservices.value[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    };
    const createId = () => {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    }
    const exportCSV = () => {
      dt.value.exportCSV();
    };
    const confirmDeleteSelected = () => {
      deleteDataservicesDialog.value = true;
    };
    const deleteSelectedDataservices = () => {
      let todelete = selectedDataservices.value.map(selected => selected.id)
      axios.delete(resturl +'/dataservices', {data: {'dataservice_ids': todelete}})
          .then(function () {
            deleteDataservicesDialog.value = false;
            toast.add({severity: 'success', summary: t('message.home.success'), detail: t('message.dashboard.dataserviceDelete'), life: 3000});
          })
          .catch(function () {
            toast.add({severity: 'error', summary: t('message.dashboard.serverError'), detail: t('message.dashboard.dataserviceNotDeleted'), life: 10000});
          });
    };

    return {
      dt,
      dataservices,
      filtersDataSources,
      filtersDataServices,
      dataserviceDialog,
      deleteDataserviceDialog,
      deleteDataservicesDialog,
      dataservice,
      selectedDataservices,
      submitted,
      apierrors,
      types,
      formatCurrency,
      openNew,
      hideDialog,
      saveDataservice,
      editDataservice,
      confirmDeleteDataservice,
      deleteDataservice,
      findIndexById,
      createId,
      exportCSV,
      confirmDeleteSelected,
      deleteSelectedDataservices,
      switchDatasourceSync,
      placeholder,
      t
    }
  }
}
</script>


