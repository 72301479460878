<template>
  <div id="pos-not-initialized" class="p-d-flex p-flex-column p-ai-center w-100 h-100"  v-if="posInstallationMode !== 'installed'">
    <div class="p-d-flex p-flex-column p-ai-center" style="margin: 20vh 10vh;gap: 1rem;" v-if="posInstallationMode === 'not_installed'">
      <h1>Sie scheinen leider noch keine Stammdaten für die POS definiert zu haben!</h1>
      <h2>Was möchten Sie tun?</h2>
      <a href="/masterdata">
        <mkeButton label="Zu den Stammdaten" color="primary" fontsize="xl"/>
      </a>
      <mkeButton label="Demodaten installieren" color="warning" fontsize="xl" function-call="loadPosDemoData"/>
    </div>
    <div class="p-d-flex p-flex-column p-ai-center" style="margin: 20vh 10vh;gap: 1rem;" v-if="posInstallationMode === 'installing'">
      <h1>Demodaten werden installiert!</h1>
      <h2>Dies kann etwas dauern.</h2>
      <p>Die Kasse wird neu geladen, wenn die Daten installiert sind.</p>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: "PosNotInitialized",
  setup() {
    const store = useStore();
    const posInstallationMode = computed(() => store.state.pos.posInstallationMode)

    return {posInstallationMode};
  }
}
</script>

<style scoped>

</style>