import { createWebHistory, createRouter } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import App from "../App";
import store from "../store";
import LoginTest from "@/views/LoginTest";
import PathNotFound from "@/views/PathNotFound";
import PathNotEnabled from "@/views/PathNotEnabled";
import {SharedElementRouteGuard} from "v-shared-element";

import Admin from "@/views/Admin";
import Dashboard from "@/views/Dashboard";
import MasterData from "@/views/MasterData";
import Bon from "@/views/Bon";
import Monitor from "@/views/Monitor";
import Pos from "@/views/Pos";

const routes = [
    {
        path: "/",
        name: "App",
        component: App,
        redirect: "/home"
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/login_test",
        name: "LoginTest",
        component: LoginTest,
    },
    {
        path: '/disabled',
        name: "PathNotEnabled",
        component: PathNotEnabled
    },
    {
        path: '/:pathMatch(.*)*',
        name: "PathNotFound",
        component: PathNotFound
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});


router.beforeEach((to, from, next) => {
    if (!(to.name === 'Login' || to.name === 'LoginTest' ) && store.state.general.jwtToken == null){
        next({
            name: 'Login',
            params: { nextUrl: to.fullPath }
        })
    }
    else next()
});

router.beforeEach((to, from) => {
    let currentApp = store.state.general.activeApp;

    if( !currentApp && to.path !== "/home") {
        console.log( "DETERMINING APP FROM ROUTE ", to );

        currentApp = store.state.general.apps.find( x => x.path === to.path || x.link === to.path );

        if( currentApp ) {
            store.commit("general/setActiveApp", currentApp);
        }
    }

    const enabled = store.getters["general/isAppEnabled"](currentApp);

    if( !enabled && to.path !== "/home" && to.path !== "/disabled" && to.name !== "PathNotFound" && !to.path.startsWith("/login")) {
        return { name: "PathNotEnabled"};
    }

    console.log( "ROUTE ", to, "=>", from, ": ", currentApp );
});

router.beforeEach((to, from, next) => {
    // Da - aus welchem Grund auch immer - beim (re)load der Home-App, der Router 2x mal getriggert wird
    // beim 2. Mal mit dem gleichen Pfad, kommt v-shared-element durcheinander,
    // weil der Guard dann in einem falschen Zustand getriggert wird.
    //
    // Im Prinzip hat shared-Element zwei Maps: den element-cache und die element-candidates.
    // Letztere bestehen aus den Elementen der neuen Seite, die mit dem Cache (also den Elementen der letzten Seite)
    // abgeglichen und schlussendlich animiert werden. Beide werden abgeglichen nachdem die Seite aufgebaut wurde und
    // dann ggfs die Animationen getriggert.
    //
    // Durch den erneuten Router-Aufruf werden zwar beide Maps bereinigt, da sich aber sonst nichts ändert und
    // insbesondere kein mount durchgeführt wird, wird kein Abgleich gestartet und die Maps sind nicht im richtigen
    // Zustand für einen Seitenwechsel.
    //
    // Daher verhindern wir den Aufruf des Guards, wenn versucht wird, denselben Pfad nochmals aufzurufen.
    if( from.path !== to.path ) {
        SharedElementRouteGuard(to, from, next);
    } else {
        next();
    }
});

const appComponents = {
    "Home": Home,
    "Dashboard": Dashboard,
    "Pos": Pos,
    "MasterData": MasterData,
    "Bon": Bon,
    "Monitor": Monitor
};

store.state.general.apps.forEach( (app) => {
    if( app.path ) {
        const route = {...app};
        route.component = appComponents[app.component];
        router.addRoute(route);
    }
} );

router.addRoute( {
    name: "Admin",
    component: Admin,
    path: "/admin/:modelName?/:view?/:id?",
    props: true
});

console.log("ROUTER ready");


export default router;