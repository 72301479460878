<!--
Type Description:
Type 0: Free Product Price
Type 1: Free Product Text
Type 2: Free Weight
Type 3: Free Length
Type 4: Free Width
Type 5: Choice to make
-->
<template>
  <Dialog v-model="label" :style="{width: '50vw'}" :modal="true">
    <div v-if="constraints.Type != 5">
      <InputText v-model="freeInputText"/>
    </div>
    <div v-if="constraints.Type == 5">
      <mkeButton v-for="(choice, index) in constraints.Choices"
              :key="index"
              :label="choice.Article"
              v-bind=choice
              @click="$emit('closeProductConstraintsDialog', index)"/>
    </div>
    <template #footer>
      <Button label="Back" icon="pi pi-times" class="p-button-text"/> <!--TODO: Implement back functionality after it was implemented in the PosService -->
      <Button v-if="constraints.Type != 5" label="Next" icon="pi pi-check" :disabled="!freeInputText" @click="$emit('closeProductConstraintsDialog', freeInputText)" />
    </template>
  </Dialog>
</template>

<script>
import {ref} from "vue";

export default {
  name: "PosProductConstraintsDialog",
  props: ['AskItems', 'CurrentCallback'],
  emits: ["closeProductConstraintsDialog"],

  setup(props){
    const askItems = JSON.parse(JSON.stringify(props.AskItems))
    const constraints = ref(false);
    const label = "jesus";
    const freeInputText = ref(askItems[props.CurrentCallback].Default)
    constraints.value = askItems[props.CurrentCallback]

    return {
      constraints,
      freeInputText,
      label
    }
  }
}
</script>
<style scoped>

</style>