<template>
  <Dialog id="process-payments-info-dialog" :visible="processPaymentsInfoDialogVisible"
          :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true" :closable="false">
    <template #header>
      <h5>{{t('message.pos.payment.info_title')}}</h5>
    </template>

    <div class="p-d-flex p-flex-column w-100 ">
        <Card v-for="paymentInfo in paymentsInfo" :key="paymentInfo.id" class="payment-info w-100">
          <template #content>
            <h5>Allgemein</h5>
            <div class="transaction-header p-d-flex p-jc-between">
              <div class="timestamp">{{d(paymentInfo.timestamp_end, 'datetime_short')}}</div>
              <div class="waiter">{{paymentInfo.waiter_name}}</div>
              <div class="payment p-d-flex p-flex-column" style="gap: 1rem">
                <div v-if="paymentInfo.tip > 0">
                  <label>{{t('message.pos.payment.tipAmount')}}</label>
                  {{n(paymentInfo.tip, 'currency')}}
                </div>
                <div v-for="payment in paymentInfo.payments" :key="payment.id" class="text-align-right">
                  <label>{{payment.payment_name}}</label>
                  {{n(payment.amount, 'currency')}}
                </div>
              </div>
            </div>
            <h5 class="mke-pt-3" >Positionen</h5>
            <div class="transaction-lines">
              <table class="w-100 bordered-split">
                <thead>
                  <tr>
                    <th class="text-align-right">{{t('message.pos.payment.line_no')}}</th>
                    <th class="text-align-right">{{t('message.pos.process_history.quantity')}}</th>
                    <th class="text-align-left  w-100">{{t('message.pos.process_history.name')}}</th>
                    <th>{{t('message.pos.payment.business_case')}}</th>
                    <th>{{t('message.pos.payment.vat_title')}}</th>
                    <th class="text-align-right">{{t('message.pos.process_history.base_price')}}</th>
                    <th class="text-align-right">{{t('message.pos.process_history.price')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="transaction_line" v-for="transactionLine in paymentInfo.transaction_lines" :key="transactionLine.id">
                    <td class="text-align-right">{{transactionLine.transaction_line_no}}</td>
                    <td class="text-align-right">
                      <span v-if="transactionLine.item">
                        {{$filters.toNumberMostlyInt(transactionLine.item.quantity)}}
                      </span>
                    </td>
                    <td class="w-100">{{transactionLine.name}}</td>
                    <td>{{transactionLine.business_case_name}}</td>
                    <td >{{transactionLine.vats.map((v)=>v.vat_code).sort().join("/")}}</td>
                    <td class="text-align-right">
                      <span v-if="transactionLine.item">
                        {{n(transactionLine.item.price_per_unit, 'currency')}}
                      </span>
                    </td>
                    <td class="text-align-right">{{n(transactionLine.price, 'currency')}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h5 class="mke-pt-3">{{t('message.pos.payment.vat_title')}}</h5>
            <div class="vat-lines">
              <table class="w-100 bordered-split">
                <thead>
                <tr>
                  <th class="text-align-left w-100">{{t('message.pos.payment.vat_name')}}</th>
                  <th class="text-align-right">{{t('message.pos.payment.vat_code')}}</th>
                  <th class="text-align-right">{{t('message.pos.payment.vat_net_price')}}</th>
                  <th class="text-align-right">{{t('message.pos.payment.vat_title')}}</th>
                  <th class="text-align-right">{{t('message.pos.payment.vat_gross_price')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr class="vat_line" v-for="vat in paymentInfo.vats" :key="vat.id">
                  <td class="w-100">{{vat.vat_name}}</td>
                  <td class="text-align-right">{{vat.vat_code}}</td>
                  <td class="text-align-right">{{n(vat.net_price, 'currency')}}</td>
                  <td class="text-align-right">{{n(vat.vat_price, 'currency')}}</td>
                  <td class="text-align-right">{{n(vat.gross_price, 'currency')}}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <h5 v-if="paymentInfo.fiscal_info?.tse_id" class="mke-pt-3">TSE</h5>
            <div v-if="paymentInfo.fiscal_info?.tse_id">
              <mkeDisplayValue label="message.pos.tse.transaction_number" :value="paymentInfo.fiscal_info.transaction_number"/>
              <mkeDisplayValue label="message.pos.tse.signature_counter" :value="paymentInfo.fiscal_info.signature_counter"/>
              <mkeDisplayValue label="message.pos.tse.timestamp_start" :value="paymentInfo.fiscal_info.timestamp_start"/>
              <mkeDisplayValue label="message.pos.tse.timestamp_finished" :value="paymentInfo.fiscal_info.timestamp_finished"/>
              <mkeDisplayValue label="message.pos.tse.process_type" :value="paymentInfo.fiscal_info.process_type"/>
              <mkeDisplayValue label="message.pos.tse.process_data" :value="paymentInfo.fiscal_info.process_data"/>
              <mkeDisplayValue label="message.pos.tse.tse_id" :value="paymentInfo.fiscal_info.tse_id"/>
              <mkeDisplayValue label="message.pos.tse.signature" :value="paymentInfo.fiscal_info.signature" style="word-wrap: break-word; word-break: break-word;"/>
            </div>
          </template>
        </Card>
      </div>
    <template #footer>
      <mke-button id="button-process-history-close" label="Schliessen" @click="closeDialog" class="p-button-primary"/>
    </template>
  </Dialog>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: "PosProcessPaymentsInfoDialog",
  setup(){
    const store = useStore();
    const { t, n, d } = useI18n({ useScope: 'global' })

    const processPaymentsInfoDialogVisible = computed( () =>
        store.state.pos.paymentsInfo !== undefined && store.state.pos.paymentsInfo !== null );

    const paymentsInfo = computed( () => store.state.pos.paymentsInfo);

    const closeDialog = () => {
      store.commit('pos/setPaymentsInfo', null);
    }

    return {
      t, n, d, paymentsInfo, processPaymentsInfoDialogVisible, closeDialog
    }
  }
}

</script>

<style scoped>

</style>