// initial state
// shape: [{ id, quantity }]

const state = () => ({
    items: {},
    active_process: null,
    processStatus: null,
    selectedProcessListColumns: [],
    showProcessListDialog: false,
})
// getters
const getters = {
    getSelectedProcessListColumns: (state) => () => {
        if (state.selectedProcessListColumns.length == 0){
            return getters.getProcessListKeys(state)()
        }
        return state.selectedProcessListColumns
    },
    getProcessListKeys: (state) => () => {
        try{
            return [...new Set(
                Object.keys(state.items)                // ProcessIds
                .map(x => Object.keys(state.items[x]))  // Keys of all Processes
                .flat()
                .filter(column => !['Actions', 'items', 'OrderLines', 'Process'].includes(column)))]
        } catch(err){
            return []
        }
    },
    /**
     * Get a list of processes
     * @param state
     * @returns {function(): *[]}
     */
    getProcessList: (state) => () => {
        let res = []
        try {
            res = Object.keys(state.items).map(x => state.items[x])
        } catch(err) {
            res = []
        }
        return res
    }
}

// actions
const actions = {
}

// mutations
const mutations = {
    setShowProcessListDialog (state, showIt){
        state.showProcessListDialog = showIt;
    },
    set_process (state, process) {
        state.items[process.ProcessId] = process
        state.items[process.ProcessId]['items'] = []
        if ('process_entries' in state.items[process.ProcessId]){
            for (const orderLine of state.items[process.ProcessId]['process_entries']){
                state.items[process.ProcessId]['items'].push(orderLine)
            }
        }
        if ('compressed_entries' in state.items[process.ProcessId]){
            for (const orderLine of state.items[process.ProcessId]['compressed_entries']){
                state.items[process.ProcessId]['items'].push(orderLine)
            }
        }
    },
    set_order_item (state, item) {
        state.items[item.ProcessId].items.push(item)
    },
    /**
     * Set the selected process list columns
     * If the item is already included it gets removed (uncheck operation)
     * @param item The item to add/remove
     */
    setSelectedProcessListColumns(state, item){
        state.selectedProcessListColumns = getters.getProcessListKeys(state)().filter(col => item.includes(col))
    },
    update_order_item(state, item) {
        var filtered_item = state.items[item.pos_process].items.find(someobject => someobject.id == item.id)
        if (filtered_item) {
            Object.assign(filtered_item, item);
        } else {
            state.items[item.pos_process].items.push(item)
        }
    },
    switch_process(state, processUUID) {
        state.active_process = state.items[String(processUUID)]
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
