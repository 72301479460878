<template>
  <div class="p-grid p-ai-center mke-px-0">
    <div v-if="label" class="p-col-4 mke-p-0">{{ label }}</div>
    <div class="p-col-8 mke-p-0">
      <InputSwitch
        v-model="value"
        @input="$emit('update:modelValue', $event)"
        :trueValue="true"
        :falseValue="false">
      </InputSwitch>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";

export default {
  name: "mkeFormBuilderInputSwitch",
  props: ["id", "label", "modelValue", "readonly"],
  emits: ["update:modelValue"],
  setup(props) {
    const value = ref(props.modelValue)
    return {value}
  }
}
</script>

<style scoped>

</style>