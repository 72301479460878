<template>
  <div>

    <div class="p-grid mke-pt-5">
      <div class="p-col-fixed p-d-flex p-ai-center">
          <h1 class="mke-page-title display-small p-d-inline-block">
            {{ t('message.home.all') }} {{ t('message.masterdata.model.' + model.toLowerCase(), 2) }}
          </h1>
      </div>
      <div class="p-col p-d-flex p-ai-center">
        <mke-button
              id="button-model-overview-new"
              :label="t('message.dashboard.new')"
              icon="pi pi-plus"
              class="p-button-primary no-shrink"
              @click="onRowClick()"
            />
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <div class="card">
          <mkeDataTable :modelData="data" :columns="columns" :actions="actions"
                        :deleteButton="true" :editButton="true" :loading="loading"
                        :filters="filters" :lazy="true"
                        @onDelete="onDelete" @onEdit="onRowClick" @onNew="onRowClick" @onPage="onPage"
                        @onRowClick="onRowClick" @onSort="onSort" @onAction="onAction" @onFilter="onFilter"
                        :rows="tableRows" :rowsPerPageOptions="[10, 20, 50, 100]"
                        selectionMode="multiple" :totalRecords="totalRecords"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import { useI18n } from 'vue-i18n'

export default {
  name: "mkeModelOverview",
  props: ["actions", "columns", "rowCount", "store"],
  emits: ["onRowClick"],
  setup(props, context){
    const store = useStore()
    const router = useRouter()
    const model = computed(() => store.state[props.store].model)
    const data = computed(() => store.state[props.store][model.value].items)
    const lazyParams = ref({});
    const loading = ref(false)
    const tableRows = ref(store.state[props.store][model.value].limit || router.currentRoute.value.query.limit || props.rowCount || 10)
    const totalRecords = computed(() => store.state[props.store][model.value].total)
    const { t } = useI18n({ useScope: 'global' })
    /**
     * Load new data (paginated)
     */
    const loadLazyData = () => {
      loading.value = true;

      store.dispatch(props.store + "/getData", {
        model: model.value,
        offset: lazyParams.value.first || 0,
        limit: lazyParams.value.rows || tableRows.value || 0,
        order: lazyParams.value.sortOrder,
        sort: lazyParams.value.sortField,
        filter: lazyParams.value.filters
      })
      loading.value = false;

      // Write data for url into the query
      // let queryData = JSON.parse(JSON.stringify(router.currentRoute.value.query))
      // queryData.offset = lazyParams.value.first
      // queryData.limit = tableRows.value
      // queryData.order = lazyParams.value.sortOrder
      // queryData.sort = lazyParams.value.sortField
      // router.push({query: queryData})
    };

    const filters = computed( () => {
        const filterEntries = props.columns
            .filter(column => column.filter || column.options )
            .map(column => [column.field, {
              value: lazyParams.value.filters && lazyParams.value.filters[column.field] ? lazyParams.value.filters[column.field].value : "",
              matchMode: column.filter
            }]);

        return Object.fromEntries(new Map(filterEntries));
    } );

    function initLazyParams() {
      lazyParams.value = {
        first: router.currentRoute.value.query.offset || 0,
        rows: tableRows.value,
        sortField: null,
        sortOrder: null,
      };
    }
    /**
     * Load new data if the model changes
     */
    watch(model, () => {
      initLazyParams();
      loadLazyData();
    })

    /**
     * Load the initial data
     */
    onMounted(() => {
      loading.value = true;
      if( !lazyParams.value ) {
        initLazyParams();
      }
      loadLazyData();
    })

    /**
     * Called on action selection. Used to emit the selected action with the selected data.
     * @param {String} action The action to emit
     * @param {Array} selectedData The selected data
     */
    const onAction = ({action, selectedData}) => {
      context.emit(action, selectedData)
    }

    /**
     * Delete the given data
     * @param data The data to delete
     */
    const onDelete = (data) => {
      store.dispatch(props.store + "/deleteData", {model: model.value, id: data.id})
    }

    /**
     * Load new data on table page change
     */
    const onPage = (event) => {
      lazyParams.value = event;
      loadLazyData();
    };

    /**
     * Open detail view
     */
    const onRowClick = (data) => {
      if (data){
        store.commit(props.store + "/setActiveID", data.id)
      } else {
        store.commit(props.store + "/setActiveID", null)
      }
      store.commit(props.store + "/setViewMode", "detail")
      context.emit("onRowClick", data)
    };

    /**
     * Load new data on column sorting
     */
    const onSort = (event) => {
      lazyParams.value = event;
      loadLazyData();
    };

    /**
     * Load new data on column sorting
     */
    const onFilter = (event) => {
      lazyParams.value = event;
      loadLazyData();
    };

    return {data, loading, model, filters, onAction, onDelete, onPage, onRowClick, onSort, onFilter, tableRows, totalRecords, t}
  },
}
</script>
