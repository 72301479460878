<template>
  <Dialog id="mke-formbuilder-add-item-dialog"
          :modal="true"
          :draggable="false"
          :closable="true"
          :visible="visible"
          :dismissableMask="true"
          >
    <template #header>
      <h3 class="p-d-flex p-jc-between p-align-center w-100">{{t(title)}}</h3>
    </template>

    <AutoComplete
        :multiple="true"
        v-model="selectedItems"
        :suggestions="filteredItems"
        :field="renderItem"
        @complete="searchItems($event)" />

    <template #footer>
      <mke-button id="button-combo-cancel" :label="t('message.masterdata.button.cancel')" icon="pi pi-times" class="p-button-transparent" @click="closeDialog" />
      <mke-button id="button-combo-commit" :label="t('message.masterdata.button.accept')" icon="pi pi-check" class="p-button-primary" @click="commitItemAdded" />
    </template>
  </Dialog>
</template>

<script>
import {useStore} from "vuex";
import {ref} from "vue";
import {useI18n} from "vue-i18n/dist/vue-i18n.esm-bundler";

export default {
  name: "mkeFormBuilderAddItemDialog",
  props: ["title", "endpoint", "visible"],
  emits: ["onItemAddCommitted", "onItemAddCanceled"],
  setup(props, context){
    const store = useStore();

    const { t } = useI18n({ useScope: 'global' })

    const selectedItems = ref([]);
    const filteredItems = ref( []);

    const closeDialog = () => {
      store.commit("general/addToast", {
        detail: t('message.masterdata.toast.cancel'),
        life: 3000,
        severity: "info",
        summary: "Info"
      } );
      context.emit("onItemAddCanceled");
      filteredItems.value = [];
      selectedItems.value = [];
    }

    const commitItemAdded = () => {
      context.emit("onItemAddCommitted", selectedItems.value);
      filteredItems.value = [];
      selectedItems.value = [];
    }

    const searchItems = (event) => {
      setTimeout(() => {

        if ( !event.query.trim().length || event.query.trim().length < 3 ) {
          filteredItems.value = [];
        }
        else {
          store.dispatch("admin/retrieveData", {
            model:props.endpoint,
            sort: "name",
            offset: 0,
            limit: 100,
            filter: {q:event.query.trim()},
            successHandler: (data) => {
              filteredItems.value = data.items;
            }
          })
        }
      }, 250);
    };

    const renderItem = (item) => {
      if( "plu" in item ) {
        return item.name + " [" + item.plu + "]";
      } else {
        return item.name;
      }
    }

    return {
      t, selectedItems, filteredItems, searchItems, renderItem, commitItemAdded, closeDialog
    }
  }
}

</script>

<style scoped>

</style>