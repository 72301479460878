<template>
  <div class="mx-querybuilder">
    <slot v-bind="queryBuilderProps">
      <queryBuilderGroup v-bind="queryBuilderProps" v-model:query="query"  />
    </slot>
  </div>
</template>

<script>
import queryBuilderGroup from "@/components/dashboard/querybuilder/components/queryBuilderGroup";
import queryBuilderRule from "@/components/dashboard/querybuilder/components/queryBuilderRule";
import { ref, computed, onMounted, watch} from 'vue'
import deepClone from "@/utilities";
import i18n from "@/i18n";



const defaultLabels = {
  matchTypes: [
    { id: "and", label: i18n.global.t("message.dashboard.queryBuilder.and") },
    { id: "or", label:  i18n.global.t("message.dashboard.queryBuilder.or") },
  ],
  addRule: i18n.global.t("message.dashboard.queryBuilder.addRule"),
  addGroup: i18n.global.t("message.dashboard.queryBuilder.addGroup"),
};

export default {
  name: "VueQueryBuilder",
  components: {
    queryBuilderGroup,
  },

  props: {
    dataSource: Array,
    cube: String,
    rules:Object,
    labels: {
      type: Object,
      default() {
        return defaultLabels;
      },
    },
    groupComponent: {
      type: Object,
      default: queryBuilderGroup,
    },
    ruleComponent: {
      type: Object,
      default: queryBuilderRule,
    },
  },

  setup(props, context) {
    const query = ref({
      condition: props.labels.matchTypes[0].id,
      rules: [],
    },)

    const updateRuleData = () => {
        let rules = deepClone(props.rules)
        query.value.condition = rules.condition
        rules.rules.forEach((rule)=>{
              let updatedRule = {}
          // Check if query is a rule or a group
              if ("rules" in rule){
                updatedRule.query = {rules: []}
                updatedRule.type = "query-builder-group"
                updatedRule.query.condition = rule.condition
                rule.rules.forEach((nestedRule)=>{
                  let a = {}
                  a.type = "query-builder-rule"
                  a.query = nestedRule
                  updatedRule.query.rules.push(a)
                })
              }
              else{
                updatedRule.type = "query-builder-rule"
                if (rule.type === 'date'){
                  if (Array.isArray(rule.value)){
                    rule.value[0] = new Date(rule.value[0])
                    rule.value[1] = new Date(rule.value[1])
                  }
                  else
                    rule.value = new Date(rule.value)
                }
                updatedRule.query = rule
              }
              query.value.rules.push(updatedRule)
            }
        )
    }
    /**
     * Load the initial rules
     */
    onMounted(() => {
      if (props.rules)
        updateRuleData()
    })

    const  mergedLabels = computed(() => {
      return Object.assign({}, defaultLabels, props.labels);
    })
    const mergedRules = computed(() => {
      let mergedRules = [];
      props.dataSource.forEach(function (rule) {
          mergedRules.push(rule);
      });

      return mergedRules;
    })
    const queryBuilderProps = computed(() => {
      return {
        index: 0,
        dataSource: mergedRules.value,
        cube:props.cube,
        labels: mergedLabels.value,
        groupComponent: props.groupComponent,
        ruleComponent: props.ruleComponent,
      };
    })
    /**
     get value of the last rule
     * */
    const getFieldValue = (rules) => {
      if (rules.length) {
        let rule = rules.slice(-1)
        if (rule[0].type === "query-builder-group")
          return getFieldValue(rule[0].query.rules)
        else
          return rule[0].query.value

      }
    }
      /**
      emit all rules that have a value to the parent
      * */
    watch(query.value.rules, () => {
      let value = getFieldValue(query.value.rules)
      if (value)
        context.emit("ruleChange", true);
    })
    function getValidRules (validQuery ){
       if (!validQuery)
           validQuery = deepClone(query.value)
       return {condition:validQuery.condition, rules: validQuery.rules.map(rule => {
         if (rule.type ===  "query-builder-rule")
           return deepClone(rule.query)
         else
           return  getValidRules(rule.query)
         })}

    }
    return { queryBuilderProps, query, getValidRules }
  },

}
</script>
