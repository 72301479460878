<template>
  <div class="p-grid mke-pt-5">
    <div class="p-col-fixed p-d-flex p-ai-center">
      <mke-button
          icon="mdi mdi-arrow-left"
          class="p-button-text no-shrink mke-mr-3"
          @click="goBack()"
      />
      <h1 v-if="data.name == null || data.name === ''" class="mke-page-title display-small p-d-inline-block">
        {{ t('message.home.newE') }} {{ t('message.masterdata.model.'+ model.toLowerCase()) }}
      </h1>
      <h1 v-else class="display-small p-d-inline-block">{{ data.name }}</h1>
    </div>
    <div class="p-col p-d-flex p-ai-center">

      <mke-button
          id="button-model-detail-save"
          :label="t('message.dashboard.save')"
          class="p-button-primary no-shrink mke-ml-2"
          @click="save"
          :disabled="!modelValueChanged"
      />
    </div>
  </div>
  <div class="p-grid mke-pt-5">
    <div class="p-col-12">
      <mkeFormBuilder v-if="loading !== true" :store="store" :activeID="activeID" :schema="schema" :data="data" :options="options" v-on:modelValueUpdate="onModelValueUpdate"/>
    </div>
  </div>
</template>

<script>
import {onMounted, ref, provide} from "vue";
import {useStore} from "vuex";
import { useI18n } from 'vue-i18n'

export default {
  name: "mkeModelDetail",
  props: ["activeID", "model", "schema", "store"],
  emits: ["modelValueUpdate", "onGoBack", "onSaved"],
  setup(props, context) {
    const store = useStore()
    const data = ref({})
    const loading = ref(false)

    if (props.activeID) {
      loading.value = true
      store.dispatch(props.store + "/getSpecificData",{id: props.activeID, model: props.model,
        onSuccessCallback: (newData) => {
          data.value = {...newData}
          loading.value = false
      }});
    }
    const modelValueChanged = ref(false)
    const options = ref(store.state[props.store].options)
    const { t } = useI18n({ useScope: 'global' })

    // Provide relevant model information for deep nested components
    // instead of passing them as properties through the hierarchy
    provide( "modelName", props.model);
    provide( "modelData", data);
    provide( "modelStore", props.store);
    provide( "modelID", props.activeID);
    provide( "modelOptions", options);
    provide( "setModelValue", setModelValue);

    /**
     * Get the options for many2one fields
     */
    onMounted(() => {
      retrieveOptions(props.schema);
    })

    const retrieveOptions = (elements) => {
      for (let element of elements.filter(x => ["many2one", "many2many", "one2many"].includes(x.type) && x.model != null)){
        window.setTimeout( () => {
          store.dispatch((element.store || props.store) + "/getAllData", {field: element.field, model: element.model, filter: element.filter, onSuccessCallback: (newData) => {
              // Set the options for the props store to show data from different services in one page
              if (element.store) {
                store.commit(props.store + "/setOptions", {field: element.field, data: newData})
              }
            }})
          }, 50);
      }

      elements.filter(x => x.component === "mkeFormBuilderTab").forEach( tab => {
        tab.tabs.forEach( panel => {
          retrieveOptions(panel.elements);
        });
      });

    }

    /**
     * Save the new data
     * Put request to the service
     */
    const save = () => {
      if (data.value.id){
        store.dispatch(props.store + "/saveData", {data: data.value, model: props.model, successCallback:onSuccessfullSave})
      } else {
        store.dispatch(props.store + "/createData", {data: data.value, model: props.model, successCallback:onSuccessfullSave})
      }


    };

    const onSuccessfullSave = (savedData) => {
      context.emit("onSaved");

      if( store.state[props.store].go_back_on_save ) {
        goBack()
      } else {
        data.value = {...savedData};
      }
    }

    /**
     * Go back to Overview
     */
    const goBack = () => {
      context.emit("onGoBack")
    };

    /**
     * Triggered on model value update.
     */
    function onModelValueUpdate(field, value) {
      modelValueChanged.value = true
      context.emit("modelValueUpdate", field, value)
    }

    /**
     * Triggered on model value update.
     */
    function setModelValue({field, value}) {
      modelValueChanged.value = true
      data.value[field] = value;
    }

    return {data, goBack, loading, modelValueChanged, onModelValueUpdate, setModelValue, options, save, t}
  }
}
</script>
