<template>
  <div
    class="mke-spinner-ring"
    :style="'width:' + size + 'px;' + 'height:' + size + 'px;'"
  >
    <div :style="'width:' + size + 'px;' + 'height:' + size + 'px;' + 'border-width:' + size/7 + 'px;'"></div>
    <div :style="'width:' + size + 'px;' + 'height:' + size + 'px;' + 'border-width:' + size/7 + 'px;'"></div>
    <div :style="'width:' + size + 'px;' + 'height:' + size + 'px;' + 'border-width:' + size/7 + 'px;'"></div>
    <div :style="'width:' + size + 'px;' + 'height:' + size + 'px;' + 'border-width:' + size/7 + 'px;'"></div>
  </div>
</template>

<script>
export default {
  name: "mkeSpinner",
  props: ["size"],
};
</script>

<style lang="scss" scoped>
.mx-app-loader .mke-spinner-ring > div {
  top: 0px !important;
  left: 0px !important;
}
</style>
