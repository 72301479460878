<template>
  <Dialog id="process-tse-dialog" :visible="processTSEDialogVisible" :modal="true" :closable="false"
          :style="{width: '50vw'}" :breakpoints="{'960px': '75vw', '640px': '90vw'}">
    <template #header>
      <h5>{{t('message.pos.tse.title')}}</h5>
    </template>

    <TabView>
      <TabPanel :header="t('message.pos.tse.orders')">
        <PosTSEList :signatures="tseInfo.order_signatures"></PosTSEList>
      </TabPanel>

      <TabPanel :header="t('message.pos.tse.process')" >
        <PosTSEList :signatures="tseInfo.process_signatures"></PosTSEList>
      </TabPanel>
    </TabView>

    <template #footer>
      <mke-button id="button-process-history-close" label="Schliessen" @click="closeDialog" class="p-button-primary"/>
    </template>
  </Dialog>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: "PosProcessTSEDialog",
  setup(){
    const store = useStore();
    const { t, n, d } = useI18n({ useScope: 'global' })

    const processTSEDialogVisible = computed( () =>
        store.state.pos.tseInfo !== undefined && store.state.pos.tseInfo !== null );

    const tseInfo = computed( () => store.state.pos.tseInfo);

    const closeDialog = () => {
      store.commit('pos/setTseInfo', null);
    }

    return {
      t, n, d, tseInfo, processTSEDialogVisible, closeDialog
    }
  }
}

</script>

<style scoped>

</style>