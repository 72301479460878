<template>
  <div class="p-grid p-ai-center mke-px-0">
    <div v-if="label" class="p-col-4 mke-p-0">{{ label }}</div>
    <div class="p-col-8 mke-p-0" :data-value="modelValue">
      <Dropdown :id="id" class="block" :modelValue="modelValue" :options="options" :optionLabel="optionLabel ? optionLabel : 'name'" :optionValue="optionValue ? optionValue : 'id'" :filter="true"
            :placeholder="placeholder" :showClear="true" @change="$emit('update:modelValue', $event.value)"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "mkeFormBuilderDropdown",
  props: ["id", "label", "modelValue", "options", "optionLabel", "optionValue", "placeholder", "readonly"],
}
</script>

<style scoped>

</style>