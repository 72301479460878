<template>
  <div class="mke-dsh-widget" v-bind:class="[widget_value.loading ? 'loading' : '']">
    <div class="grid-stack-item">
      <mkePreloader v-show="widget_value.loading"/>
    </div>
    <Card class="grid-stack-item-content">
      <template #header>
        <div class="p-d-flex p-jc-between relative">
          <!-- menu button -->
          <div>
          <h2 v-if="title" class="display-micro">{{ title }}</h2>
          <h3 v-if="subtitle" class="body-14-regular">{{ subtitle }}</h3>
          </div>
          <mke-context-menu v-bind:widget_id="id"/>
          <!-- TODO: Widget Context Menu -->
          <!-- <Button
            class="p-button-transparent p-button-rounded context-menu"
            type="button"
            icon="mdi mdi-dots-vertical"
            @click="toggle"
          />
          <Menu ref="menu" :model="items" :popup="true" /> -->
        </div>
      </template>
      <template #content>
        <div v-if="widget_value.error == false"  style="width: 100%; height: 100%">
          <v-chart :id=id class="chart" :option="option" theme="42-default" autoresize />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>

import {computed, ref, watch, getCurrentInstance} from "vue";
import {useStore} from "vuex";
import * as echarts from "echarts";
import mkeContextMenu from "./components/mkeContextMenu";


export default {
  name: "GraphBar",
  props: ["id", "widget_id", "title", "subtitle"],
  setup(props) {
    const store = useStore()
    const widget_value = computed(() => store.getters["dashboard/get_widget_info"](props.id))
    const xdata = ref(widget_value.value.value['xdata'])
    const series = ref(widget_value.value.value['series'])
    const app = getCurrentInstance()
    const FormatValue = app.appContext.config.globalProperties.$filters
    watch(widget_value, () => {
      if (widget_value.value.value_type != undefined && widget_value.value.value_type === 'bar') {
        xdata.value = widget_value.value.value['xdata']
        series.value = widget_value.value.value['series']
        try {
          let myChart = echarts.getInstanceByDom(document.getElementById(props.id))
          let currentOption = myChart.getOption()
          if (currentOption.series.length > option.value.series.length) {
            myChart.setOption(option.value, {notMerge: true})
            myChart.resize()
          }
        } catch (err) {
          //Ignore if chart not inited
        }
      }
    })
    const option = ref({
      grid: {
        top: 60,
        left: 10,
        right: 15,
        bottom: 40,
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: "cross",
        },
        formatter: (params) => {
          let tooltip = params[0].axisValue + "<br>"
          params.forEach((value) => {
            if(value.data != 0) {
              tooltip = tooltip + value.marker + value.seriesName + ": " + value.data.toFixed(2) + "<br>"
            }
          })
          return tooltip
        },
    },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        right: 'auto',
        top: 'auto',
        selector: [{
          type: 'all or inverse',
          title: 'Alle'
        },
          {
            type: 'inverse',
            title: 'Inv'
          }]
      },
      xAxis: {
        type: 'category',
        data: xdata,
        offset: 10,
        // axisLabel: {
        //   // interval: 0,
        //   // rotate: 45 //If the label names are too long you can manage this by rotating the label.
        //   formatter: function (value,) {
        //     return FormatValue.toDate(value)
        //   }
        // }
      },
      toolbox: {
        left: 'center',
        itemSize: 25,
        top: 20,
        feature: {
          saveAsImage: {
            backgroundColor:'black'
          },
          dataZoom: {
            yAxisIndex: 'none'
          },
          magicType: {
            type: ['bar', 'line', 'stack']
          },
        }
      },
      yAxis: {
        type: "value",
        offset: 10,
        axisPointer: {
          snap: true,
        },
        axisLabel: {
          formatter: function (value, ) {
            return FormatValue.toNumber(value);
          }
        },
      },
      dataZoom: [
        {
          type: 'inside',
          throttle: 50
        }
      ],
      series: series
    });
    return { option, widget_value, mkeContextMenu};
  },
};
</script>

