<template>
  <div id="monitor" class="p-d-flex" >
    <PosProcessHistoryDialog/>
    <PosProcessTSEDialog/>
    <PosProcessPaymentsInfoDialog/>

    <div class="monitor-sidebar">
<!--        <Panel header="Übersicht Vorgänge">-->
          <mkeSidebar :items="processOverviewList"/>

<!--        </Panel>-->
      </div>

    <div id="content" class="p-d-flex p-flex-column p-flex-grow-1 has-sidebar ">
      <TopBar />
      <div class="p-d-flex p-flex-row p-flex-wrap p-flex-grow-1 pos-tiles">
        <Card v-for="process in processList" :key="process.id" class="process-card pos-transactions "
              :data-process-id="process.id" :data-process-state="process.state">
          <template #title>
            <div class="p-d-flex " style="gap: 0.5rem">
              <i class="order-state" :class="getStateIcon(process)"></i>
              {{process.name}}
            </div>
            <div class = "p-d-flex" style="gap: 0.5rem">
              <mke-button class="show-process-history"
                          icon="mdi mdi-history" :tooltip="t('message.pos.process_history.show_history')"
                          @click="showProcessHistory(process)"/>

              <mke-button class="show-payments"
                          icon="mdi mdi-cash-register" :tooltip="t('message.pos.show_payments')"
                          @click="showProcessPayments(process)"/>

              <mke-button class="show-tse-transactions"
                          icon="mdi mdi-certificate-outline" :tooltip="t('message.pos.tse.show_signature')"
                          @click="showProcessTSESignature(process)"/>



            </div>
          </template>
          <template #content>
              <ul class="p-orderlist p-flex-column" style="list-style: none">
                <li v-for="entry in process.process_entries" :key="entry.id" class="product-item"
                    :data-entry-id="entry.id"
                    :data-quantity="entry.quantity"
                    :data-entry-number="entry.entry_number"
                    :data-state="entry.state"
                    :data-name="entry.name"
                    :data-hint="entry.hint"
                    :data-inhouse="entry.inhouse"
                    :data-base-price="entry.base_price"
                    :data-price="entry.price"
                    :data-entry-type="entry.entry_type"
                    :data-memo="entry.memo"
                    :data-plu="entry.plu">

                  <i class="order-state mdi" :class="entry.state"></i>
                  <div class="info p-d-flex p-flex-row">
                    <span class="quantity">{{$filters.toNumberMostlyInt(entry.quantity)}}</span>
                    <div class="info">
                      <span class="name">{{entry.name}}</span><br/>
                      <span class="hint "><small><em>{{entry.hint}}</em></small></span>
                      <span class="font-size-s" v-if="entry.hint && entry.memo"><small><em>: </em></small></span>
                      <span class="memo font-size-s"><small><em>{{entry.memo}}</em></small></span>
                    </div>
                  </div>
                </li>
              </ul>
          </template>

        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import {computed} from "vue";
import {useI18n} from "vue-i18n";

export default {
  setup(){
    const store = useStore()
    store.dispatch("pos/getProcesses")

    const { t, d, n } = useI18n({ useScope: "global" });


    function getStateIcon(process) {
      let icon = "cards-heart";
      if( process.state === "new" ) {
        icon = "cart-arrow-down";
      } else if( process.state === "opened" ) {
        icon = "cart-arrow-right";
      } else if( process.state === "voided" ) {
        icon = "cart-remove";
      } else if( process.state === "in_pay" ) {
        icon = "credit-card-clock";
      } else if( process.state === "closed" ) {
        icon = "check-bold";
      }

      return 'mdi mdi-'+icon;
    }

    const processOverviewList = computed(() => {
          let items = [];
          store.getters["pos/processList"]().forEach( process => {
            items.push({
              key: process.id,
              label: process.name,
              icon: 'mdi mdi-' + getStateIcon(process),
              command: function() {
                scrollToProcess(process);
              }
            })
          });
          return items;
    } )

    const processList = computed(() => {
      return store.state.pos.processList;
    } )

    /**
     * Close the current active table
     * It gets automatically called when the window is about to unload its resources
     */
    const closePosSession = () => {
      // console.log(store);
      // store.dispatch("pos/closePosSession")
    }
    const scrollToProcess=(process) => {
      let process_card = document.querySelector("[data-process-id='" + process.id + "']");

      if( process_card ) {
        process_card.scrollIntoView({behavior: "smooth"} );
      }
    }

    const showProcessHistory = (process) => {
      store.dispatch('pos/showProcessHistory', {process_id: process.id} );
    }

    const showProcessTSESignature = (process) => {
      store.dispatch("pos/call_pos_api", {
        method: "get",
        url: "/pos/" + process.id + "/tse_signature",
        noProcessRefresh: true,
        compressed: false,
        successHandler: ({commit}, result) => {
          console.log(result);
          commit("setTseInfo", result);
        }
      });
    }

    const showProcessPayments = (process) => {
      store.dispatch("pos/call_pos_api", {
        method: "get",
        url: "/pos/" + process.id + "/payment_info",
        noProcessRefresh: true,
        compressed: false,
        successHandler: ({commit}, result) => {
          console.log(result);
          commit("setPaymentsInfo", result);
        }
      });
    }

    return {
      processOverviewList, processList, closePosSession, getStateIcon, showProcessHistory, showProcessTSESignature, showProcessPayments, t, d, n
    }

  },
  created() {
    window.addEventListener('beforeunload', this.closePosSession)
  },
};
</script>

<style lang="scss">
  .pos-tiles {
    gap: 2rem;
  }
  .order-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 3rem;

    .p-card {
      min-height: 30rem;
      max-height: 40vh;
      overflow: auto;
      min-width: max(40ch, 20%);
      max-width: min(80ch, 30%);
    }
  }

  li {
    margin-bottom: 0.5rem;
  }

  .quantity {
    display: inline-block;
    text-align: right;
    width: 3ch;
    padding-right: 1rem;
  }

  .info {
    display: inline-block;

  }
</style>