<template>
  <div id="pos__process-details" class="mx-process-details" :class="isReadonly ? 'readonly': ''" :data-readonly="isReadonly">
    <div class="mx-process-detail-list">
      <TransitionGroup name="fade-in-from-left" >
        <div v-for="processEntry in processItems" :key="processEntry.id"
             class="mx-process-detail-item" :class="[processEntry.state, processEntry.entry_type]"
             :id="'process-entry-' + processEntry.id"
             :data-quantity="processEntry.quantity"
             :data-state="processEntry.state"
             :data-name="processEntry.name"
             :data-hint="processEntry.hint"
             :data-inhouse="processEntry.inhouse"
             :data-base-price="processEntry.base_price"
             :data-price="processEntry.price"
             :data-entry-type="processEntry.entry_type"
             :data-memo="processEntry.memo"
             :data-plu="processEntry.plu">

          <div class="mx-process-detail-item-left">
            <div class="mx-process-detail-item-number">
              <mke-button v-if="processEntry.actions.void"
                          class="mx-button--void-process-entry"
                          icon="mdi mdi-minus" color="surface-low"
                          :pos-action="processEntry.actions.void"/>
              <span v-if="processEntry.quantity !== null" class="mx-process-detail-item-current-number">
                {{ $filters.toNumberMostlyInt(processEntry.quantity) }}
              </span>
              <mke-button v-if="processEntry.entry_type === 'pos_process_order_entry'"
                          class="mx-button--reorder-process-entry"
                          icon="mdi mdi-plus" color="surface-low"
                          function-call="orderPlu" :function-call-args="[processEntry.plu, 'quantity']" />
            </div>
          </div>

          <div class="mx-process-detail-item-center">
            <div class="mx-process-detail-item-name" @click="productMenu_toggle(processEntry, $event)" style="cursor: pointer">
              {{processEntry.name}}
            </div>
            <div class="mx-process-detail-item-info">
              <div class="mx-process-detail-item-info-block">
                <span v-if="processEntry.hint && processEntry.memo">
                  {{ processEntry.hint }}: {{ processEntry.memo }}
                </span>
                <span v-else-if="processEntry.memo">
                  {{ processEntry.memo }}
                </span>
                <span class="child-entries"
                     v-for="childEntry in processEntry.child_entries"
                     :key="childEntry.id"
                     :data-quantity="childEntry.quantity"
                     :data-state="childEntry.state"
                     :data-name="childEntry.name"
                     :data-hint="childEntry.hint"
                     :data-inhouse="childEntry.inhouse"
                     :data-base-price="childEntry.base_price"
                     :data-price="childEntry.price"
                     :data-entry-type="childEntry.entry_type"
                     :data-memo="childEntry.memo"
                     :data-plu="childEntry.plu"
                     :data-child-entries="childEntry.child_entries?.map((c)=>c.name)?.join(', ')">
                  {{childEntryText(childEntry)}}
                </span>
              </div>
            </div>
          </div>
          <div class="mx-process-detail-item-right">
            <span class="mx-process-detail-item-takeaway" v-if="processEntry.actions.outhouse">
              <i class="mdi mdi-15x mdi-home-export-outline mke-ml-2"/>
            </span>
            <span class="mx-process-detail-item-price" v-if="processEntry.price !== null">
              {{ n(processEntry.price, 'currency') }}
            </span>
          </div>
        </div>
      </TransitionGroup>
    </div>
    <div class="mx-process-details-footer">
      <div class="mx-process-details-payments" v-if="activeProcess?.payments">
        <div v-for="payment in activeProcess.payments" :key="payment.id"
             class="mx-process-details-payment-info font-size-s bold"
             :class="'mx-process-details-payment-info-' + payment.business_case"
             :data-price="payment.price"
             :data-given="payment.given"
             :data-tip="payment.tip"
             :data-returned="payment.returned"
              >
          <Divider type="dashed" class="mke-m-2"/>
          <span>
            *** {{t('message.pos.payment.payment_types.' + payment.business_case)}}
            <small>{{d(payment.timestamp_end, 'datetime_short')}}</small>
          </span>
          <div class="p-d-flex p-jc-between">
            <span>Bar</span><span>{{ n(payment.price, 'currency') }}</span>
          </div>
          <div class="p-d-flex p-jc-between" v-if="payment.given">
            <span>Gegeben</span><span>{{ n(payment.given, 'currency') }}</span>
          </div>
          <div class="p-d-flex p-jc-between" v-if="payment.tip">
            <span>TIP</span><span>{{ n(payment.tip, 'currency') }}</span>
          </div>
          <div class="p-d-flex p-jc-between" v-if="payment.returned">
            <span>Rückgeld</span><span>{{ n(payment.returned, 'currency') }}</span>
          </div>

        </div>
        <div class="mx-process-details-payment-info mx-process-details-payment-info-total font-size-s bold"
             v-if="activeProcess?.payed > 0 && activeProcess.state !== 'closed'"
             :data-payed="activeProcess.payed"
             :data-open="activeProcess.total - activeProcess.payed">
          <Divider type="dashed" />
          <div class="p-d-flex p-jc-between">
            <span>{{t('message.pos.payment.payed')}}</span><span>{{ n(activeProcess.payed, 'currency') }}</span>
          </div>
          <div class="p-d-flex p-jc-between" >
            <span>{{t('message.pos.payment.not_payed')}}</span><span>{{ n(activeProcess.total - activeProcess.payed, 'currency') }}</span>
          </div>
        </div>
      </div>

      <div class="mx-process-details-payments" v-if="cashPaymentStore.anyValueGiven">
        <div class="mx-process-details-payment-info mx-process-details-payment-info-current font-size-s bold"
             :data-given="cashPaymentStore.givenAmount"
             :data-tip="cashPaymentStore.tipAmount"
             :data-returned="cashPaymentStore.returnAmount">
          <Divider type="dashed" />

          <div class="p-d-flex p-jc-between" :class="{invalid: cashPaymentStore.givenInvalid}">
            <span>Gegeben</span><span>{{ n(cashPaymentStore.givenAmount, 'currency') }}</span>
          </div>
          <div class="p-d-flex p-jc-between" :class="{invalid: cashPaymentStore.targetInvalid}" v-if="cashPaymentStore.targetAmount > 0">
            <span>{{ t('message.pos.payment.' + cashPaymentStore.lastTarget) }}</span><span>{{ n(cashPaymentStore.targetAmount, 'currency') }}</span>
          </div>
          <div class="p-d-flex p-jc-between" v-if="cashPaymentStore.tipAmount" :class="{invalid: cashPaymentStore.tipInvalid}">
            <span>TIP</span><span>{{ n(cashPaymentStore.tipAmount, 'currency') }}</span>
          </div>
          <div class="p-d-flex p-jc-between" v-if="cashPaymentStore.returnAmount" >
            <span>Rückgeld</span><span>{{ n(cashPaymentStore.returnAmount, 'currency') }}</span>
          </div>
        </div>
      </div>

      <Divider type="dashed" />
      <div class="mx-process-details-footer-inner">
        <div class="mx-process-details-footer-left">
            <InputSwitch
                :data-compressed="compressed"
                class="mke-mr-2 mke-switch-process-compressed"
                id="compress"
                v-model="compressed"
            />
            <label for="compress">
              {{compressed === true ? t('message.pos.compressed') : t('message.pos.uncompressed')}}
            </label>
        </div>
        <div class="mx-process-details-footer-right">
          <span class="mx-process-details-price-total">
            {{ n(activeProcess?.total ?? 0, 'currency') }}
          </span>
        </div>
      </div>
    </div>
    <PosNumpad id="pos__keyboard" numpadStore="pos-main-keyboard-display"/>

    <Menu
        ref="productMenu"
        id="pos-transactions-context-menu"
        :model="productMenuItems"
        :popup="true"
        @click="productMenu_toggle(null, $event)">
      <template #item="{item}">
        <!-- if link/button -->
        <mke-button
            :id="item.id"
            v-if="item.ifCondition"
            :type="item.type"
            :label="item.label"
            :icon="item.icon"
            class="p-menuitem-link block text-left w-100 font-size-xs p-d-flex p-ai-center"
            :color="item.color"
            v-model="item.action"
            :posAction="item.posAction"
            :selected="item.selected"
        >
        </mke-button>
      </template>
    </Menu>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";
import {useI18n} from "vue-i18n";
import {useCashPaymentStore} from "@/store/pinia/pos/CashPaymentStore";
import {toDateTime} from "../../utilities";

export default {
  methods: {toDateTime},
  props: ["id", "numpadStore", "collapsed", "h"],
  setup() {
    const store = useStore();
    const cashPaymentStore = useCashPaymentStore();

    const {d, t, n} = useI18n({useScope: 'global'})

    const posProcessDetailsExpanded = computed(() => {
      return store.state.pos.processDetailsExpanded;
    });

    const activeProcess = computed(() => {
      return store.state.pos.activeProcess;
    });
    const processItems = computed(() => {
      if (store.state.pos.activeProcess != null) {
        return store.state.pos.activeProcess.process_entries;
      }
      return [];
    });

    const inhouse = computed({
      get() {
        if (store.state.pos.activeProcess) {
          return store.state.pos.activeProcess.inhouse;
        } else {
          return undefined;
        }
      },
      set(inhouse) {
        store.dispatch("pos/updateProcess", {
          process: store.state.pos.activeProcess,
          values: { inhouse: inhouse },
        });
      },
    });

    const compressed = computed({
      get() {
        return store.state.pos.compressed;
      },
      set(compressed) {
        store.dispatch("pos/setCompressed", compressed);
      },
    });

    const isReadonly = computed( () => !["new", "opened"].includes(store.state.pos.activeProcess?.state));

    // process context menu
    const productMenu = ref();
    const productMenuItems = ref([]);
    const productMenu_toggle = (entry, event) => {
      if( entry ) {
        productMenuItems.value = productMenu_items (entry);
      } else {
        productMenuItems.value = [];
      }
      productMenu.value.toggle(event);
    };
    const productMenu_items = (entry) => {
      const menuItems = [
        {
          id: "saletype",
          type: "switch",
          label: t('message.pos.process_inhouse'),
          icon: "mdi mdi-forest",
          color: "info",
          ifCondition: entry.actions.outhouse || entry.actions.inhouse,
          posAction: entry.actions.outhouse ? entry.actions.outhouse : entry.actions.inhouse,
          selected: entry.actions.outhouse ? false : true
        },
        {
          id: "void-product",
          type: "link",
          label: t('message.pos.void'),
          icon: "mdi mdi-trash-can",
          color: "danger",
          ifCondition: entry.actions.void,
          posAction: entry.actions.void,
        },
        {
          id: "history",
          type: "link",
          label: t('message.pos.process_history.show_history'),
          icon: "mdi mdi-history",
          ifCondition: "true",
          posAction: entry.actions.history
        },
      ];

      return menuItems;
    };

    const childEntryText = (childEntry) => {
      let entryText = "";
      if( childEntry.quantity > 1 ) {
        entryText += childEntry.quantity + "x ";
      }
      entryText += childEntry.name;
      if( childEntry.child_entries && childEntry.child_entries.length > 0 ) {
        const childDetails = " | " + childEntryDetails(childEntry.child_entries);
        entryText += childDetails
      }
      if( childEntry.price !== null && childEntry.price > 0 ) {
        entryText += " " + n(childEntry.price, 'currency');
      }
      return entryText;
    }

    const childEntryDetails = (childEntries) => {
      let details = [];
      if( childEntries && childEntries.length > 0 ) {
        details = childEntries.map( c => {
          let detail = c.name;
          let childDetails = childEntryDetails(c.childEntries);
          if( childDetails ) {
            detail += "(" + childDetails + ")";
          }
          return detail;
        })
      }

      return details.join(" | ");
    }

    return {
      t,
      n,
      d,
      activeProcess,
      processItems,
      inhouse,
      compressed,
      posProcessDetailsExpanded,
      productMenu,
      productMenu_toggle,
      productMenu_items,
      productMenuItems,
      childEntryText,
      childEntryDetails,
      isReadonly,
      cashPaymentStore
    };
  },
};
</script>
