<template>
  <div class="mx-querybuilder-rule mx-querybuilder-rule-container">
   <div class="mx-querybuilder-rule-field">
     <div class="p-d-flex p-ai-center p-jc-start gap-2">
        <Dropdown v-model="compQuery.field" :options="dataSource" @change="fieldChanged" optionLabel="label"
                  optionValue="field"
                  :class="{'p-invalid':query_validator.field.$invalid }"/>
        <div v-if="compQuery.field">
          <Dropdown v-model="compQuery.operator" :options="operators[compQuery.type]" @change="operatorChanged"
                    optionLabel="name" optionValue="id"
                    :class="{'p-invalid':query_validator.operator.$invalid }"/>
        </div>

        <div v-if="compQuery.type === 'string'">
          <div>
            <div v-if="compQuery.operator === 'equal' || compQuery.operator === 'notequal' ">
              <Dropdown v-model="compQuery.value" :options="valueOptions" optionLabel="label" optionValue="value"
                        :filter="true" :showClear="true" @filter="onFilter"
                        :resetFilterOnHide="true" @show="showDropdown"
                        v-bind:filterPlaceholder="t('message.dashboard.select_widget_template')"
                        :class="{'p-invalid':query_validator.value.$invalid }"/>
            </div>

            <div v-else-if="compQuery.operator === 'in' || compQuery.operator === 'notin'">
              <MultiSelect v-model="compQuery.value" :options="valueOptions" optionLabel="label" optionValue="value"
                            display="chip"
                           :showClear="true" @filter="onFilter"
                           :resetFilterOnHide="true" @show="showDropdown"
                           :filter="true" :class="{'p-invalid':query_validator.operator.$invalid }"/>
            </div>
            <div v-else>
              <InputText type="text" v-model="compQuery.value"
                         :class="{'p-invalid':query_validator.value.$invalid }"/>
            </div>

          </div>
        </div>
        <div v-if="compQuery.type === 'number'">
          <div v-if="compQuery.operator ==='between' || compQuery.operator ==='notbetween'">
            <div v-for="(_, index) in compQuery.value" v-bind:key="index">
              <InputNumber inputId="minmax-buttons" v-model="compQuery.value[index]" mode="decimal" showButtons
                           :min="compQuery.validation.min" :max="compQuery.validation.max"
                           :class="{'p-invalid':query_validator.value.$invalid }"/>
            </div>
          </div>
          <div v-else>
            <div>
              <InputNumber inputId="minmax-buttons" v-model="compQuery.value" mode="decimal" showButtons
                           :min="compQuery.validation.min" :max="compQuery.validation.max"
                           :class="{'p-invalid':query_validator.value.$invalid }"/>
            </div>
          </div>
        </div>
        <div class="p-d-flex p-ai-center gap-2" v-if="compQuery.type === 'boolean'">
          <div class="p-d-flex p-ai-center">
            <RadioButton inputId="rule1" name="true"  v-model="compQuery.value" v-bind:value="true" />
            <label class="mke-ml-1" for="rule1">{{ t("message.dashboard.queryBuilder.true") }}</label>
          </div>
          <div class="p-d-flex p-ai-center">
            <RadioButton inputId="rule2" name="false"  v-model="compQuery.value" v-bind:value="false" />
            <label class="mke-ml-1" for="rule2">{{ t("message.dashboard.queryBuilder.false") }}</label>
          </div>
        </div>
        <div v-if="compQuery.type === 'date'">
          <div v-if="compQuery.operator ==='between' || compQuery.operator ==='notbetween'">
            <div v-for="(_, index) in compQuery.value" v-bind:key="index">
              <Calendar inputId="icon" v-model="compQuery.value[index]"
                        :dateFormat="t('message.dashboard.queryBuilder.primeDateFormat')" :showIcon="true"
                        :class="{'p-invalid':query_validator.value.$invalid }"/>
            </div>
          </div>
          <div v-else>
            <div>
              <Calendar inputId="icon" v-model="compQuery.value"
                        :dateFormat="t('message.dashboard.queryBuilder.primeDateFormat')" :showIcon="true"
                        :class="{'p-invalid':query_validator.value.$invalid }"/>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="delete">
        <slot name="deleteRule"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import operators from "@/components/dashboard/querybuilder/utils/queryBuilderOperators";
import {queryBuilderWrapper} from "@/components/dashboard/querybuilder/utils/queryBuilderWrapper";
import {required} from "@vuelidate/validators";
import {ref, computed} from 'vue'
import {useVuelidate} from "@vuelidate/core";
import {useI18n} from 'vue-i18n';
import deepClone from "@/utilities";
import moment from 'moment';
import {useStore} from "vuex";

moment().format();
export default {
  props: ["query", "index", "labels", "dataSource", "cube"],
  components: {},
  setup(props, {emit}, context) {
    const store = useStore()
    const compQuery = queryBuilderWrapper(props, emit, 'query')
    const {t} = useI18n({useScope: 'global'})
    // query Vue Validate Rules
    const queryRules = {
      field: {required},
      operator: {required},
      value: {required},
    };

    const query_validator = useVuelidate(queryRules, compQuery);
    const filterValue = ref('')
    const inAndNotIn = () => {
      return compQuery.value.operator === "in" || compQuery.value.operator === "notin";
    }
    const equalAndNotEqual = () => {
      return compQuery.value.operator === "equal" || compQuery.value.operator === "notequal";
    }

    const mutateOptions = () => {
      if (filterValue.value.length === 0) {
        if (equalAndNotEqual()) {
          return [{label: compQuery.value.value, value: compQuery.value.value}]
        } else if (inAndNotIn() && Array.isArray(compQuery.value.value)) {
          return compQuery.value.value.map(item => ({label: item, value: item}))
        }
      } else return store.state.query_builder.query_builder_value_options[props.cube][compQuery.value.field]['options']
    }

    const valueOptions = computed({
      get: () => {
        return mutateOptions()
      },
      set: () => {
        if (filterValue.value.length < 3) {
          store.commit("query_builder/reset_options", {cube: props.cube, field: compQuery.value.field})
        }
      }
    })

    const loadOptions = () => {
      let operators = ['in', 'notin', 'equal', 'notequal']
      let operator = compQuery.value.operator
      if (compQuery.value.field) {
        if (operators.includes(compQuery.value.operator)) {
          operator = 'contains'
        }
        store.dispatch("query_builder/get_value_options", {
          model: "dataframe_filters",
          cube: props.cube,
          name: compQuery.value.field,
          operator: operator,
          offset: 0,
          limit: 20,
          filter: filterValue.value
        })
      }
    };

    const onFilter = async (value) => {
      filterValue.value = value.value
      valueOptions.value = []
      if (inAndNotIn()) {
        compQuery.value.value = []
      }
      if (filterValue.value.length >= 3)
        loadOptions()
    };

    const showDropdown = () => {
      if (filterValue.value.length >= 3) {
        loadOptions()
      }
    };

    const getRule = (ruleId) => {
      let rule = null;
      props.dataSource.forEach(function (value) {
        if (value.field === ruleId) {
          rule = deepClone(value)
          return rule
        }
      })
      return rule;
    }
    const fieldChanged = (field) => {
      if (props.dataSource) {
        let rule = {}
        rule = getRule(field.value)
        if (rule.type === "string")
          rule.operator = "startswith"
        else {
          rule.operator = "equal"
        }
        compQuery.value = rule
      }
    }

    const operatorChanged = (operator) => {
      // reset all values
      store.commit("query_builder/reset_options", {cube: props.cube, field: compQuery.value.field})
      filterValue.value = ''
      compQuery.value.value = ''
      if (inAndNotIn()) {
        compQuery.value.value = []
      }

      let rule = getRule(compQuery.value.field)
      if (operator.value === 'between' || operator.value === 'notbetween') {
        if (!Array.isArray(compQuery.value.value)) {
          if (compQuery.value.type === "number")
              /*default number value*/
            compQuery.value.value = [1, 1]
          else
              /*default date value*/
            compQuery.value.value = [moment(new Date(new Date()), "DD/MM/YYYY").format("DD.MM.YYYY")
              , moment(new Date(new Date()), "DD/MM/YYYY").format("DD.MM.YYYY")]
        }

      } else {
        if (Array.isArray(compQuery.value.value)) {
          rule.operator = operator.value
          compQuery.value = rule
        }
      }
    }

    const deleteRule = () => {
      context.emit("delete_child", props.index);
    }
    return {
      onFilter,
      deleteRule,
      showDropdown,
      operators,
      fieldChanged,
      operatorChanged,
      compQuery,
      query_validator,
      valueOptions,
      t
    }
  },
}
</script>
