<template>
  <MxApp :app="app">

    <PosNotInitialized/>
    <PosProcessListDialog/>
    <PosProcessVoidDialog/>
    <PosProcessComboDialog/>
    <PosProductInfoDialog/>
    <PosProcessHistoryDialog/>
    <PosProcessPaymentDialog/>
    <PosProcessSplitDialog/>
    <PosProcessTSEDialog/>
    <PosProcessPaymentsInfoDialog/>

    <!-- Möglich sind: outer-right-hand  / inner-right-hand  / simple-right-hand / standard-right-hand + für alle die left-hand Variante-->
    <div id="pos" class="mx-grid leave-page " :class="layoutCssClass">
      <!-- Topbar -->
      <PosTopBar class="fade"/>
      <!-- Sidebar -->
      <div id="pos__sidebar" class="mx-sidebar fade delay-1">
        <div class="mx-sidebar-top">
          <div class="mx-sidebar-logo-wrapper">
            <MxLogoSidebar class="mx-sidebar-logo" />
          </div>
          <PosProcessSwitcher/>
        </div>
        <div class="mx-sidebar-bottom"></div>
      </div>

      <PosProcessDetails class="fade delay-2"/>

      <PosActionPanel id="pos__column-a" target="function_a" class="fade delay-3"/>

      <PosProductExplorer id="pos__product-explorer" class="fade delay-4"/>

      <PosActionPanel id="pos__functions" target="main_function" class="fade delay-5"/>

      <PosActionPanel id="pos__column-b" target="function_b" class="fade delay-6"/>

      <PosChangeTableofProcess/>

      <!-- Footer -->
      <div id="pos__footer" class="mx-footer fade delay-7">
        <div class="mx-footer-left"></div>
        <div class="mx-footer-center"></div>
        <div class="mx-footer-right">
          <span class="mx-footer-version">ver. 0.42</span>
          <!-- <MxLogoFooter /> -->
        </div>
      </div>
    </div>
  </MxApp>
</template>

<script>
import {useStore} from "vuex";
import {ref, onBeforeUnmount, onBeforeMount, computed} from "vue";
import PosChangeTableofProcess from "@/components/pos/split/PosChangeTableofProcess";
import axios from "axios";

export default {
  components: {PosChangeTableofProcess},
  props: ["app"],
  setup() {
    const store = useStore();
    const processDetailsExpanded = ref(false);

    const layoutCssClass = computed( () => store.getters["pos/layoutCssClass"]());

    window.setTimeout(() => {
      store.dispatch("pos/getOpenProcesses")
    }, 50);

    function togglePosProcessExpanded() {
      processDetailsExpanded.value = !processDetailsExpanded.value;
    }

    /**
     * This function is called in three different scenarios:
     * a) The user is logged out (either via logout or withdrawn of the "Kellnerschlüssel"
     *      -> we need to react on the store action "general/logout"
     * b) The user refreshs the page
     *      -> we need to react on browsers "beforeunload" event
     * c) The user switches to another page
     *      -> we need to react on vue's beforeUnmount-Hook
     *
     * The function calls the posservice to close the session, which means
     * the current process will be in an appropriate state and open orders are ordered.
     * Because the axios called is delayed and the store has already cleared the jwt-token,
     * we need to add it directly to the axios request.
     */
    function closePos() {
      if( store.state.pos.activeProcess && store.state.general.jwtToken) {
        const requestConfig = {
          url: config.PosServiceUrl + "/pos/" + store.state.pos.activeProcess.id + "/close_session",
          method: "put",
          headers: {
            Authorization: "Bearer " + store.state.general.jwtToken
          }
        }
        axios.request(requestConfig);
      }
    }

    // Subscribes to the action "general/logout", to close the POS and
    // also stores the handler to unsubscribe it, after unmounting.
    const unsubscribeActions = store.subscribeAction((action) => {
      if( action.type === "general/logout" ) {
        closePos();
      }});

    // We need to register the closing of the POS to the window event
    // in case, the user reloads the page
    onBeforeMount( () => {
      window.addEventListener("beforeunload", closePos);
    });

    // We need to close the POS, if we redirect to other pages
    onBeforeUnmount(() => {
      closePos();
      if( unsubscribeActions ) {
        unsubscribeActions();
      }
      window.removeEventListener("beforeunload", closePos);
    })

    return {processDetailsExpanded, togglePosProcessExpanded, layoutCssClass};
  }
};
</script>
