<template>
  <div>
    <Dialog
        id="split-dialog"
        :modal="true"
        v-model:visible="splitDialogVisible"
        :closable="false"
        :style="{width: '90vw'}">
      <template #header>
        <h5>Split von {{splitStore.sourceProcess.name}}</h5>
      </template>
        <PosProcessSplitEntries id="split-process"
                                :numpadState="numpadState"
                                @numpad:consumed="numpadConsumed"/>
        <Divider layout="vertical"/>

        <div class="p-d-flex p-flex-column p-jc-between">
          <div>
            <!--<div class="font-size-16 mke-mb-2 mx-fg-text-medium">weiteren Split hinzufügen</div> -->
            <mke-button
              icon="mdi mdi-format-list-numbered"
              iconPos="center"
              label="Vorgangsliste"
              color=""
              size="lg"
              class="w-100 p-button-lg"
              @click="openProcessList()"
          />
          </div>
        <PosNumpadSplit id="pos-process-split-numpad" ref="numpad" :value="numpadState"
                                 @numpad:change="numpadChanged" @numpad:create="createProcess"/>
        </div>
      
      <template #footer>
        <mke-button id="button-payment-cancel" label="Abbrechen" icon="pi" size="lg" @click="closeDialog" class=""/>
        <mke-button id="button-payment-commit" label="Bestätigen" icon="pi" color="primary" size="lg" @click="commitSplit" />
      </template>
    </Dialog>
    <PosProcessSelectDialog :id="processSelectDialogId" title="Bitte wählen Sie ein Ziel für den Split von Vorgang " @process:selected="createProcess" />
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, ref} from "vue";
import deepClone from "../../../utilities";
import {useSplitEntriesStore} from "@/store/pinia/pos/SplitEntriesStore";

export default {
  name: "PosProcessSplitDialog",
  setup(){
    const store = useStore();

    const splitDialogVisible = computed( () => store.state.pos.splitAction !== undefined );
    const processSelectDialogId = "process-select-for-split-dialog";

    const numpad = ref(null);
    const numpadState = ref({value:0, text: '', all: false});

    const splitStore = useSplitEntriesStore();


    /**
     * Open the process list to select another process to split into
     */
    function openProcessList() {
        store.commit('pos/setSelectProcessVisibleId', 'process-select-for-split-dialog');
    }

    function numpadChanged(state) {
      numpadState.value = state;
    }

    function numpadConsumed() {
      numpad.value.consumed();
    }

    function createProcess(newProcess) {
      if( newProcess.name === "" ) {
        newProcess.name = "Vorgang " + (splitStore.processesForSplit.length + 1);
      }
      newProcess = deepClone(newProcess);

      splitStore.addSplit(newProcess);

      store.commit("pos/setSelectProcessVisibleId", undefined);
    }

    const commitSplit = () => {
      const processesForSplit = splitStore.getAllSplitInfos();

      store.dispatch("pos/splitProcess", {
          splitInfo: {
            process_id: store.state.pos.splitAction.process.id,
            processes_to_split: processesForSplit
          },
          successCallback: () => {
            store.commit("general/addToast", {
              detail: "Der Split wurde durchgeführt",
              life: 3000,
              severity: "success",
              summary: "Info"
            } );
            store.commit('pos/setSplitAction', undefined);
          }
        });
    };

    const closeDialog = () => {
      store.dispatch("pos/cancelSplit", {
        splitInfo: {
          process_id: store.state.pos.splitAction.process.id
        },
        successCallback: () => {
          store.commit("general/addToast", {
            detail: "Der Split wurde abgebrochen",
            life: 3000,
            severity: "error",
            summary: "Info"
          });
        }
      });
    }

    return {
      splitDialogVisible, commitSplit, numpad, numpadChanged, numpadConsumed, numpadState, openProcessList, createProcess, processSelectDialogId, closeDialog, splitStore
    }
  }
}

</script>

<style scoped>

</style>