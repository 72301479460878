<template>
  <div id="home" class="mx-grid leave-page">
    <!-- Topbar -->
    <MxTopBar class="fade"/>
    <!-- <TopBar class="fade" /> -->

    <div id="content" class="mx-home-content">
      <div class="p-grid">
        <div class="p-col-12">
          <div id="mke-title-wrapper" class="fade-in-from-bottom delay-2">
            <h1 v-if="activeUser">{{ t('message.home.hello') }} {{activeUser.user_name}}</h1>
            <h3></h3>
          </div>
        </div>
        <div class="p-col-8">
            <div class="mx-app-grid p-d-flex p-flex-wrap gap-md-3 gap-xl-4">
              <mx-app-icon v-for="(app, index) in apps" :key="index" :app="app"/>
            </div>
        </div>

         <div class="p-col-4">
            <div class="mx-app-grid p-d-flex p-flex-column p-jc-between p-jc-md-start gap-md-2">
              <!-- MX System App Card -->
              <mx-settings-app-icon v-for="(app, index) in settings" :key="index" :app="app"/>
            </div>
        </div>
      </div> 
    </div>

    <!-- Footer -->
    <div id="home__footer" class="mx-footer fade-in-from-bottom delay-5">
      <div class="mx-footer-left"></div>
      <div class="mx-footer-center"></div>
      <div class="mx-footer-right">
        <span class="mx-footer-version">ver. 0.42</span>
        <!-- <MxLogoFooter /> -->
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n'

export default {
  name: "Home",
  setup() {
    const apps = computed( () => store.state.general.apps.filter((a) => a.app_type === 'app' && a.enabled));
    const activeApp = computed( () => store.state.general.activeApp)
    const settings = computed( () => store.state.general.apps.filter((a) => a.app_type === 'settings' && a.enabled));
    const { t } = useI18n({ useScope: 'global' })
    const store = useStore();
    const activeUser = computed(() => store.state.general.activeUser)

    return { activeUser, apps, activeApp, settings, t };
  }
};
</script>