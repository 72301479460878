<template>
  <div id="dashboard">
    <dsh_sidebar/>
    <div id="content" class="has-sidebar">
      <div class="mke-background-gradient-element"/>
      <dsh_topbar/>
      <mkeCrud v-if="main_view === 'dashboards' && !openStandardDashboard" store="dashboard" :create="true" />
      <mkeCrud v-if="main_view === 'dashboard_categories'" store="dashboard" :create="true"/>
      <DshConfig v-if="main_view === 'config'"/>
      <mkeCrud v-if="main_view === 'widget_templates'" store="dashboard" title="Widget Templates" :create="false"/>
      <div class="p-grid mke-pt-5"/>
    </div>
  </div>
</template>

<style lang="scss">

</style>

<script>
import DshConfig from "../components/dashboard/dsh_config"
import {computed, onMounted, onUnmounted, ref} from "vue";
import {useStore} from "vuex";
import {dashboardsocket} from "../store/index"
export default {
  components: {
    DshConfig
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      dashboardsocket.connect()
    })
    onUnmounted(() => {
      dashboardsocket.disconnect()
      store.commit('dashboard/reset_state')
    })
    const openStandardDashboard = ref(!!store.state.general.activeUser?.default_dashboard_id)

    store.watch( () => store.state.dashboard.dashboards,
    (newVal) => {
      if (newVal){
        if (openStandardDashboard.value && store.state.general.activeUser?.default_dashboard_id) {
          if( newVal[store.state.general.activeUser?.default_dashboard_id] ) {
            store.commit('dashboard/set_active_dashboard', store.state.general.activeUser.default_dashboard_id)
            store.commit("dashboard/set_dashboard_visible", true)
            store.dispatch('dashboard/calculate_dashboard')
          }
          openStandardDashboard.value = false
        }
      }
    })
    const main_view =  computed(() => store.getters["dashboard/get_main_view"]())
    store.dispatch("dashboard/getAllData", {field: '', model: 'widget_templates'})
    return {main_view, openStandardDashboard};
  },

};
</script>

<style lang="scss">
#dashboard {
  .mke-logout {
    margin-top: 60px;
  }
}

</style>

