<template>
  <div class="p-grid p-ai-center mke-px-0">
    <div v-if="label" class="p-col-4 mke-p-0">{{ label }}</div>
    <div class="p-col-8 mke-p-0">
      <InputNumber
          :id="id"
          v-model="value"
          @input="$emit('update:modelValue', $event.value)"
          :placeholder="placeholder"
          :currency="currency"
          :min-fraction-digits="minFractionDigits"
          :max-fraction-digits="maxFractionDigits"
          :use-grouping="useGrouping"
          :mode="mode || 'decimal'"
          :disabled="readonly || false"
          class="block"
          :class="{'p-invalid':invalid}"
      >
      </InputNumber>
    </div>
  </div>
<!--  <InputNumber id="currency-germany" v-model="value10" @input="$emit('update:modelValue', $event.value)" mode="currency" currency="EUR" locale="de-DE" />-->
</template>

<script>
import {toRef} from "vue";

export default {
  name: "mkeInput",
  props: ["id", "currency", "label", "mode", "modelValue", "readonly", "placeholder", "minFractionDigits", "maxFractionDigits", "useGrouping", "invalid"],
  emits: ['update:modelValue'],
  setup(props){
    const value = toRef(props, 'modelValue');
    return { value }
  }
}
</script>

<style scoped>

</style>