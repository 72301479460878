export default function createDashboardSecurityPlugin() {
    return store => {
        store.subscribe((mutation, state) => {
            if (mutation.type === "general/setActiveUser") {
                store.commit('dashboard/set_dashboard_rights', state.general.activeUser)
            }
        });
    };
}

