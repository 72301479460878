<template>

  <PosProcessSelectDialog id="process-select-for-table-change-dialog" title="Tischwechsel von "  @process:selected="switchTable" @process:canceled="close" />
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: "PosChangeTableofProcess",
  setup() {
    const store = useStore()
    const activeProcess = computed(() => store.state.pos.activeProcess);
    const changeTableVisible = computed( () => store.state.pos.changeTableVisible);

    const switchTable = (process) => {
      console.log( "-> process:selected", process);

      store.dispatch("pos/updateProcess", {
        process: store.state.pos.activeProcess,
        values: { name: process.name }
      });
      close();
    }

    const close = () => {
      store.commit("pos/setSelectProcessVisibleId", undefined);
      store.commit("pos/setChangeTableVisible", false);
    }

    return {activeProcess, switchTable, changeTableVisible, close}
  }
}
</script>

<style scoped>

</style>