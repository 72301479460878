<template>
  {{}}
</template>

<script>
import { inject} from "vue";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
// import dummy_data from '../json/dummy_data.json';

export default {
  setup() {
    const store = useStore()
    const signalr = inject("signalr")
    const toast = useToast();
    signalr.on("got_processes", (IncomingEvent) => {
      for (let pos_process of JSON.parse(IncomingEvent)){
        // TODO: Temporary until the PosService sends the correct ProcessId
        pos_process['ProcessId'] = pos_process['Process']['ProcessId']
        store.commit('pos_process/set_process', pos_process)
      }
    });
    signalr.on("got_waiters", (IncomingEvent) => {
      store.commit('waiters/set_waiters', JSON.parse(IncomingEvent))
    });
    signalr.on("got_families", (IncomingEvent) => {
      store.commit('articles/set_families', JSON.parse(IncomingEvent))
    });
    signalr.on("got_familygroups", (IncomingEvent) => {
      store.commit('articles/set_familiegroups', JSON.parse(IncomingEvent))
    });
    signalr.on("got_articles", (IncomingEvent) => {
      store.commit('articles/set_articles', JSON.parse(IncomingEvent))
    });
    signalr.on("terminal_error", (IncomingEvent) => {
      console.log("termerr", IncomingEvent)
    });
    signalr.on("waiter_error", (IncomingEvent) => {
      toast.add({severity:'error', summary: 'Login Failed', detail:JSON.parse(IncomingEvent).Reason, life: 3000});
    });
    signalr.on("terminal_logged_in", (IncomingEvent) => {
      console.log("term", IncomingEvent)
      store.commit('waiters/set_active_terminal', JSON.parse(IncomingEvent).Id)
    });
    signalr.on("waiter_logged_in", (IncomingEvent) => {
      console.log("waiter login", IncomingEvent)
      store.commit('waiters/set_active_waiter', JSON.parse(IncomingEvent).Id)
    });
    signalr.on("waiter_logged_out", (IncomingEvent) => {
      console.log("waiter logout", IncomingEvent)
      store.commit('waiters/set_active_waiter', null)
    });
    signalr.on("table_opened", (incomingEvent) => {
      incomingEvent = JSON.parse(incomingEvent)
      incomingEvent['OrderLines'] = incomingEvent.Actions
          .filter(x => x.Type == 1)
          .map(x => x.Payload.OrderLines)
          .filter(x => x != undefined)
          .flat()
      store.commit('pos_process/set_process', incomingEvent)
      store.commit('pos_process/switch_process', incomingEvent.ProcessId)
    });
    signalr.on("table_error", (IncomingEvent) => {
      console.log("table_error", IncomingEvent)
    });
    signalr.on("table_closed", (IncomingEvent) => {
      console.log("table_closed", IncomingEvent)
    });
    signalr.on("ordered", (IncomingEvent) => {
      IncomingEvent = JSON.parse(IncomingEvent)
      for (let orderLine of IncomingEvent.Payload['OrderLines']){
        orderLine['ProcessId'] = IncomingEvent.Payload.ProcessId
        store.commit('pos_process/set_order_item', orderLine)
      }
    });
    signalr.on("committed", (incomingEvent) => {
      console.log("Order Committed", JSON.parse(incomingEvent))
    });
    signalr.on("order_error", (IncomingEvent) => {
      console.log("order_error", IncomingEvent)
    });
    // async function start() {
    //   try {
    //     await signalr.start();
    //     let get_waiters = { Terminal: store.state.waiters.active_terminal}
    //     signalr.send("get_waiters", JSON.stringify(get_waiters))
    //     let get_families = { Terminal: store.state.waiters.active_terminal}
    //     signalr.send("get_families", JSON.stringify(get_families))
    //     let get_familygroups = { Terminal: store.state.waiters.active_terminal}
    //     signalr.send("get_familygroups", JSON.stringify(get_familygroups))
    //     let get_articles = { Terminal: store.state.waiters.active_terminal}
    //     signalr.send("get_articles", JSON.stringify(get_articles))
    //     // TODO: Replace dummy data loading with a layout loading signalr request
    //     // store.commit('layout/set_keyboard', {schemaName: "keyboard", keyboard: dummy_data['keyboard']})
    //     store.commit('layout/setLayout', dummy_data['layout'])
    //   } catch (err) {
    //     console.log(err);
    //     setTimeout(start, 5000);
    //   }
    // }
    // start();
    return {}
  }
}
</script>
