<template>
  <div class="p-grid mke-pt-5">
    <div class="p-col-12 p-d-flex p-align-center p-jc-between p-flex-column p-flex-md-row gap-4 mke-pb-0">
      <div id="mke-title-wrapper">
        <h1 class="display-small">{{ dashboard_name }}</h1>
      </div>
      <template v-if="edit_dashboard">
        <div class="dsh-actions p-d-flex p-jc-between gap-4">
        <Button
            icon="mdi mdi-plus"
            class="p-button-primary"
            :label="t('message.dashboard.addWidget')"
            @click="createWidget()"
        />

        <div class="p-d-flex gap-2">
          <mq-responsive target="lg+">
            <mkeButton color="warning" icon="mdi mdi-lock-open-outline" type="submit" @click="lock_gridstack()" v-if="unlock == true" v-tooltip.bottom="'Editier-Modus beenden'">
            </mkeButton>
            <mkeButton color="transparent" icon="mdi mdi-lock" type="submit" @click="lock_gridstack()" v-if="unlock == false" v-tooltip.bottom="'Editier-Modus starten'">
            </mkeButton>
          </mq-responsive>

          <Dialog :header="t('message.dashboard.addWidget')" v-model:visible="create_widget_dialog_visible" :modal="true"
                  :breakpoints="{'1800px': '55vw','1440px': '80vw', '960px': '90vw' }"
                  :style="{width: '45vw'}" @hide="create_widget_dialog_hide">
            <form @submit.prevent="">
                <template>
                  <span>New Widget</span>
                </template>
                <!-- Widget Title Text -->
                <div class="p-d-flex p-flex-column gap-4">
                  <!-- Widget Template Selector-->
                  <div class="p-grid p-ai-center">
                    <div class="p-col-4 mke-p-0">{{ t('message.dashboard.widgetTemplate') }}</div>
                    <div class="p-col-8 mke-p-0">
                      <Dropdown class="block" type="text" v-model="widget_dialog_template_selector" optionLabel="name" optionValue="widget_json"
                                :options="options_widget_template"  v-bind:placeholder="t('message.dashboard.select_widget_template')" :filter="true" v-bind:filterPlaceholder="t('message.dashboard.select_widget_template')"/>
                    </div>
                  </div>
                </div>
              <!-- Footer -->
              <Divider layout="horizontal"/>
              <div class="p-d-flex p-jc-end mke-mt-4">
                <Button type="submit" @click="widget_create_submit()" :label="t('message.dashboard.widget_create_from_template')" class="p-mt-2">
                </Button>
              </div>
            </form>
          </Dialog>
        <dsh_settingsMenu />
        </div>
      </div>
      </template>
    </div>

    <div class="p-col-12 p-d-flex p-align-center p-flex-column p-flex-md-row p-jc-between gap-4">
        <dsh_calendar />
        <!-- branches -->
        <div class="dsh-branch-picker p-d-flex p-jc-end">
          <div class="p-inputgroup" style="max-width: 700px;">
            <span class="p-inputgroup-addon">
              <i class="mdi mdi-store mdi-md"></i>
            </span>

            <MultiSelect :filter="true" scrollHeight="400" v-model="selectedOutlets" :options="outlets"
                         optionLabel="name" placeholder="message.dashboard.allBranches" display="chip"
                          >
              <template #value="value">
                <span v-if="value.value.length===0">{{ t(value.placeholder) }}</span>
              </template>
              <template #emptyfilter>
                <span>{{ t('message.dashboard.noBranchesFound') }}</span>
              </template>
            </MultiSelect>
          </div>
        </div>
    </div>
  </div>

  <div id="widget-wrapper">
    <div class="p-grid">
      <div>
      </div>
      <div class="grid-stack widgets-list" ref="gridelement">
        <component
          class="grid-stack-item"
          v-for="widget in widgets"
          :gs-x="widget.widget_json.x"
          :gs-y="widget.widget_json.y"
          :gs-h="widget.widget_json.h"
          :gs-w="widget.widget_json.w"
          :key="widget.id"
          :id="widget.id"
          :widgetid="widget.id"
          :is="widget.widget_json.type"
          v-bind="widget.widget_json"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" >
.p-sidebar.no-close-link .p-sidebar-close {
  display: none;
}
</style>

<script>
import { computed, ref, onMounted, onUpdated, onUnmounted } from "vue";
import { useStore } from "vuex";
import WidgetSimple from "./widgets/widget_simple";
import WidgetTable from "./widgets/widget_table";
import GraphArea from "./widgets/graph_area";
import GraphPi from "./widgets/graph_pie";
import GraphBar from "./widgets/graph_bar";
import { GridStack } from "gridstack";
import Dsh_topbar from "./dsh_topbar";
import dsh_settingsMenu from "./widgets/components/dsh_settingsMenu";
import { useI18n } from 'vue-i18n'
export default {
  name: "DshDashboard",
  components: {
    WidgetSimple,
    GraphArea,
    Dsh_topbar,
    GraphPi,
    GraphBar,
    WidgetTable,
    dsh_settingsMenu
  },
  setup() {
    const store = useStore();
    const widgets = computed(() => store.getters["dashboard/get_widgets"]());
    const dashboard_name = computed(() => store.getters["dashboard/get_dashboard_name"]());
    const dashboard_changed = computed(() => store.getters["dashboard/manual_update"]());
    const { t } = useI18n({ useScope: 'global' })
    const edit_dashboard = computed(() => store.getters["dashboard/edit_dashboard"]());
    const gridelement = ref(null);
    const end_date = ref(null);
    const latest_columns_count = ref(0)
    const create_widget_dialog_visible = ref(false);
    const widget_dialog_template_selector = ref({})
    const unlock = ref(false)
    const options_widget_template = ref(store.getters["dashboard/get_widget_templates"]())
    const widget_create_submit = () => {
      store.dispatch('dashboard/createWidget', widget_dialog_template_selector.value)
      create_widget_dialog_visible.value = false;
    }
    const lock_gridstack = () => {
      unlock.value = !unlock.value
      grid.enableResize(unlock.value)
      grid.enableMove(unlock.value)
    }
    const createWidget = () => {
      widget_dialog_template_selector.value = {}
      create_widget_dialog_visible.value = true;
    };
    const create_widget_dialog_hide = async () => {
      create_widget_dialog_visible.value = false;
    }

    const update_gridstack_widgets = async (columns) => {
      grid.removeAll(false);
      let items = []
      for (var i = gridelement.value.children.length; i--; ) {
        items.push({
          item_id: i,
          y: parseInt(gridelement.value.children[i].getAttribute("y")),
          x: parseInt(gridelement.value.children[i].getAttribute("x")),
          w: parseInt(gridelement.value.children[i].getAttribute("w")),
          h: parseInt(gridelement.value.children[i].getAttribute("h")),
          type: gridelement.value.children[i].attributes.type.value,
        })
      }

      items.sort(function(a, b) {
        //sort by x, secondary by y
        return a.y == b.y ? a.x - b.x : a.y - b.y;
      });
      let x = 0;
      let y = 0;
      let w = 0;
      let maxheight = 0;
      // eslint-disable-next-line no-unused-vars
      items.forEach(function (item, index) {
        if(columns === undefined || columns === 12){
          gridelement.value.children[item.item_id].setAttribute("gs-x",item.x);
          gridelement.value.children[item.item_id].setAttribute("gs-y",item.y);
          gridelement.value.children[item.item_id].setAttribute("gs-w",item.w);
          gridelement.value.children[item.item_id].setAttribute("gs-h",item.h);
        } else if(columns === 6) {
          if(item.w > 6){
            w = 6
          } else {
            w = 3
          }
          gridelement.value.children[item.item_id].setAttribute("gs-x",x);
          gridelement.value.children[item.item_id].setAttribute("gs-y",y);
          gridelement.value.children[item.item_id].setAttribute("gs-w",w);
          gridelement.value.children[item.item_id].setAttribute("gs-h",item.h);
          if(item.h > maxheight){
            maxheight = item.h
          }
          if(x === 0 && w === 3){
            x = x + w
            try {
              if (items[index+1].w > 6){
                gridelement.value.children[item.item_id].setAttribute("gs-w",6);
                x = 0
                y = y + maxheight
                maxheight = 0
              }
            } catch (err) {
              //Ignore if chart not inited
            }
          } else {
            x = 0
            y = y + maxheight
            maxheight = 0
          }
        } else if(columns === 1) {
          gridelement.value.children[item.item_id].setAttribute("gs-x",0);
          gridelement.value.children[item.item_id].setAttribute("gs-y",y);
          gridelement.value.children[item.item_id].setAttribute("gs-w",3);
          gridelement.value.children[item.item_id].setAttribute("gs-h",item.h);
          y = y + item.h
        }
        grid.makeWidget(gridelement.value.children[item.item_id]);
      });
    }

    const save_widget_coordinates = async () => {
      let widget_coordinates = {}
      for (var i = gridelement.value.children.length; i--; ) {
        widget_coordinates[gridelement.value.children[i].getAttribute('widgetid')] = {
          h: parseInt(gridelement.value.children[i].getAttribute('gs-h')) || 0,
          w: parseInt(gridelement.value.children[i].getAttribute('gs-w')) || 0,
          x: parseInt(gridelement.value.children[i].getAttribute('gs-x')) || 0,
          y: parseInt(gridelement.value.children[i].getAttribute('gs-y')) || 0,
        }
      }
      store.commit('dashboard/set_widget_coordinates', widget_coordinates)
    }

     const resize = (update_gridstack=false) => {
      let width = document.body.clientWidth;
      let layout = 'move';
      let columns = 1;
      if (width < 768) {
        //Smartphone
        columns = 1
        grid.column(1, layout).cellHeight('181px');
      } else if (width < 1025) {
        // Tablet
        columns = 6
        grid.column(6, layout).cellHeight('181px');
      } else {
        columns = 12
        grid.column(12, layout).cellHeight('181px');
      }
      if(latest_columns_count.value !== columns || update_gridstack===true) {
        update_gridstack_widgets(columns)

      }
      latest_columns_count.value = columns
      //
    };

    const selectedOutlets = computed({
      get: () => {
        return store.state.dashboard.dashboards[store.state.dashboard.active_dashboard].dashboard_settings.outlets
      },
      set: (val) => (
          store.commit("dashboard/set_dashboard_outlets", {dashboardId: store.state.dashboard.active_dashboard, outlets: val}),
          store.dispatch('dashboard/calculate_dashboard')
      )})
    const outlets = computed(() => store.getters["dashboard/get_outlets"]());

    // Calendar
    const date = ref();

    let grid = null;

    onUpdated(() => {
      if (!dashboard_changed.value) {
        resize(true)
      } else {
        store.commit('dashboard/reset_manual_update')
      }
    });

    onMounted(() => {
      let static_grid = store.state.dashboard.move_widgets
      grid = GridStack.init({
        float: true,
        cellHeight: "181px",
        minRow: 2,
        minCol: 3,
        margin: 8,
        animate: true,
        staticGrid: !static_grid,
        disableDrag: true,
        disableResize: true,
        disableOneColumnMode: true
      });
      // eslint-disable-next-line no-unused-vars
      grid.on('dragstop', function(event, element) {
        save_widget_coordinates()
      });
      // eslint-disable-next-line no-unused-vars
      grid.on('resizestop', function(event, element) {
        save_widget_coordinates()
      });
      resize()
      window.addEventListener("resize", resize);
    });
    onUnmounted(() => {
      window.removeEventListener("resize", resize);
    });

    return {
      widgets,
      gridelement,
      end_date,
      date,
      outlets,
      selectedOutlets,
      createWidget,
      create_widget_dialog_hide,
      create_widget_dialog_visible,
      widget_dialog_template_selector,
      options_widget_template,
      dashboard_name,
      edit_dashboard,
      widget_create_submit,
      lock_gridstack,
      t,
      unlock
    };
  },
};
</script>

<style lang="scss">
#dashboard {
  .mke-logout {
    margin-top: 60px;
  }
}

.p-calendar.full-width {
  width: 100%;
  .p-datepicker,
  .p-datepicker-group-container,
  .p-datepicker-group {
    width: 100%;
  }
}
</style>

