<template>
    <div
         class="p-button p-button-lg p-overlay-badge v-select-product p-flex-column"
         :class="'p-button-' + combo.color"
         :data-plu="combo.plu"
         :data-color="combo.color"
         :data-quantity="combo.selected_quantity"
         :data-name="combo.title || combo.name"
         :data-parent="parent.title || parent.name"
         :data-level="level"
         :data-valid="combo.valid"
         :data-selection-method="combo.combo_selection_method"

         :data-min="combo.combo_min"
         :data-max="combo.combo_max"
         :data-display-price="combo.display_price"
         data-action="add"
         @click="selectCombo(combo)">
      <div class="p-button-label" >{{combo.title || combo.name}}</div>
      <div v-if="combo.additional_text && combo.display_price !== null" class="p-button-label additional-price">
        <span v-if="combo.additional_text==='price'">{{n(combo.display_price, 'currency')}}</span>
        <span v-else-if="combo.additional_text==='price_add'">{{'(+' + n(combo.display_price, 'currency') + ')'}}</span>
        <span v-else-if="combo.additional_text==='no_price_add'">{{t('message.masterdata.combo.price.no_price_add')}}</span>
        <span v-else>{{ combo.additional_text }}</span>
      </div>

      <span v-if="combo.selected_quantity > 0" @click.stop="deselectCombo(combo)"
            data-action="sub"
            class="p-badge p-component p-badge-no-gutter p-badge-lg p-badge-warning">
        {{combo.selected_quantity}}
      </span>
    </div>
</template>

<script>

import {useI18n} from "vue-i18n/dist/vue-i18n.esm-bundler";

export default {
  name: "PosProcessComboSingle",
  props: ["combo", "parent", "level"],
  emits: ["entry-selected"],
  setup(props, {emit}) {
    const {t, n} = useI18n({useScope: 'global'})

    const selectCombo = (c) => {
      console.log("entry selected");
      emit('entry-selected', {combo:props.combo, selectedEntry:c, added: true});
    }
    const deselectCombo = (c) => {
      console.log("entry deselected");
      emit('entry-selected', {combo:props.combo, selectedEntry:c, added: false});
    }

    return {t, n, selectCombo, deselectCombo}
  }
}

</script>

<style scoped>

</style>