<template>
<img class="mx-show-on-dark w-100" src="@/assets/images/logo-42.png"/>
<img class="mx-show-on-light w-100" src="@/assets/images/logo-42-light.png"/>
 <!--<svg width="35px" height="25px" viewBox="0 0 35 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Group 5</title>
    <g id="Design-Elements" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Sidebar-Logo" transform="translate(-8.000000, -23.000000)" fill="#FFFFFF">
            <g id="Group" transform="translate(0.000000, 10.000000)">
                <g id="Group-5" transform="translate(8.000000, 13.000000)">
                    <path d="M34.9995,0.655 C34.9995,0.295 34.7045,0 34.3445,0 L13.8955,0 C13.5385,0 13.2455,0.29 13.2405,0.647 L13.2405,4.733 C13.2405,5.042 13.3775,5.507 13.5445,5.766 L14.5275,7.293 L17.1625,11.383 C17.3455,11.667 17.6445,11.668 17.8285,11.384 L21.4285,5.81 C21.6115,5.527 22.0385,5.294 22.3765,5.294 L26.4475,5.294 C26.7855,5.294 27.0615,5.571 27.0615,5.909 L27.0615,20.909 C27.0965,21.237 27.3755,21.496 27.7135,21.496 L34.3445,21.496 C34.7045,21.496 34.9995,21.201 34.9995,20.841 L34.9995,0.655 Z" id="Fill-1"></path>
                    <path d="M21.107,24.1429 C21.467,24.1429 21.762,23.8479 21.762,23.4879 L21.762,13.0299 C21.762,12.6919 21.612,12.6479 21.429,12.9329 L17.834,18.5179 C17.651,18.8019 17.351,18.8019 17.168,18.5179 L13.574,12.9519 C13.391,12.6679 13.241,12.7119 13.241,13.0499 L13.241,18.2339 C13.241,18.5719 12.964,18.8489 12.626,18.8489 L8.555,18.8489 C8.217,18.8489 7.941,18.5719 7.941,18.2339 L7.941,3.3019 C7.941,2.9419 7.646,2.6469 7.286,2.6469 L0.654,2.6469 C0.294,2.6469 0,2.9419 0,3.3019 L0,23.4879 C0,23.8479 0.294,24.1429 0.654,24.1429 L21.107,24.1429 Z" id="Fill-3"></path>
                </g>
            </g>
        </g>
    </g>
</svg>-->
</template>
