<template>
  <div class="preloader_wrapper">
    <div class="pan-preloader">
      <div class="preloader"></div>
      <div class="pan-container">
        <div class="pan"></div>
        <div class="handle"></div>
      </div>
      <div class="shadow"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">

</style>