<template>
  <div
    id="dsh_status"
    class="p-d-flex p-ai-center"
    style="z-index: 2"
    @click="showStatusDialog = true"
  >
    <span class="mx-fg-text-high mke-mr-1">Status:</span>
    <div class="p-d-flex p-ai-center" :status="status">
      <div class="dsh_status_label" :class="'mx-fg-' + color">
        {{ label }}
      </div>
      <div
        class="dsh_status_indicator p-d-flex p-ai-center mke-ml-2"
        :class="status ? 'status-' + status : 'status-unset'"
      >
        <!-- show status-icon if status is not loading -->
        <i
          v-if="status != 'loading'"
          class="p-d-flex p-ai-center"
          :class="icon + ' ' + 'mx-fg-' + color"
        />

        <!-- show spinner if status is loading -->
        <mke-spinner
          class="mx-fg-primary"
          v-if="status == 'loading'"
          size="14"
        />
      </div>
    </div>
  </div>

  <Dialog
    v-model:visible="showStatusDialog"
    :dismissableMask="true"
    :draggable="false"
    :closable="false"
    :modal="true"
    :breakpoints="{ '1200px': '75vw' }"
    :style="{ width: '75vw' }"
  >
    <template #header>
      <div>
        <h5 class="p-d-inline-flex mke-mr-2 p-ai-center">
          Status: {{ label }}
          <i
            v-if="status != 'loading'"
            class="p-d-flex p-ai-center mke-ml-2"
            :class="icon + ' ' + 'mx-fg-' + color"
          />
          <!-- show spinner if status is loading -->
          <mke-spinner
            class="mx-fg-primary mke-ml-3"
            v-if="status == 'loading'"
            size="18"
          />
        </h5>
        <span class="mx-fg-text-high">{{ description }}</span>
      </div>
    </template>

    <DataTable :value="data">
      <Column field="service" header="Service"></Column>
      <Column field="outlet" header="Outlet"></Column>

      <Column field="progress" header="Progress" style="min-width: 10rem">
        <template #body="{data}">
          <ProgressBar
            class="has-lable"
            :class="{'status-error': data.status == 'error', 'status-warning': data.status == 'warning' }"
            :value="data.progress"
            :showValue="true"
          />
        </template>
      </Column>
      <Column field="status" header="Status">
        <template #body="{data}">
          <div class="p-d-flex p-ai-center">
             <!-- icon if 'ok' -->
          <i
            v-if="data.status == 'OK'"
            class="mdi mdi-thumb-up mx-fg-success-high p-d-flex p-ai-center mke-mr-2"
          />

          <!-- icon if 'error' -->
          <i
            v-if="data.status == 'error'"
            class="mdi mdi-alert-circle mx-fg-danger-high p-d-flex p-ai-center mke-mr-2"
          />

          <!-- icon if 'warning' -->
          <i
            v-if="data.status == 'warning'"
            class="mdi mdi-information mx-fg-warning-high p-d-flex p-ai-center mke-mr-2"
          />

          <!-- <div :class="data.status == 'error' ? 'mx-fg-danger-high' : '' && data.status == 'warning' ? 'mx-fg-warning-high' : ''"> -->
          <div :class="{'mx-fg-danger-high': data.status == 'error', 'mx-fg-warning-high': data.status == 'warning' }">
            <span v-if="data.status == 'error'">Fehler: </span>
            <span v-if="data.status == 'warning'">Warnung: </span>
            {{ data.description }}
          </div>

          <!-- spinner if 'loading' -->
          <mke-spinner
            class="mx-fg-primary mke-ml-3"
            v-if="data.status == 'loading'"
            size="18"
          />
          </div>
        </template>
      </Column>
    </DataTable>

    <template #footer>
      <mke-button color="primary" label="Schließen" @click="showStatusDialog = false"> </mke-button>
    </template>
  </Dialog>
</template>

<script>
import {computed, ref} from "vue";
import { useStore } from 'vuex';
export default {
  name: "DshStatus",
  props: ["label", "status", "icon", "color", "description"],
  setup() {
    const showStatusDialog = ref(false);
    const store = useStore();
    const data = computed(() => store.getters["dashboard/get_syncinfo"]());
    return { showStatusDialog, data };
  },
};
</script>
