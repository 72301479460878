<template>
  <div :id="id" class="pos-order-functions" >
    <div class="mke-widget grid-inset">
      <div class="grid-wrapper" :class="'rows_' + (widgetRows ? widgetRows : 4) + ' cols_' + (widgetCols ? widgetCols : 1)">
        <mkeButton class="pos-order-functions-send-bon font-size-xl" color="primary" functionCall="commitOrder" label="Senden"></mkeButton>
        <mkeButton class="pos-order-functions-set-quantity font-size-xl"  color="secondary" functionCall="setPosQuantity" labelStorePost="pos/getQuantity" functionCallStore="pos-main-keyboard-display" label="Anz."></mkeButton>
        <mkeButton class="pos-order-functions-payment font-size-xxl"  color="primary" functionCall="startPayment" label="Bar"></mkeButton>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "PosFunctions",
  props: ['id', 'widgetRows', 'widgetCols']

}
</script>