<template>
  <div id="bon">
       <div id="content">
          <TopBar />
          <Panel :header="'Vorgänge'" class="process-list" >
            <ul style="list-style: none">
              <li v-for="process in processOverviewList" :key="process.id">
                 <mke-button :icon="process.icon" :color="process.color" :label="process.label"
                             @click="showBon(process.id)"/>
              </li>
            </ul>
          </Panel>
      </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import {computed} from "vue";

export default {
  setup(){
    const store = useStore()
    store.dispatch("pos/getProcesses")

    const restUrlPosService = config.PosServiceUrl

    const processOverviewList = computed(() => {
          let items = [];
          store.getters["pos/processList"]().forEach( process => {
            let icon = "cloud-print-outline";
            let color = "warning";

            if( process.process_entries.some((e) => e.state === "ordered") ) {
              icon = "cloud-print";
              color = "primary"
            }
            items.push({
              id: process.id,
              label: process.name,
              icon: 'mdi mdi-'+icon,
              color: color
            })
          });
          return items;
    } )

    const processList = computed(() => {
      return store.getters["pos/processList"]();
    } )

    function showBon(processId) {
      window.open(restUrlPosService + "/pos/" + processId + "/bon", "_blank");
    }

    /**
     * Close the current active table
     * It gets automatically called when the window is about to unload its resources
     */
    const closePosSession = () => {
      // console.log(store);
      // store.dispatch("pos/closePosSession")
    }
    return {
      processOverviewList, processList, showBon, closePosSession
    }

  },
  created() {
    window.addEventListener('beforeunload', this.closePosSession)
  },
};
</script>

<style lang="scss">
  .process-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 3rem;

    .p-card {
      height: 40vh;
      overflow: auto;
      min-width: max(40ch, 20%);
      max-width: min(80ch, 30%);
    }
  }

  li {
    margin-bottom: 0.5rem;
  }

  .quantity {
    display: inline-block;
    text-align: right;
    width: 3ch;
    padding-right: 1rem;
  }

  .info {
    display: inline-block;

  }
</style>