<template>
  <div id="topbar" style="z-index: 2">
    <div class="p-grid" style="z-index: 2">
      <div class="p-col-12 mke-py-0">
        <Toolbar class="mke-py-2 transparent">
          <template #start>
            <Button icon="mdi mdi-menu mdi-2x" @click="mobileMenuVisible = true" class="p-button-transparent p-button-rounded mke-mr-4 p-d-xl-none" />
            <div class="time body-14-regular">{{clock}}</div>
          </template>


          <template #end>
            <mke-switch-theme-toggle/>
            <Button
                class="p-button-transparent"
                type=""
                icon="mdi mdi-bell-outline mdi-2x"
            />
            <Divider layout="vertical" />
            <div class="user_profile p-d-flex p-ai-center p-ripple hover-primary" v-ripple @click="toggle">
              <Avatar
                  v-if="activeUser && activeUser.user_image"
                  size="large"
                  :image="require('@/assets/images/avatar.jpg')"
                  shape="circle"
              /> <!-- TODO user_image -->
              <Avatar
                  v-else
                  size="large"
                  icon="mdi mdi-account-outline"
                  shape="circle"
              />
              <div class="user_name mke-ml-4">
                <span v-if="activeUser" class="body-14-bold p-d-block">{{activeUser.user_name}}</span>
                <span v-if="activeUser" class="">ID: {{activeUser.user_id}}</span>
              </div>
            </div>
            <Menu ref="menu" :model="items" :popup="true" />
          </template>
        </Toolbar>
        <Dialog v-model:visible="showSettingsDialog" :style="{width: '50vw'}" :dismissableMask="true"
                :draggable="false" :closable="true" :modal="true">
          <template #header>
            <div>
              <h5 class="p-d-inline-block mke-my-0 mke-mr-2">{{ t("message.home.settings") }}</h5>
            </div>
          </template>
          <mkeModelDetail store="admin" model="users" :activeID="activeUser.user_id" :schema="schema" @onGoBack="onGoBack($event)"/>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import { useI18n } from 'vue-i18n'
import { getTranslatedSchema } from "../../json/admin_demo";

export default {
  name: "TopBar",
  setup() {
    const store = useStore()
    const menu = ref()
    const activeUser = computed(() => store.state.general.activeUser)
    const { t, d } = useI18n({ useScope: 'global' })
    const mobileMenuVisible = computed({
      get: () => {return store.state.admin.mobile_menu_visible},
      set: (value) => (store.commit("admin/set_mobile_menu_visible", value))
    })
    const schema = computed(() => getTranslatedSchema("user_settings", "detail"))
    const showSettingsDialog = ref(false)

    const items = ref([
      {
        label: t("message.home.settings"),
        items: [
          {
            label: t("message.home.userSettings"),
            icon: "mdi mdi-dashboard",
            command: () => {
              showSettingsDialog.value = true
            }
          },
        ],
      },
      {
        label: t("message.home.navigate"),
        items: [
          {
            label: t("message.home.logout"),
            icon: "mdi mdi-logout",
            command: () => {
              store.dispatch('general/logout')
            }
          },
        ],
      },
    ]);

    const clock = ref()
    /**
     * Get the current date and time
     */
    const get_now = async () =>  {
      const today = new Date();
      clock.value = d(today, "datetime_long")
    }

    const toggle = (event) => {
      menu.value.toggle(event);
    };

    function onGoBack() {
      showSettingsDialog.value = false
    }

    return { items, menu, toggle, get_now, clock, activeUser, mobileMenuVisible, onGoBack, schema, showSettingsDialog, t };
  },
  created() {
    setInterval(() => {
      this.get_now();
    }, 1000)
  },
};
</script>
