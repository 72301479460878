<template>
  <MxApp :app="activeApp">
    <div id="path-not-found" class="p-d-flex p-flex-column p-ai-center p-jc-around w-100 h-100">
      <h1>Leider konnten wir auf <strong>{{$route.path}}</strong> nichts für Sie finden!</h1>
      <mkeButton label="Get Back" @click="$router.go(-1)"/>
    </div>
  </MxApp>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: "PathNotFound",
  setup() {
    const store = useStore();
    const activeApp = computed( () => store.state.general.activeApp ?? {name: "UNKNOWN", id: "UNKNOWN"} );

    return {activeApp};
  }
}
</script>

<style scoped>

</style>