<template>
  <Dialog id="process-combo-dialog"

          :modal="true"
          :draggable="false"
          v-model:visible="processComboDialogVisible"
          :closable="true"
          :dismissableMask="true"
          :data-selection-method="rootCombo.combo_selection_method"
          :data-price-method="rootCombo.combo_price_method"
          :data-main-price="rootCombo.price"
          :data-child-price="rootCombo.children_price"
          :data-combo-price="rootCombo.combo_price"
          style="width: max-content; max-width: 80vw;"
          >
    <template #header>
      <h3 class="p-d-flex p-jc-between p-align-center w-100">
        <span>{{rootCombo.title}}</span>
        <span class="font-size-m" v-if="rootCombo.combo_price !== null">
          {{ t('message.pos.total_price') }}: {{n(rootCombo.combo_price, 'currency')}}
        </span>
      </h3>
     <!-- <Button @click="closeDialog()"></Button> -->
    </template>

    <h5 v-if="rootCombo.child_mode!='all_child_childs'" style="margin-bottom: 1rem">{{rootCombo.selection_title}}</h5>
    <pos-process-combo-component :combo="rootCombo" :parent="rootCombo" :level="1" @entry-selected="updateCombo"/>

    <template #footer>

      <mke-button id="button-combo-cancel" label="Abbruch" icon="pi pi-times" class="p-button-transparent" @click="closeDialog" />
      <mke-button id="button-combo-commit" label="Weiter" icon="pi pi-check" class="p-button-primary" :disabled="!rootCombo.valid || rootCombo.selected_quantity === 0" @click="commitOrder" />
    </template>
  </Dialog>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import PosProcessComboComponent from "@/components/pos/combo/PosProcessComboComponent";
import {useI18n} from "vue-i18n";

export default {
  name: "PosProcessComboDialog",
  components: {PosProcessComboComponent},
  setup(){
    const store = useStore();
    const {t, n} = useI18n({useScope: 'global'})

    const processComboDialogVisible = computed( () => store.state.pos.processComboAction !== undefined );
    const rootCombo = computed( () => store.state.pos.processComboAction !== undefined ? store.state.pos.processComboAction.rootCombo : "")

    const closeDialog = () => {
      store.commit("general/addToast", {
        detail: t('message.pos.selection_canceled'),
        life: 3000,
        severity: "info",
        summary: "Info"
      } );
      store.commit('pos/setProcessComboAction', undefined);
    }

    const commitOrder = () => {
      if( store.state.pos.processComboAction.process_id) {
        store.dispatch('pos/commitCombo', store.state.pos.processComboAction)
      } else {
        store.commit('pos/setProcessComboAction', undefined);
      }
    }

    const updateCombo = ({selectedEntry, added} ) => {
      store.commit("pos/processComboSelected", {selectedEntry:selectedEntry, added: added} )
    }

    return {
      t, n, processComboDialogVisible, rootCombo, closeDialog, updateCombo, commitOrder
    }
  },
  /**
   * Close the Dialog if the 'Cross' is clicked for closing
   */
  updated() {
    // if (!this.showProcessListDialog){
    //   this.closeDialog()
    // }
  }
}

</script>

<style scoped>

</style>