<template>
  <Dialog :modal="true" v-model:visible="showProcessListDialog">
    <div>
      <Button @click="closeDialog()">Close</Button>
      <Button @click="createNewProcessClick()">New Process</Button>
      <DataTable :value="posProcesses" responsiveLayout="scroll" selectionMode="single" @row-click="rowClick"
                 :paginator="true" :rows="10" :resizableColumns="true" columnResizeMode="fit" showGridlines>
        <template #header>
          <div style="text-align:left">
            <MultiSelect :modelValue="selectedColumns" :options="processColumns" @update:modelValue="onToggle"
                         placeholder="Select Columns" style="width: 20em"/>
          </div>
        </template>
        <Column v-for="(col, index) of selectedColumns" :field="col" :header="col" :key="col + '_' + index"
                :sortable="true">
        </Column>
      </DataTable>
    </div>
  </Dialog>
</template>

<script>
import {useStore} from "vuex";
import {computed, ref, watch} from "vue";
import {pos_api} from "../../api/pos.js";

export default {
  name: "PosProcessListDialog",
  components: {},
  setup(){
    const store = useStore();
    const {closeTable, createNewProcess, openTable} = pos_api()
    const posProcesses = computed(() => store.getters["pos_process/getProcessList"]())
    const selectedColumns = computed(() => store.getters["pos_process/getSelectedProcessListColumns"]())
    const processColumns = computed(() => store.getters["pos_process/getProcessListKeys"]())
    const showProcessListDialogStore = computed(() => store.state.pos_process.showProcessListDialog)
    const showProcessListDialog = ref(false)
    watch(showProcessListDialogStore, () => {
      showProcessListDialog.value = showProcessListDialogStore.value
    })

    /**
     * Create and open a new process
     * Close the dialog afterwards
     */
    const createNewProcessClick = () => {
      let tableId = createNewProcess()
      if (store.state.pos_process.active_process && store.state.pos_process.active_process.Table){
        closeTable(store.state.pos_process.active_process.Table)
      }
      openTable(tableId)
      closeDialog()
    }
    /**
     * Close the dialog by updating the store
     */
    const closeDialog = () => {
      store.commit('pos_process/setShowProcessListDialog', false)
    }
    /**
     * Toggle the shown Columns
     * @param val The column name that got toggled
     */
    const onToggle = (val) => {
      store.commit('pos_process/setSelectedProcessListColumns', val)
    };
    /**
     *
     * @param process
     * @returns {Promise<void>}
     */
    const rowClick = async (process) => {
      if (store.state.pos_process.active_process && store.state.pos_process.active_process.Table){
        closeTable(store.state.pos_process.active_process.Table)
      }
      openTable(process.data.Table)
      closeDialog()
    }
    return {
      closeDialog, createNewProcessClick, onToggle, posProcesses, processColumns, rowClick, selectedColumns, showProcessListDialog
    }
  },
  /**
   * Close the Dialog if the 'Cross' is clicked for closing
   */
  updated() {
    if (!this.showProcessListDialog){
      this.closeDialog()
    }
  },
}

</script>

<style scoped>

</style>