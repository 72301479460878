<template>
<div>
  <div class="mx-app" v-shared-element:[activeApp?.id] style="width: 100vw; height: 100vh; overflow: hidden; border-radius: 0px; background: var(--surface-high);">
    <MxAppSwitcher class="" :app="activeApp"/>
    <div v-if="loading" id="" class="mx-app-loader p-d-flex p-ai-center p-jc-center" style="width: 100%; height: 100%;">
      <mke-spinner class="mx-fg-primary" size="100" />
    </div>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</div>
</template>

<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";

export default {
  name: "MxApp",
  props: ["app"],
  setup(props) {
    const loading = ref(true);

    const store = useStore();

    const activeApp = computed( () => props.app ?? store.state.general.activeApp?.value ?? {name: "UNKNOWN", id: "UNKNOWN"});

    window.setTimeout(() => {
      loading.value = false;
    }, 200);

    return {loading, activeApp};
  }

}
</script>

<style scoped>

</style>