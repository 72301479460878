<template>
  <div class="p-grid p-ai-center mke-m-2" v-if="value">
    <div class="p-col-3 mke-p-0" :class="labelClass">
      <label>{{t(label)}}</label>
    </div>
    <div class="mke-p-0 p-d-flex p-justify-between p-align-center" :class="cssValueClass">{{value}}</div>
  </div>
</template>

<script>
import {computed} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: "mkeDisplayValue",
  props: ["label", "value", "cols", "labelClass", "valueClass"],
  setup(props) {
    const { t } = useI18n({ useScope: 'global' })

    const cssValueClass = computed( () => {
        const cols = props.cols ? props.cols : 9;
        return ( props.valueClass ? props.valueClass : "" ) + " p-col-" + cols;
    })
    return {t, cssValueClass}
  }
}
</script>

<style scoped>

</style>