<template>
  <div class="mkeFormBuilder p-d-flex gap-4" :class="{'p-flex-column':!inline}">
    <!-- Exclude invisible fields -->
    <div
        v-for="(element) in schema.filter(x => !modelFieldInvisible.includes(x.field))"
        :key="element.id"
        class="p-d-flex p-flex-column gap-4 mke-p-0"
        :class="element.class"
    >
      <!-- Many2One -->
      <component
          :is="element.component"
          v-model="modelData[element.field]"
          v-bind="element"
          v-if="element.type === 'many2one'"
          :options="modelOptions[element.field]"
          v-on:update:modelValue="onModelValueUpdate(element.field, modelData[element.field])"
      />
      <!-- One2Many -->
      <component
          :is="element.component"
          v-model:modelValue="modelData[element.field]"
          v-bind="element"
          v-else-if="element.type == 'one2many'"
          :options="modelOptions[element.field]"
          :displayType="element.displayType"
          v-on:update:modelValue="onModelValueUpdate(element.field, modelData[element.field])"
      />
      <!-- Many2Many -->
      <component
          :is="element.component"
          v-model:modelValue="modelData[element.field]"
          v-bind="element"
          :rows="element.rows || 20"
          v-else-if="element.type === 'many2many'"
          :modelData="modelOptions[element.field]"
          :selectionMode="element.selectionMode"
          :selectedData="parseSelectedData(modelData[element.field])"
          :options="modelOptions[element.field]"
          :displayType="element.displayType"
          v-on:update:modelValue="onModelValueUpdate(element.field, modelData[element.field])"
      />
      <!-- Tree -->
      <component
          :is="element.component"
          v-bind="element"
          v-else-if="element.type === 'tree'"
          :store="store"
          :activeID="activeID"
          :data="modelData"
          :options="modelOptions"
          v-on:modelValueUpdate="onModelValueUpdate"
          v-on:update:modelValue="onModelValueUpdate"
      />

      <!-- Tab -->
      <component
          :is="element.component"
          v-bind="element"
          v-else-if="element.component === 'mkeFormBuilderTab'"
          :store="store"
          :activeID="activeID"
          :data="modelData"
          :options="modelOptions"
          v-on:modelValueUpdate="onModelValueUpdate"
          v-on:update:modelValue="onModelValueUpdate"
      />

      <!-- Standard Field -->
      <component
          :is="element.component"
          v-model="modelData[element.field]"
          :field="element.field"
          v-bind="element"
          v-on:update:modelValue="onModelValueUpdate(element.field, modelData[element.field])"
          v-else
      />
    </div>

  </div>
</template>

<script>
import {toRef, ref} from "vue";

export default {
  name: "FormBuilder",
  props: ["store", "activeID", "data", "options", "schema", "inline"],
  emits: ["modelValueUpdate"],
  setup(props, context){
    const modelData = toRef(props, "data");

    // Set default values
    if (Object.keys(modelData.value).length === 0) {
      props.schema.filter(x => 'default' in x).map(x => modelData.value[x.field] = x.default)
    }

    const modelFieldInvisible = ref([])
    const modelOptions = toRef(props, "options");
    const modelFieldWithVisibleConditions = ref(props.schema.filter(x => x.visible_condition))

    // Check and change visibility on setup
    let fields = [...new Set(modelFieldWithVisibleConditions.value.map(x => x.visible_condition.split(",")[0]))]
    fields.map(x => checkAndChangeFieldVisibility(x, modelData.value[x] || false))

    /**
     * Function to check and add/remove fields depending on their visible_condition
     * @param field The field to check
     * @param value The value to check
     */
    function checkAndChangeFieldVisibility(field, value) {
      for (let fieldWithCondition of modelFieldWithVisibleConditions.value.filter(x => x.visible_condition.includes(field))){
        if (String(value) != String(fieldWithCondition.visible_condition.split(",")[1])){
          modelFieldInvisible.value.push(String(fieldWithCondition.field))
        } else {
          modelFieldInvisible.value = modelFieldInvisible.value.filter(x => x != fieldWithCondition.field)
        }
      }
    }

    /**
     * Function to transform a list of ids into an object with the format {"id": true, "id2:": true}.
     * We need this to pre-check (checked=true) the toggle element of the mkeDataTable.
     * It is used for the many2many table view.
     * @param {Array.<String>} selectedData
     * @returns {{}}
     */
    function parseSelectedData(selectedData){
      if (!selectedData){
        return {}
      }
      return selectedData.reduce((a, v) => ({ ...a, [v]: true}), {})
    }

    /**
     * Triggered on model value update.
     */
    function onModelValueUpdate(field, value) {
      context.emit("modelValueUpdate", field, value)
      checkAndChangeFieldVisibility(field, value)
    }

    return {modelData, modelFieldInvisible, modelOptions, onModelValueUpdate, parseSelectedData}
  }
}
</script>

<style scoped>

</style>
