<template>
  <Dialog
      :id="id"
      :modal="true"
      :draggable="false"
      v-model:visible="processSelectorDialogVisible"
      :closable="true"
      :style="{width: '60vw'}">
    <template #header>
      <h5>{{ title }} {{activeProcess?.name}}</h5>
    </template>
    <div class="selector-open-processes mke-mb-5">
      <div class="mx-title-with-divider">Offene Vorgänge</div>
      <div class="grid-wrapper cols_4 mke-mt-2">
        <mkeButton v-for="(process, index) in processList"
                    size="lg"
                   :key="index"
                   :label="process.name"
                   @click="selectProcess(process)"
                   :tooltip="process.id"
                   color="secondary"
                   v-bind:class="[process.id === (activeProcess && activeProcess.id) ? 'active' : '', 'mke-button--select-process']"
                   :data-process-id="process.id"
        />
      </div>
    </div>
    <div class="selector-new-processes">
      <div class="mx-title-with-divider">Neuer Vorgang</div>
      <div class="grid-wrapper cols_4 mke-mt-2" >
        <mkeButton v-for="(process, index) in remainingProcessList"
                   size="lg"
                   :key="index"
                   :label="process.name"
                   @click="selectProcess(process)"
                   color=""
        />
      </div>
    </div>

    <template #footer>
      <mke-button id="button-payment-cancel" label="Abbrechen" icon="pi" @click="closeDialog" class=""/>
    </template>
  </Dialog>

</template>
<script>
import { useStore } from 'vuex'
import {computed} from 'vue'

export default {
  name: "PosProcessSelectDialog",
  props: ["id", "title"],
  emits: ['process:selected', 'process:canceled'],

  setup(props, ctx){
    const store = useStore()
    const processList = computed(() => store.state.pos.processList)
    const activeProcess = computed(() => store.state.pos.activeProcess)
    const processSelectorDialogVisible = computed(() => store.state.pos.selectProcessVisibleId === props.id)

    const remainingProcessList = computed( () => {
      let currentProcessNames = [];
      for( let prop in processList.value ) {
        let name = processList.value[prop].name;

        currentProcessNames.push(name)
      }
      let remainingProcessList = [];
      for( let p = 1; p <= 20; p++ ) {
        const label = "" + p;
        if( currentProcessNames.indexOf(label) < 0 ) {
          remainingProcessList.push({index: p, name: label})
        }
      }

      return remainingProcessList;
    })

    const selectProcess = (process) => {
      ctx.emit("process:selected", process);
    }

    const closeDialog = () => {
      store.commit("pos/setSelectProcessVisibleId", undefined);
      ctx.emit("process:canceled");
    }

    return {
      activeProcess,
      processList,
      remainingProcessList,
      processSelectorDialogVisible,
      selectProcess,
      closeDialog
    }
  },

}
</script>
<style lang="scss">

</style>