<template>
  <Button class="p-button-transparent p-button-icon-only" type="" @click="toggleTheme" :class="{'p-d-none' : ignoreTheme}">
    <input
      @change="toggleTheme"
      id="checkbox"
      type="checkbox"
      class="switch-checkbox"
    />
    <label for="checkbox" class="switch-label">
      <span v-if="userTheme === 'dark-theme'">🌙</span>
      <span  v-if="userTheme === 'light-theme'">☀️</span>
      <div
        class="switch-toggle"
        :class="{ 'switch-toggle-checked': userTheme === 'dark-theme' }"
      ></div>
    </label>
  </Button>
</template>

<script>
export default {
  name: 'mkeSwitchThemeToggle',
    props: {
        ignoreTheme: Boolean
    },
  mounted() {
    const initUserTheme = this.getTheme() || this.getMediaPreference();
     if (this.ignoreTheme) {
        this.setTheme("dark-theme");
      }
      else {
        this.setTheme(initUserTheme);
      }
    
  },

  data() {
    return {
      userTheme: "dark-theme",
    };
  },

  methods: {
    toggleTheme() {
      const activeTheme = localStorage.getItem("user-theme");
      if (activeTheme === "light-theme") {
        this.setTheme("dark-theme");
      } else {
        this.setTheme("light-theme");
      }
    },

    getTheme() {
      return localStorage.getItem("user-theme");
    },

    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },

    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "light-theme";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.switch-checkbox {
  display: none;
}
</style>
