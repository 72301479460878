<template>
  <div class="p-grid mke-pt-5">
    <div class="p-col-fixed p-d-flex p-ai-center">
      <mke-button
          icon="mdi mdi-arrow-left"
          class="p-button-text no-shrink mke-mr-3"
          @click="goBack()"
      />
      <h1 v-if="data.name == null || data.name == ''" class="mke-page-title display-small p-d-inline-block">{{ t('message.home.newE') }} {{ t('message.crud.model.'+ model) }}</h1>
      <h1 v-else class="display-small p-d-inline-block">{{ data.name }}</h1>
    </div>
    <div class="p-col p-d-flex p-ai-center">

      <mke-button
          id="button-model-detail-save"
          :label="t('message.crud.save')"
          class="p-button-primary no-shrink mke-ml-2 crud-save"
          @click="save"
          :disabled="!modelValueChanged"
      />
    </div>
  </div>
  <div class="p-grid mke-pt-5">
    <div class="p-col-12">
      <mkeFormBuilder v-if="loading != true" :schema="schema" :data="data" :options="options" v-on:modelValueUpdate="onModelValueUpdate"/>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import { useI18n } from 'vue-i18n'

export default {
  name: "mkeCrudDetail",
  props: ["activeID", "model", "schema", "store"],
  emits: ["onGoBack"],
  setup(props, context) {
    const store = useStore()
    const data = ref({})
    const loading = ref(false)

    if (props.activeID) {
      loading.value = true
      store.dispatch(props.store + "/getSpecificData",{id: props.activeID, model: props.model,
        onSuccessCallback: (newData) => {
          data.value = {...newData}
          loading.value = false
      }});
    }
    const modelValueChanged = ref(false)
    const options = ref(store.state[props.store].options)
    const { t } = useI18n({ useScope: 'global' })

    /**
     * Get the options for many2one fields
     */
    onMounted(() => {
      for (let element of props.schema.filter(x => ["many2one", "many2many"].includes(x.type) && x.model != null)){
        store.dispatch(props.store + "/getAllData", {field: element.field, model: element.model})
      }
    })

    /**
     * Save the new data
     * Put request to the service
     */
    const save = () => {
      if (data.value){
        store.dispatch(props.store + "/saveData", {data: data.value, model: props.model,
          successcallback: (result) => {
            if(result){
              if (props.model === "dashboard_names" && result.id){
                store.dispatch("dashboard/createDashboard", result.id);
              }
            }
            goBack()
          },
          errorcallback: () => {
          }
        })
      } else {
        store.dispatch(props.store + "/createData", {data: data.value, model: props.model})
      }

    };

    /**
     * Go back to Overview
     */
    const goBack = () => {
      context.emit("onGoBack")
    };

    /**
     * Triggered on model value update.
     */
    function onModelValueUpdate() {
      modelValueChanged.value = true
    }

    return {data, goBack, loading, modelValueChanged, onModelValueUpdate, options, save, t}
  }
}
</script>
