<template>
  <div class="mx-querybuilder-children mx-querybuilder-list">
    <component
        v-for="(child, index) in query.rules"
        :is="getComponent(child.type)"
        :key="index"
        :type="child.type"
        v-model:query="child.query"
        :dataSource="dataSource"
        :cube="cube"
        :index="index"
        :labels="labels"
        :groupComponent="groupComponent"
        :ruleComponent="ruleComponent"
    >
      <template #deleteRule>
          <mke-button
            color="transparent"
            :key="index"
            type="button"
            icon="pi pi-times"
            class="p-button-rounded p-button-sm"
            @click="$emit('deleteChild', index)"
          />
      </template>
    </component>
  </div>
</template>

<script>
export default {
  props: [
    "query",
    "dataSource",
    "cube",
    "labels",
    "groupComponent",
    "ruleComponent",
  ],
  emits: ["deleteChild"],
  setup(props) {
    const getComponent = (type) => {
      return type === "query-builder-group"
          ? props.groupComponent
          : props.ruleComponent;
    }
    return {getComponent}
  }
};
</script>
