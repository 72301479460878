import * as signalR from '@microsoft/signalr';
import store from "@/store"

const SignalRPlugin = {
    install(app, { url, options }) {
        const connection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(url)
            .build();

        app.provide('signalr', connection)
        app.config.globalProperties.$signalr = connection
    }
}

export default SignalRPlugin;