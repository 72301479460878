function scrollToBottom(elementSelector) {
    const container = document.querySelector(elementSelector);
    // container.scrollTop = container.scrollHeight;
    container.scrollIntoView({behavior: 'smooth'});
}

export default {
    install(app) {


        app.config.globalProperties.$scrollToBottom = scrollToBottom;
        app.provide('scrollToBottom', scrollToBottom);
    }
}

export {scrollToBottom };
