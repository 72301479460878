<template>
  <div class="tse-list">
    <Card v-for="signature in signatures" :key="signature.tse_id + '-' + signature.signature_counter"
          class="mke-p-0"
          :data-transation-number="signature.transaction_number"
          :data-signature-counter="signature.signature_counter"
          :data-timestamp-start="signature.timestamp_start"
          :data-timestamp-finished="signature.timestamp_finished"
          :data-process-type="signature.process_type"
          :data-process-data="signature.process_data"
          :data-signature="signature.signature"
          :data-tse-id="signature.tse_id">

      <template #content>
        <mkeDisplayValue label="message.pos.tse.transaction_number" :value="signature.transaction_number"/>
        <mkeDisplayValue label="message.pos.tse.signature_counter" :value="signature.signature_counter"/>
        <mkeDisplayValue label="message.pos.tse.timestamp_start" :value="signature.timestamp_start"/>
        <mkeDisplayValue label="message.pos.tse.timestamp_finished" :value="signature.timestamp_finished"/>
        <mkeDisplayValue label="message.pos.tse.process_type" :value="signature.process_type"/>
        <mkeDisplayValue label="message.pos.tse.process_data" :value="signature.process_data"/>
        <mkeDisplayValue label="message.pos.tse.tse_id" :value="signature.tse_id"/>
        <mkeDisplayValue label="message.pos.tse.signature" :value="signature.signature"/>
        <Divider layout="horizontal" />
      </template>
    </Card>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";

export default {
  name: "PosTSEList",
  props: ['signatures'],
  setup() {
    const { t, d, n } = useI18n({ useScope: "global" });

    return { t, d, n };
  }
}
</script>

<style scoped>

</style>