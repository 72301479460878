// Core Vue
import { createApp } from 'vue'
import App from './App.vue';
import router from "./router";
import PrimeVue from 'primevue/config';
import store from "./store"
import VueCookies from 'vue3-cookies'
import { createPinia } from 'pinia'


// PrimeVue UI Elements
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import InputText from 'primevue/inputtext';
import Rating from 'primevue/rating';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import SelectButton from 'primevue/selectbutton';
import ToggleButton from 'primevue/togglebutton';
import OverlayPanel from 'primevue/overlaypanel';
import Toolbar from 'primevue/toolbar';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import OrderList from 'primevue/orderlist';
import Avatar from 'primevue/avatar';
import Sidebar from 'primevue/sidebar';
import Ripple from 'primevue/ripple';
import Dialog from 'primevue/dialog';
import Menu from 'primevue/menu';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Card from 'primevue/card';
import TieredMenu from 'primevue/tieredmenu';
import PanelMenu from 'primevue/panelmenu';
import MultiSelect from 'primevue/multiselect';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import FileUpload from 'primevue/fileupload';
import InputNumber from 'primevue/inputnumber';
import ProgressBar from 'primevue/progressbar';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Tooltip from 'primevue/tooltip';
import Calendar from 'primevue/calendar';
import InputSwitch from 'primevue/inputswitch';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import TreeTable from 'primevue/treetable';
import CascadeSelect from 'primevue/cascadeselect';
import Inplace from 'primevue/inplace';
import Password from 'primevue/password';
import Badge from 'primevue/badge';
import AutoComplete from 'primevue/autocomplete';
import Image from 'primevue/image'
import SplitButton from "primevue/splitbutton";

import {toCurrency, toDateTime} from './utilities';
import ScrollPanel from 'primevue/scrollpanel';
import Panel from 'primevue/panel';

// Responsive Plugin
import { Vue3Mq, MqResponsive } from "vue3-mq";
import i18n from './i18n/index'

// Vue 3 Lottie
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

// Responsive Video Background
import VideoBackground from 'vue-responsive-video-background-player'

// 42 Custom
import pos_api from "./api/pos.vue"
import mkeButton from "./components/general/mkeButton";
import mkeChangePasswordDialog from "./components/general/mkeChangePasswordDialog";
import mkeDataTable from "./components/general/mkeDataTable";
import mkeFormBuilder from "./components/formbuilder/mkeFormBuilder"
import mkeFormBuilderDropdown from "./components/formbuilder/mkeFormBuilderDropdown";
import mkeFormBuilderInputCheckbox from "./components/formbuilder/mkeFormBuilderInputCheckbox";
import mkeFormBuilderInputSwitch from "./components/formbuilder/mkeFormBuilderInputSwitch";
import mkeFormBuilderInputText from "./components/formbuilder/mkeFormBuilderInputText";
import mkeFormBuilderInputTextarea from "./components/formbuilder/mkeFormBuilderInputTextarea";
import mkeFormBuilderInputNumber from "./components/formbuilder/mkeFormBuilderInputNumber";
import mkeFormBuilderTreeTable from "./components/formbuilder/mkeFormBuilderTreeTable";
import mkeFormBuilderTab from "./components/formbuilder/mkeFormBuilderTab";
import mkeFormBuilderTranslationDialog from './components/formbuilder/mkeFormBuilderTranslationDialog';
import mkeFormBuilderMultiSelect from "./components/formbuilder/mkeFormBuilderMultiSelect";
import mkeFormBuilderImage from "./components/formbuilder/mkeFormBuilderImage";
import mkeFormBuilderAddItemDialog from "./components/formbuilder/mkeFormBuilderAddItemDialog";
import mkeFormBuilderOneToManyPanel from "./components/formbuilder/mkeFormBuilderOneToManyPanel";
import mkeFormBuilderDisplayValue from "./components/formbuilder/mkeFormBuilderDisplayValue";
import mkeFormBuilderSelectItem from "./components/formbuilder/mkeFormBuilderSelectItem";
import mkeInputText from "./components/general/mkeInputText";
import mkeDisplayValue from "./components/general/mkeDisplayValue";
import mkeKeyboard from "./components/general/mkeKeyboard";
import mkeModelDetail from "./components/general/mkeModelDetail"
import mkeModelOverview from "./components/general/mkeModelOverview"
import mkeCrud from "./components/general/mkeCrud"
import mkeCrudDetail from "./components/general/mkeCrudDetail"
import mkeCrudOverview from "./components/general/mkeCrudOverview"
import mkePreloader from "./components/general/mkePreloader";
import mkeSidebar from "./components/general/mkeSidebar";
import mkeSwitchThemeToggle from "./components/general/mkeSwitchThemeToggle";
import mkeBadge from "./components/general/mkeBadge";
import mkeSpinner from "./components/general/mkeSpinner";
import PosTopBar from "./components/pos/PosTopBar"
import PosProductConstraintsDialog from "./components/pos/PosProductConstraintsDialog";
import PosProcessDetails from "./components/pos/PosProcessDetails";
import PosKeyboardDisplay from "./components/pos/PosKeyboardDisplay";
import PosNumpad from "./components/pos/PosNumpad";
import PosNumpadCash from "./components/pos/payment/PosNumpadCash";
import PosNumpadSplit from "./components/pos/split/PosNumpadSplit";
import PosProductCategoryFilter from "./components/pos/PosProductCategoryFilter";
import PosProductSelection from "./components/pos/PosProductSelection";
import PosProductNavigation from "./components/pos/PosProductNavigation";
import PosProcessListDialog from "./components/pos/PosProcessListDialog";
import PosProcessVoidDialog from "./components/pos/PosProcessVoidDialog";
import PosProcessPaymentDialog from "./components/pos/payment/PosProcessPaymentDialog";
import PosProcessSplitDialog from "./components/pos/split/PosProcessSplitDialog";
import PosProcessSplitEntries from "./components/pos/PosProcessSplitEntries";
import PosProcessPaymentCash from "./components/pos/payment/PosProcessPaymentCash";
import PosProcessComboDialog from "./components/pos/PosProcessComboDialog";
import PosProcessComboComponent from "./components/pos/combo/PosProcessComboComponent";
import PosProcessComboSingle from "./components/pos/combo/PosProcessComboSingle";
import PosProcessComboMulti from "./components/pos/combo/PosProcessComboMulti";
import PosFormBuilderComboEdit from "./components/pos/combo/PosFormBuilderComboEdit";
import PosFormBuilderComboPanel from "./components/pos/combo/PosFormBuilderComboPanel";
import PosFormBuilderComboEditDialog from "./components/pos/combo/PosFormBuilderComboEditDialog";
import posSetTerminal from "./components/pos/posSetTerminal"
import PosProcessSwitcher from "./components/pos/PosProcessSwitcher"
import PosProcessSelectDialog from "./components/pos/PosProcessSelectDialog"
import PosProcessFunctions from "./components/pos/PosProcessFunctions"
import PosOrderFunctions from "./components/pos/PosOrderFunctions"
import PosProductInfoDialog from "./components/pos/PosProductInfoDialog"
import PosProcessHistoryDialog from "./components/pos/PosProcessHistoryDialog"
import PosFormBuilderSlotMachineDialog from "./components/pos/PosFormBuilderSlotMachineDialog"
import PosActionPanel from "./components/pos/PosActionPanel"
import PosProductExplorer from "./components/pos/PosProductExplorer"
import PosProcessTSEDialog from "./components/pos/payment/PosProcessTSEDialog"
import PosProcessPaymentsInfoDialog from "./components/pos/payment/PosProcessPaymentsInfoDialog"
import PosTSEList from "./components/pos/payment/PosTSEList"
import PosChangeTableofProcess from "./components/pos/split/PosChangeTableofProcess"
import PosNotInitialized from "./components/pos/PosNotInitialized"

import Grid from "./components/general/Grid"
import TopBar from "./components/general/TopBar"
import MxAppIcon from "./components/apps/MxAppIcon";
import MxSettingsAppIcon from "./components/apps/MxSettingsAppIcon";
import MxAppSwitcher from "./components/apps/MxAppSwitcher";
import MxApp from "./components/apps/MxApp";
import MxTopBar from "./components/apps/MxTopBar";

// MKE Custom Transitions
import mxTransitionExpand from "./components/transition/mx-transition-expand"

import BadgeDirective from 'primevue/badgedirective';


// Dashboard

import dsh_calendar from "./components/dashboard/dsh_calendar";
import dsh_sidebar from "./components/dashboard/dsh_sidebar";
import dsh_topbar from "./components/dashboard/dsh_topbar";
import dsh_status from "./components/dashboard/dsh_status";
import dsh_exportDataDialog from "@/components/dashboard/widgets/components/dsh_exportDataDialog";
import mkeContextMenu from "@/components/dashboard/widgets/components/mkeContextMenu";
// MX EP COMPONENTS

import MxLogoFooter from "./components/general/MxLogoFooter";
import MxLogoSidebar from "./components/general/MxLogoSidebar";

import MxIconPos from "./components/apps/icons/MxIconPos";

// Shared-Elements & Draggable
import {
    SharedElementDirective
} from 'v-shared-element'

import draggable from 'vuedraggable';

//Echarts
import ECharts from 'vue-echarts'
import { use, registerTheme } from "echarts/core"
import {
    CanvasRenderer
} from 'echarts/renderers'
import {
    BarChart,
    PieChart,
    LineChart
} from 'echarts/charts'
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent,
    VisualMapComponent,
    ToolboxComponent
} from 'echarts/components'
use([
    CanvasRenderer,
    BarChart,
    GridComponent,
    TooltipComponent,
    PieChart,
    LineChart,
    TitleComponent,
    LegendComponent,
    VisualMapComponent,
    ToolboxComponent,
])


// SWIPER
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/vue";
import 'swiper/swiper.scss';
import "swiper/components/pagination/pagination.scss";


// Style
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

// MKE Application SCSS
require('@/assets/scss/_application.scss');

// gridstack.js
import "gridstack/dist/gridstack.min.css";
import "gridstack/dist/gridstack-extra.min.css";
import "gridstack/dist/h5/gridstack-dd-native";

//SignalR
import SignalRPlugin from '@/plugins/SignalRPlugin.io'



// Perfect Scrollbar
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'


const debug = process.env.NODE_ENV !== 'production'

const pinia = createPinia()
const app = createApp(App);
app.use(pinia);
app.use(i18n);

if( debug ) {
    pinia.use(({ store }) => {
        store.$subscribe((mutation) => {
            console.log(`[🍍 Mutation on ${mutation.storeId}]: ${mutation.type}`, mutation.payload);
        })
        store.$onAction(({name, store, args}) => {
            console.log(`[🍍 Action on ${store.$id}]: ${name}`, args);
        })
    })
}

var signal_rhost = window.location.protocol + "//" + window.location.hostname + "/posservice";

app.use(SignalRPlugin, {
    url: signal_rhost,
    options: {}
})

app.use(scrolltobottom);


// Set responsive breakpoints
app.use(Vue3Mq, {
    breakpoints: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1024,
      xl: 1200,
      xxl: 1400
    }
  });

app.use(VueCookies);
app.use(PrimeVue,{'ripple': true});
app.use(router)
app.use(store)
app.use(ToastService);
app.use(PerfectScrollbar);
app.use(Vue3Lottie)
app.use(SharedElementDirective, {
    duration: "400ms",
    zIndex: 9999,
    endDuration: false,
    includeChildren: false,
    compositeOnly: true
})

app.config.globalProperties.$filters = {
    toDate(value) {
        if (moment(value, 'YYYY-MM-DD', true).isValid()) {
            return moment(value, "YYYY-MM-DD").format("DD.MM.yyyy");
        } else {
            return value
        }
    },
    toDateTime(value) {
        return toDateTime(value);
    },
    utcToTime(value) {
        const utcValue = moment(value, false);
        if ( utcValue.isValid()) {
            return utcValue.format("HH:mm:ss");
        } else {
            return value;
        }
    },
    toCurrency(value) {
        return toCurrency(value);
    },
    toNumber(value) {
        if (typeof value !== "number") {
            return value;
        }
        const formatter = new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2
        });
        return formatter.format(value);
    },
    toNumberInt(value) {
        if (typeof value !== "number") {
            return value;
        }
        const formatter = new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 0
        });
        return formatter.format(value);
    },
    toNumberMostlyInt(value) {
        if (typeof value !== "number") {
            return value;
        }

        const fractionDigits = Number.isInteger(value) ? 0 : 2;

        const formatter = new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits
        });
        return formatter.format(value);
    }
}

// Axios
import axios from "axios";

/**
 * Add a request interceptor to add the jwt token as authorization in the header
 */
axios.interceptors.request.use(function (config) {
    // If there is already an authorization, don't overwrite it
    if( !config.headers.Authorization ) {
        config.headers.Authorization = "Bearer " + store.state.general.jwtToken
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('InputText', InputText);
app.component('Button', Button);
app.component('SelectButton', SelectButton);
app.component('ToggleButton', ToggleButton);
app.component('OverlayPanel', OverlayPanel);
app.component('Dialog', Dialog);
app.component('Toolbar', Toolbar);
app.component('Toast', Toast);
app.component('Avatar', Avatar);
app.component('Sidebar', Sidebar);
app.component('Menu', Menu);
app.component('TieredMenu', TieredMenu);
app.component('PanelMenu', PanelMenu);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('MultiSelect', MultiSelect);
app.component('Card', Card);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('Row', Row);
app.component('Textarea', Textarea);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('InputNumber', InputNumber);
app.component('FileUpload', FileUpload);
app.component('ProgressBar', ProgressBar);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Calendar', Calendar);
app.component('InputSwitch', InputSwitch);
app.component('Password', Password);
app.component('Badge', Badge);
app.directive('tooltip', Tooltip);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('ScrollPanel', ScrollPanel);
app.component('TreeTable', TreeTable);
app.component('AutoComplete', AutoComplete);
app.component('Image', Image);
app.component('Panel', Panel);
app.component('SplitButton', SplitButton);

app.component('pos_api', pos_api);
app.component('Grid', Grid);
app.component('mkeButton', mkeButton);
app.component('mkeChangePasswordDialog', mkeChangePasswordDialog);
app.component('mkeDataTable', mkeDataTable);
app.component('mkeFormBuilder', mkeFormBuilder);
app.component('mkeFormBuilderDropdown', mkeFormBuilderDropdown);
app.component('mkeFormBuilderInputCheckbox', mkeFormBuilderInputCheckbox);
app.component('mkeFormBuilderInputSwitch', mkeFormBuilderInputSwitch);
app.component('mkeFormBuilderInputText', mkeFormBuilderInputText);
app.component('mkeFormBuilderInputTextarea', mkeFormBuilderInputTextarea);
app.component('mkeFormBuilderInputNumber', mkeFormBuilderInputNumber);
app.component('mkeFormBuilderMultiSelect', mkeFormBuilderMultiSelect);
app.component('mkeFormBuilderTreeTable', mkeFormBuilderTreeTable);
app.component('mkeFormBuilderTab', mkeFormBuilderTab);
app.component('mkeFormBuilderTranslationDialog', mkeFormBuilderTranslationDialog);
app.component('mkeFormBuilderImage', mkeFormBuilderImage);
app.component('mkeFormBuilderAddItemDialog', mkeFormBuilderAddItemDialog);
app.component('mkeFormBuilderOneToManyPanel', mkeFormBuilderOneToManyPanel);
app.component('mkeFormBuilderDisplayValue', mkeFormBuilderDisplayValue);
app.component('mkeFormBuilderSelectItem', mkeFormBuilderSelectItem);
app.component('mkeInputText', mkeInputText);
app.component('mkeDisplayValue', mkeDisplayValue);
app.component('mkeKeyboard', mkeKeyboard);
app.component('mkeModelDetail', mkeModelDetail);
app.component('mkeModelOverview', mkeModelOverview);
app.component('mkeCrud', mkeCrud);
app.component('mkeCrudDetail', mkeCrudDetail);
app.component('mkeCrudOverview', mkeCrudOverview);
app.component('mkePreloader', mkePreloader);
app.component('mkeSidebar', mkeSidebar);
app.component('mkeSwitchThemeToggle', mkeSwitchThemeToggle);
app.component('mkeBadge', mkeBadge);
app.component('mkeSpinner', mkeSpinner);
app.component('PosKeyboardDisplay', PosKeyboardDisplay);
app.component('PosNumpad', PosNumpad);
app.component('PosNumpadCash', PosNumpadCash);
app.component('PosNumpadSplit', PosNumpadSplit);
app.component('PosTopBar', PosTopBar);
app.component('PosProductCategoryFilter', PosProductCategoryFilter);
app.component('PosProductSelection', PosProductSelection);
app.component('PosProductNavigation', PosProductNavigation);
app.component('PosProductConstraintsDialog', PosProductConstraintsDialog);
app.component('PosProcessListDialog', PosProcessListDialog);
app.component('PosProcessVoidDialog', PosProcessVoidDialog);
app.component('PosProcessPaymentDialog', PosProcessPaymentDialog);
app.component('PosProcessSplitDialog', PosProcessSplitDialog);
app.component('PosProcessSplitEntries', PosProcessSplitEntries);
app.component('PosProcessPaymentCash', PosProcessPaymentCash);
app.component('PosProcessComboDialog', PosProcessComboDialog);
app.component('PosProcessComboComponent', PosProcessComboComponent);
app.component('PosProcessComboSingle', PosProcessComboSingle);
app.component('PosProcessComboMulti', PosProcessComboMulti);
app.component('PosFormBuilderComboEdit', PosFormBuilderComboEdit);
app.component('PosFormBuilderComboPanel', PosFormBuilderComboPanel);
app.component('PosFormBuilderComboEditDialog', PosFormBuilderComboEditDialog);
app.component('PosProductInfoDialog', PosProductInfoDialog);
app.component('PosProcessHistoryDialog', PosProcessHistoryDialog);
app.component('PosProcessTSEDialog', PosProcessTSEDialog);
app.component('PosProcessPaymentsInfoDialog', PosProcessPaymentsInfoDialog);
app.component('PosTSEList', PosTSEList);
app.component('PosProcessDetails', PosProcessDetails);
app.component('PosProcessSwitcher', PosProcessSwitcher);
app.component('PosProcessSelectDialog', PosProcessSelectDialog);
app.component('PosProcessFunctions', PosProcessFunctions);
app.component('PosOrderFunctions', PosOrderFunctions);
app.component('PosFormBuilderSlotMachineDialog', PosFormBuilderSlotMachineDialog);
app.component('PosActionPanel', PosActionPanel);
app.component('PosProductExplorer', PosProductExplorer);
app.component('posSetTerminal', posSetTerminal);
app.component('PosChangeTableofProcess', PosChangeTableofProcess);
app.component('PosNotInitialized', PosNotInitialized);
app.component('Swiper', Swiper);
app.component('SwiperSlide', SwiperSlide);
app.component('Pagination', Pagination);
app.component('OrderList', OrderList);
app.component('DataView', DataView);
app.component('TopBar', TopBar);
app.component('CascadeSelect', CascadeSelect);
app.component('Inplace', Inplace);
app.component('mq-responsive', MqResponsive);
app.component('mxTransitionExpand', mxTransitionExpand);
app.component('draggable', draggable);

app.component('video-background', VideoBackground);

app.component('dsh_calendar', dsh_calendar);
app.component('dsh_sidebar', dsh_sidebar);
app.component('dsh_topbar', dsh_topbar);
app.component('dsh_status', dsh_status);
app.component('dsh_exportDataDialog', dsh_exportDataDialog);
app.component('mkeContextMenu', mkeContextMenu);

// MX EP COMPONENTS
app.component('MxLogoFooter', MxLogoFooter);
app.component('MxLogoSidebar', MxLogoSidebar);

app.component('MxAppIcon', MxAppIcon);
app.component('MxSettingsAppIcon', MxSettingsAppIcon);
app.component('MxAppSwitcher', MxAppSwitcher);
app.component('MxApp', MxApp);
app.component('MxTopBar', MxTopBar);
app.component('MxIconPos', MxIconPos);

import theme from "./json/echarts_theme.json";
import RadioButton from "primevue/radiobutton";

import moment from "moment";
import scrolltobottom from "@/plugins/scrolltobottom";

registerTheme("42-default", theme);
app.component('v-chart', ECharts)

app.directive('ripple', Ripple);
app.directive('badge', BadgeDirective);

router.isReady().then(() => {
    console.log( "MOUNTING APP");
    app.mount('#app');
})

console.log("MAIN.JS end", new Date().toISOString());
