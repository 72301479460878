<template>
  <div class="p-grid p-ai-center mke-px-0">
    <div v-if="label" class="p-col-4 mke-p-0">{{ label }}</div>
    <div class="p-col-8 mke-p-0">
      <MultiSelect :filter="true" scrollHeight="400" :id="id" :modelValue="modelValue" :options="options"
                   optionLabel="name" optionValue="id" :display="displayType"
                   @change="$emit('update:modelValue', $event.value)"
                   class="block">
      </MultiSelect>
    </div>
  </div>
</template>

<script>
export default {
  name: "mkeFormBuilderMultiSelect",
  props: ["id", "displayType", "label", "modelValue", "options", "readonly"]
}
</script>

<style scoped>

</style>