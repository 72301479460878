export default function createAuthenticationWebSocketPlugin(socket) {
    return store => {
        /**
         * Write jwtToken in args
         */
        let jwtEmit = function(ev, ...args) {
            let terminalUUID = store.state.general.terminalUUID
            if (terminalUUID) {
                args.unshift({'terminal_id': terminalUUID})
            }
            let jwtToken = {token: store.state.general.jwtToken}
            if (jwtToken) {
                args.unshift(jwtToken)
            }
            socket.emit(ev, ...args)
        }
        /**
         * Reset the store states
         */
        function resetStore() {
            for (let storeName of Object.keys(store.state)) {
                if (storeName + "/resetState" in store._actions) {
                    store.dispatch(storeName + "/resetState")
                }
            }
        }

        socket.on('add_toast', (toast) => {
            store.commit("general/addToast", toast)
        });
        /**
         * Authenticate on connect
         */
        socket.on("connect", () => {
            jwtEmit("authenticate")
        });
        /**
         * Forced login with jwt token by authentication service
         * For example: Login with key by keylock
         */
        socket.on("login", async (newJwtToken) => {
            if (store.state.general.activeUser) {
                await store.dispatch("general/logout")
            }
            store.dispatch("general/loginToken", {jwtToken: newJwtToken})
        });
        /**
         * Forced logout by authentication service
         * For example: The user got deleted
         */
        socket.on("logout", () => {
            if (store.state.general.activeUser) {
                store.dispatch("general/logout")
            }
        });
        /**
         * Get and send the signal to renew the jwtToken
         */
        socket.on("initiateRenewToken", () => {
            jwtEmit("renewToken")
        });
        /**
         * Get an updated jwtToken and reauthenticate
         */
        socket.on("updateToken", (newJwtToken) => {
            jwtEmit("authenticate")
            store.dispatch("general/renewToken", {jwtToken: newJwtToken})
        });


        store.subscribeAction({
            before: (action) => {
                if (action.type === "general/loginToken") {
                    resetStore()
                }
            },
            after: (action) => {
                if (action.type === "general/loginToken") {
                    jwtEmit("authenticate")
                } else if (action.type === "general/logout") {
                    jwtEmit("logout")
                    resetStore()
                }
            }
        });
    };
}