/**
 * Add the possibility to reset the state to a defined standard to your store.
 * To use this mixin your store needs an exported getDefaultState function.
 * IMPORTANT: This reset will be called on login and logout
 *
 * Usage:
 *      - Create a new function in your store to return the default state. Example:
 *          function getDefaultState() {
 *              return {
 *                 viewMode: 'overview',
 *                 activeID: null,
 *                 languages: {},
 *             }
 *         }
 *      - export the function
 *      - Call the createResetState function with your store module
 */
export default function createResetState(module) {
    const actions = {
        /**
         * Reset the state of the store
         */
        resetState({commit}) {
            commit("resetState")
        },
    }
    const mutations = {
        resetState(state) {
            Object.assign(state, module.getDefaultState())
        },
    }
    for (const [actions_key, actions_function] of Object.entries(actions)) {
        if (module.actions[actions_key] === undefined) {
            module.actions[actions_key] = actions_function
        }
    }
    for (const [mutation_key, mutation_function] of Object.entries(mutations)) {
        if (module.mutations[mutation_key] === undefined) {
            module.mutations[mutation_key] = mutation_function
        }
    }
}