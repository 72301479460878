<template>
  <Dialog
      :modal="true"
      :breakpoints="{ '960px': '75vw' }"
      :style="{ width: '50vw' }"
  >
    <template #header>
       <h5>{{ t('message.dashboard.excelExport') }}</h5>
    </template>
    <form @submit.prevent="handleSubmit(!validator.$invalid)">
      <div class="p-d-flex p-flex-column gap-4">

        <div class="p-grid p-ai-center">
          <div class="p-col-4 mke-p-0">
            <span class="p-d-block">{{ t('message.dashboard.fileName') }}</span>
          </div>
          <div class="p-col-8 mke-p-0">
            <InputText class="block dashboard-title" type="text" v-model="dialog.name"  :class="{'p-invalid':validator.name.$invalid}"/>
          </div>
        </div>


      </div>

      <Divider layout="horizontal" />
      <Button  :label="t('message.dashboard.exit')"
               icon="mdi mdi-close"
               id="close-dashboard"
               class="p-mt-2 p-mr-2 p-button-transparent"
               @click="exportDataDialogVisible = false">
      </Button>
      <Button
          :label="'Export'"
          id="create-dashboard"
          type="submit"
          icon="mdi mdi-check"
          class="p-mt-2">
      </Button>
    </form>
  </Dialog>
</template>

<script>
import {computed, watch, ref} from "vue";
import { useStore } from "vuex";
import {required } from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import { useI18n } from 'vue-i18n'

export default {
  props: ["fileName"],
  emits: ["fileNameExport"],
  setup(props, context) {
    const store = useStore();
    const rules = {
      name: { required },
    };
    const dialog = ref({name: props.fileName})
    const validator = useVuelidate(rules, dialog);
    const { t } = useI18n({ useScope: 'global' })

    watch(props, () => {
      dialog.value.name = props.fileName;
    });

    const handleSubmit = (isFormValid) => {
      if (isFormValid) {
        const payload = {}
        Object.assign(payload, dialog.value)
        context.emit("fileNameExport", payload);
        store.commit("dashboard/set_export_data_dialog_visible", false)
        dialog.value = {name: props.fileName}
      }
    }
    const exportDataDialogVisible = computed({
      get: () => {
        return store.state.dashboard.export_data_dialog_visible;
      },
      set: (value) =>
          store.commit("dashboard/set_export_data_dialog_visible", value),
    });

    return { exportDataDialogVisible, handleSubmit, validator, dialog,t};
  },
};
</script>
