<template>
  <div class="combo-multi"
       :data-plu="combo.plu"
       :data-color="combo.color"
       :data-quantity="combo.selected_quantity"
       :data-name="combo.title || combo.name"
       :data-parent="parent.title || parent.name"
       :data-level="level"
       :data-selection-method="combo.combo_selection_method"
       :data-min="combo.combo_min"
       :data-max="combo.combo_max"
       :data-valid="combo.valid"
       :data-child-mode="combo.child_mode">
    <h6 class="font-size-m mx-fg-text-high mke-mb-4 mke-mt-2">{{combo.title || combo.name}}</h6>
    <div class="p-d-flex p-flex-wrap">
      <div class="mke-pr-2 mke-pb-2" :key="entry.key" v-for="entry in combo.entries" >
        <pos-process-combo-component :combo="entry" :parent="combo" :level="level+1" @entry-selected="selectEntry"/>
      </div>
    </div>
  </div>
  <Divider class="mke-my-5" />
  <div v-if="selectedEntriesWithSubSelection().length > 0">
    <pos-process-combo-multi v-for="entry in selectedEntriesWithSubSelection()" :key="entry.key" :combo="entry" :parent="combo" :level="level+1" @entry-selected="selectEntry"/>
  </div>

</template>

<script>

export default {
  name: "PosProcessComboMulti",
  props: ["combo", "parent", "level"],
  emits: ["entry-selected"],
  setup(props, {emit}) {
    const selectEntry = ({combo, selectedEntry, added} ) => {
      emit('entry-selected', {combo:combo, selectedEntry:selectedEntry, added: added});
    }

    const selectedEntriesWithSubSelection = () => {
      let s = props.combo.entries.filter((e) => e.selected_quantity > 0 && e.child_mode === 'no_child_childs');
      return s;
    }

    return {selectEntry, selectedEntriesWithSubSelection}
  }
}

</script>

<style scoped>

</style>