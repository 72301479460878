<template>
  <div v-if="isNested == true" class="grid-stack-item" :breakpoint="breakpoint">
    <div class="grid-stack-item-content">
      <div :class="'grid-stack-nested grid-stack ' + id" ref="gridStack" :column="column">
        <div
            class="grid-stack-item"
            v-for="(element, index) in gridElements"
            :x="getDimensionValue(element, 'x')"
            :y="getDimensionValue(element, 'y')"
            :h="getDimensionValue(element, 'h')"
            :w="getDimensionValue(element, 'w')"
            :key="index"
        >
          <component
              :is="element.component"
              v-bind="element"
              @enterButtonPressed="enterButtonPressed"
              @resetFocus="onResetFocus"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
  <div class="p-overflow-hidden" :class="'grid-stack ' + id" ref="gridStack" :column="column" :breakpoint="breakpoint">
    <component
        class="grid-stack-item"
        v-for="(element, index) in gridElements"
        v-bind="element"
        :x="getDimensionValue(element, 'x')"
        :y="getDimensionValue(element, 'y')"
        :h="getDimensionValue(element, 'h')"
        :w="getDimensionValue(element, 'w')"
        :key="index"
        :is="element.component"

        @enterButtonPressed="enterButtonPressed"
        @resetFocus="onResetFocus"
    />
  </div>
  </div>
</template>

<script>
import {onMounted, onUnmounted, onUpdated, ref} from "vue";
import { GridStack } from "gridstack";
import { useMq } from "vue3-mq";


export default {
  name: "Grid",
  props: ['acceptWidgets', 'animate', 'cellHeight', "disableOneColumnMode", 'dragOut', 'float', 'gridElements', 'id', 'isNested', 'minCol', 'minRow', 'maxRow', 'staticGrid', 'margin', 'column'],
  emits: ["enterButtonPressed", "resetFocus"],
  setup(props, context){
    let grid = null;
    const gridStack = ref(null);
    const gridProps = ref(props)
    const mq = useMq();
    const breakpoint = ref( mq.current );


    console.log("Starting with breakpoint " + mq.current );
    /**
     * Receive a forward the enterButtonPressed emit
     */
    const enterButtonPressed = async () => {
      context.emit("enterButtonPressed")
    }
    /**
     * Receive a forward the resetFocus emit
     */
    function onResetFocus() {
      context.emit("resetFocus")
    }

    onMounted(() => {
      grid = GridStack.init(gridProps.value, gridProps.value.id);
      buildGrid(grid, gridStack);

      window.addEventListener('resize', onResize);
    })
    onUpdated(() => {
      buildGrid(grid, gridStack);
    });

    onUnmounted( () => {
      window.removeEventListener('resize', onResize);
    });

    const getDimensionValue = (element, dimension) => {
      const breakpoints = {"sm": ["sm"], "md": ["md", "sm"], "lg": ["lg", "md", "sm"], "xl": ["xl", "lg", "md", "sm"], "xxl": ["xxl", "xl", "lg", "md", "sm"] }
      const currentBreakpoints = breakpoints[breakpoint.value];

      let value = undefined;

      if( currentBreakpoints ) {
        currentBreakpoints.forEach( (b) => {
          const key = dimension + "-" + b;
          const bValue = element[key];
          if( bValue && !value ) {
            value = bValue;
          }
        } );
      }

      value = value || element[dimension];

      return value;
    };

    const transferDimension = (element, gridItem, dimension) => {
      const value = getDimensionValue(element, dimension);
      gridItem.setAttribute("gs-" + dimension, value);
    };

    const buildGrid = (grid, gridStack) => {
      grid.removeAll(false)
      for (let p = gridStack.value.children.length; p--;) {
        transferDimension(props.gridElements[p], gridStack.value.children[p], "x");
        transferDimension(props.gridElements[p], gridStack.value.children[p], "y");
        transferDimension(props.gridElements[p], gridStack.value.children[p], "w");
        transferDimension(props.gridElements[p], gridStack.value.children[p], "h");

        grid.makeWidget(gridStack.value.children[p])
      }
    }

    const onResize = () => {
      if( breakpoint.value !== mq.current ) {
        console.log("Switching breakpoint from " + breakpoint.value + " to " + mq.current );
        breakpoint.value = mq.current;
        buildGrid(grid, gridStack);
      }
    }
    return {gridStack, gridProps, enterButtonPressed, onResetFocus, getDimensionValue, breakpoint}
  }
}
</script>

<style scoped>
</style>