import dummy_data from "../../json/dummy_data.json";

export default function createPosWebSocketPlugin(socket) {
    return store => {
        /**
         * Set the dummy layout for the POS
         * TODO: Replace with a request to get the layout from a service
         */
        store.commit("layout/setLayout", dummy_data["layout"])
        // store.dispatch("pos/getRootNavigation")
        socket.on("login", (jwtToken) => {
            store.dispatch("general/loginToken", jwtToken)
        })
        socket.on("logout", () => {
            store.dispatch("general/logout")
        })
        socket.on("process_updated", (event) => {
            store.dispatch("pos/processUpdated", JSON.parse(event));
        })

    };
}