<template>
  <InputText v-model="inputData"></InputText>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: "mkeInputText",
  props: ["store"],
  setup(props) {
    const store = useStore()
    const inputData = computed({
      get: () => {
        return store.state.pos.keyboardDisplay[props.store]
      },
      set: (val) => (
          store.commit("pos/setKeyboardDisplay", {keyboardDisplayId: props.store, value: val})
      )
    })
    return {inputData}
  }
}
</script>

<style scoped>

</style>