<template>
  <Dialog id="product-info-dialog" :modal="true" v-model:visible="productInfoDialogVisible" :style="{width: '60vw'}" :closable="false">
    <template #header>
      <h5>{{t('message.pos.product_info.title', product.name)}}</h5>
    </template>

    <TabView>
      <TabPanel :header="t('message.masterdata.general')">
        <mke-display-value label="message.pos.product.name" :value="product.name"/>
        <mke-display-value label="message.pos.product.button_text" :value="product.button_text"/>
        <mke-display-value label="message.pos.product.printer_text" :value="product.printer_text"/>
        <mke-display-value label="message.pos.product.description" :value="product.description"/>
        <mke-display-value label="message.pos.product.plu" :value="product.plu"/>
        <mke-display-value label="message.pos.product.price" :value="n(product.price, 'currency')"/>
      </TabPanel>
      <TabPanel header="Zusätze/Allergene">
        Hier könnten die ganzen Zusätze/Allergene/Hinweise zum Produkt stehen.
      </TabPanel>
      <TabPanel header="Begleitung">
        Hier könnten vorgeschlagene Weine oä stehen
      </TabPanel>
    </TabView>
    <template #footer>
      <mke-button id="button-product-info-close" label="Schliessen" @click="closeDialog" class="p-button-primary"/>
    </template>
  </Dialog>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: "PosProductInfoDialog",
  setup(){
    const store = useStore();
    const { t, n } = useI18n({ useScope: 'global' })

    const productInfoDialogVisible = computed( () =>
        store.state.pos.productForInfo !== undefined && store.state.pos.productForInfo !== null );

    const product = computed( () => store.state.pos.productForInfo );

    const closeDialog = () => {
      store.commit('pos/productForInfo', null);
    }

    return {
      t, n, product, productInfoDialogVisible, closeDialog
    }
  },
}

</script>

<style scoped>

</style>