import axios from "axios";
import router from "../../router";
import jwt_decode from "jwt-decode";
import i18n from '../../i18n/index.js'
const restUrlAuthenticationService = config.AuthenticationServiceUrl
const restUrlDatamanagementService = config.DataServiceUrl

// initial state
function getStartState() {
    return {
        toasts: [],
        activeUser: null,
        authenticationDevice: null,
        jwtToken: null,
        loginUsers: [],
        terminalUUID: null,
        apps: [],
        activeApp: null
    }
}

function getDefaultState() {
    /**
     * Remove persisted state fields from default state
     */
    let res = getStartState()
    delete res["activeUser"]
    delete res["jwtToken"]
    delete res["terminalUUID"]
    return res
}

const state = getStartState()

// getters
const getters = {
    isAppEnabled: (state) => (app) => {
        let foundApp = app;
        if( state.apps.length > 0 ) {
            const appID = ( typeof app === "string" ? app : app?.id);
            foundApp = state.apps.find((a) => a.id === appID);
        }

        if( foundApp && state.activeUser ) {
            return !foundApp.privileges || foundApp.privileges.some(x => state.activeUser.privileges.includes(x));
        } else {
            return false;
        }
    }
}

// actions
const actions = {
    getLoginUsers({commit}) {
        axios.get(restUrlAuthenticationService + "/users")
            .then(function (result) {
                commit("setLoginUsers", result.data)
            })
            .catch(function (error) {
                commit("addToast", {detail: error.response.data.detail, life: 3000, severity: "error", summary: 18n.global.t('message.home.error')})
            });
    },
    /**
     * Try to authenticate/login the given user
     */
    login({commit, dispatch}, {user, password}) {
        const params = {
            "user_login" : user.login,
            "password" : password
        };

        axios.put(restUrlAuthenticationService + "/users/login", params)
            .then(function (result) {
                dispatch("loginToken", {jwtToken: result.data})
            })
            .catch(function (error) {
                if( 'response' in error ) {
                    commit("addToast", {detail: error.response.data.detail, life: 10000, severity: "error", summary: i18n.global.t('message.home.error')})
                } else {
                    commit("addToast", {detail: error, life: 10000, severity: "error", summary: i18n.global.t('message.home.error')})
                }
            });
    },
    /**
     * Login the user inside the jwt token
     * Only login the user if there is no active user
     */
    loginToken({commit}, {jwtToken, silent=false}) {
        commit("setJwtToken", jwtToken)
        let decoded = jwt_decode(jwtToken);
        commit("setActiveUser", decoded.claims)
        if (!silent){
            commit("addToast", {detail: i18n.global.t('message.home.loginSuccess'), life: 3000, severity: "success", summary: i18n.global.t('message.home.success')})
        }
        if( router.currentRoute.value.params.nextUrl ) {
            router.push(router.currentRoute.value.params.nextUrl)
        } else if (decoded.claims?.default_app) {
            router.push({name: decoded.claims.default_app})
        } else {
            router.push({name: "Home"})
        }
    },
    /**
     * Renew the token of the user.
     * We need this because the loginToken action uses
     * the setJwtToken mutation which resets the state.
     */
    renewToken({commit}, {jwtToken}) {
        commit("renewJwtToken", jwtToken)
        let decoded = jwt_decode(jwtToken);
        commit("setActiveUser", decoded.claims)
    },
    /**
     * Logout the active user
     * -> remove the jwt token from the locale storage
     * -> route to login screen
     * @param commit
     */
    async logout({commit}){
        commit("setJwtToken", null)
        commit("setActiveUser", null)
        commit("addToast", {detail: i18n.global.t('message.home.logoutSuccess'), life: 3000, severity: "success", summary: i18n.global.t('message.home.success')})
        await router.push({name: "Login"})
    },
    /**
     * Check if the given terminal uuid is available. If not: register the terminal and get a new uuid
     * @param terminalUUID The terminal uuid to check
     */
    checkAndRegisterTerminal({commit}, terminalUUID) {
        axios.get(restUrlDatamanagementService + "/terminal_settings/" + terminalUUID)
            .catch(function () {
                axios.post(restUrlDatamanagementService + "/terminal_settings/register")
                    .then(function (result) {
                        commit("setTerminalUUID", result.data)
                    })
                    .catch(function (error) {
                        commit("addToast", {
                            detail: error.message,
                            life: 3000,
                            severity: "error",
                            summary: i18n.global.t('message.home.error')
                        })
                    });
            })

    }
}

// mutations
const mutations = {
    /**
     * Add a toast to the screen
     * @param {string} detail Detailed message to show
     * @param {number} life Lifespan of the message. Null for a sticky message.
     * @param {string} severity Type of message to show. For example success or error
     * @param {string} summary The summary used as the title
     */
    addToast(state, {detail, life, severity, summary}) {
        state.toasts.push({severity: severity, summary: summary, detail: detail, life: life})
    },
    addLoginUser(state, user) {
        const index = state.loginUsers.findIndex((el) => el.id == user.id)
        if (index >= 0){
            state.loginUsers[index] = user
        } else {
            state.loginUsers.push(user)
        }
    },
    /**
     * Clear all toasts
     */
    clearToasts(state) {
        state.toasts = []
    },
    /**
     * Set a user as active user
     * @param user The user to set as active user
     */
    setActiveUser(state, user) {
        state.activeUser = user
        if (user && user.user_lang){
            i18n.global.locale.value = user.user_lang.replace('_', '-')
        }
    },
    setAuthenticationDevice(state, authenticationDevice) {
        state.authenticationDevice = authenticationDevice
    },
    /**
     * Renew the jwtToken. Needed code duplication of setJwtToken to prevent store reset.
     */
    renewJwtToken(state, jwtToken) {
        state.jwtToken = jwtToken
    },
    /**
     * Set a new jwtToken
     */
    setJwtToken(state, jwtToken) {
        state.jwtToken = jwtToken
    },
    setLoginUsers(state, loginUsers) {
        state.loginUsers = loginUsers
    },
    setTerminalUUID(state, uuid) {
        state.terminalUUID = uuid
    },
    setApps(state, apps) {
        state.apps = apps;
    },
    setActiveApp(state, app) {
        state.activeApp = app?.value ?? app;

        console.log( "SET ACTIVE APP", state.activeApp);
    }
}

export default {
    namespaced: true,
    actions,
    getDefaultState,
    getters,
    mutations,
    state,
}
