<template>
  <div
      class="mke__login_waiter_list p-d-flex p-ai-stretch p-jc-stretch"
      style="width: 100%;"
  >
    <div
        class="waiter p-button-transparent p-ripple"
        v-ripple
        v-for="(user, index) in users"
        v-bind:key="index"
        @click="onSelectUser(user)"
        :data-waiter="user.name"
    >
      <Avatar class="mke-mr-3">{{
          user.name.split(" ")
              .map(([v]) => v)
              .join("")
        }}
      </Avatar>
      <div class="waiter-name">{{ user.name }}</div>
    </div>
  </div>

  <!-- Change Password -->
  <mkeChangePasswordDialog v-if="showChangePasswordDialog" :noOldPassword="true" :userID="selectedUser.id"
                           v-model:showDialog="showChangePasswordDialog"/>

  <!-- Enter Password -->
  <Dialog v-model:visible="showPasswordDialog" :style="{width: '50vw'}" :dismissableMask="true"
          :draggable="false" :closable=false :modal=true>
    <template #header>
      <div>
        <h5 class="p-d-inline-block mke-my-0 mke-mr-2">{{ selectedUser.name }}</h5>
        <span class="mx-fg-text-medium">{{ t('message.home.enterPassword') }}.</span>
      </div>
    </template>
    <Password ref="passwordInputField" class="mke-login-password" v-model:visible="showPasswordDialog"
              :feedback="false" toggleMask v-model="password" v-on:keyup.enter="enterButtonPressed"/>
  </Dialog>

  <mq-responsive target="md+">
    <Sidebar class="keyboard" v-model:visible="showPasswordDialog" :modal=false :baseZIndex="700" position="bottom">
      <div id="keyboard-full" class="surface-medium-opaque mke-pt-2 mke-pb-2">
        <mkeKeyboard layout="keyboard_full" store="loginPassword"
                     @enterButtonPressed="enterButtonPressed" @resetFocus="onResetFocus"/>
      </div>
    </Sidebar>
  </mq-responsive>

</template>


<script>
import {useStore} from "vuex";
import {computed, ref} from "vue";
import {useI18n} from 'vue-i18n'

export default {
  name: "Login",
  data() {
    return {
      Interaction: false,
      visibleLeft: false,
      Background: {},
    };
  },

  setup() {
    const store = useStore();
    const password = computed({
      get: () => {
        return store.state.pos.keyboardDisplay["loginPassword"]
      },
      set: (val) => (
          store.commit("pos/setKeyboardDisplay", {keyboardDisplayId: "loginPassword", value: val})
      )
    })
    const {t} = useI18n({useScope: 'global'})
    const showChangePasswordDialog = ref(false);
    const showPasswordDialog = ref(false);
    const selectedUser = ref(false);
    store.dispatch('general/getLoginUsers')
    const users = computed(() => store.state.general.loginUsers);
    const passwordInputField = ref(null)
    const keyboardFullLayoutComponents = computed(() => store.getters["layout/getSpecificLayout"]("keyboard_full"))

    /**
     * Login the selected user
     * @param user The selected user for the login
     */
    const buttonLoginUser = async (user) => {
      if (user == null) {
        return false;
      }
      await store.dispatch('general/login', {
        "user": user,
        "password": store.getters["pos/getKeyboardDisplay"]("loginPassword")
      })
    };

    /**
     * Called if a user was selected. Shows the password dialog if necessary
     */
    const onSelectUser = async (user) => {
      selectedUser.value = user;
      if (user.need_password) {
        showChangePasswordDialog.value = true
      } else {
        if (user.authentication_method) {
          showPasswordDialog.value = true;
        } else {
          showPasswordDialog.value = false
          await buttonLoginUser(user)
        }
      }
    };

    /**
     * Emitted enter press of the keyboard: Try to login the selected user.
     */
    const enterButtonPressed = async () => {
      await buttonLoginUser(selectedUser.value)
      onResetFocus()
    }

    /**
     * Set the focus on the input of the password input
     */
    function onResetFocus() {
      passwordInputField.value.$el.children[0].focus()
    }

    return {
      buttonLoginUser,
      keyboardFullLayoutComponents,
      enterButtonPressed,
      password,
      showChangePasswordDialog,
      showPasswordDialog,
      onSelectUser,
      onResetFocus,
      passwordInputField,
      selectedUser,
      users,
      t
    };
  },
};
</script>

<style scoped>
</style>
