import de_DE from './de_DE.json'
import en_US from './en_US.json'
import { createI18n } from 'vue-i18n'

const messages = {
    'en-US': {
        message: {
            ...en_US
        }
    },
    'de-DE': {
        message: {
            ...de_DE
        }
    }
}

const datetimeFormats = {
    'en-US': {
        datetime_long: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        },
        datetime_short: {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        },
        date_long: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
        date_short: {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        },
        time_long: {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        },
        time_short: {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        },
    },
    'de-DE': {
        datetime_long: {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        },
        datetime_short: {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        },
        date_long: {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        },
        date_short: {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        },
        time_long: {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        },
        time_short: {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        },
    }
}

const numberFormats = {
    'en-US': {
        currency: {
            style: 'currency', currency: 'EUR', notation: 'standard'
        },
        decimal: {
            style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        int: {
            style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0
        },
        percent: {
            style: 'percent', useGrouping: false
        }
    },
    'de-DE': {
        currency: {
            style: 'currency', currency: 'EUR', notation: 'standard'
        },
        decimal: {
            style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        int: {
            style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0
        },
        percent: {
            style: 'percent', useGrouping: false
        }
    }
}

const i18n = createI18n({
    locale: 'de-DE', // set locale
    legacy: false,
    fallbackLocale: 'de-DE', // set fallback locale
    messages,
    datetimeFormats,
    numberFormats
})

export default i18n;
