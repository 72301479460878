<template>
  <div class="p-grid p-ai-center mke-px-0">
    <div v-if="label" class="p-col-4 mke-p-0">{{ label }}</div>
    <div class="p-col-12 mke-p-0">
      <TreeTable :id="id" :value="nodes" :expandedKeys="expandedKeys">
        <Column :field="column.field" :header="column.name" v-for="(column, index) in columns" :key="column.field" :expander="index==0"/>
      </TreeTable>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import {useStore} from "vuex";

export default {
  name: "mkeTreeTable",
  props: ["id", "activeID", "label", "store", "model", "field", "action", "columns", "placeholder", "readonly"],
  emits: ["update:modelValue"],

  setup(props) {
    const store = useStore()
    const nodes = ref([]);
    const expandedKeys = ref([]);

    store.dispatch(props.store + "/getTreeData",{
      id: props.activeID,
      model: props.model,
      field: props.field,
      action: props.action,
      onSuccessCallback: (newData) => {
        nodes.value = [newData];
        expandedKeys.value[newData.key] = true;
      }});

    return {nodes, expandedKeys};
  }
};
</script>

<style scoped></style>
